import { HeaderProps } from 'react-table'
import { logger } from '../../../utils'
import { ContextList } from '../hooks/contextMenuHook'
import { MenuInfoType } from '../Table.Store'

export const defualtHeaderList = (
  header?: HeaderProps<any>,
  useSingleRowSelect?: boolean
): ContextList[] => {
  return [
    {
      key: 'header-select-all',
      title: header?.isAllRowsSelected ? 'Deselected All' : 'Select All',
      onClick: () => {
        header?.toggleAllRowsSelected()
      },
      isHidden: useSingleRowSelect
    },
    {
      key: 'header-column-manager',
      title: 'Column Manager',
      onClick: () => logger('Column Manager'),
      divider: true
    },
    {
      key: 'header-count-columns',
      title: 'Count columns',
      onClick: ({ setShowCount }) => setShowCount(MenuInfoType.Column)
    },
    {
      key: 'header-count-rows',
      title: 'Count rows',
      onClick: ({ setShowCount }) => setShowCount(MenuInfoType.Row)
    },
    {
      key: 'header-sum-all',
      title: 'Sum all',
      onClick: ({ setToggleFooter }) => setToggleFooter(),
      isHidden: !header?.visibleColumns.find((column) => column.isNumber)
    }
  ]
}

export const defualtRowList: ContextList[] = [
  {
    key: 'row-count-columns',
    title: 'Count columns',
    onClick: ({ setShowCount }) => setShowCount(MenuInfoType.Column)
  },
  {
    key: 'row-count-rows',
    title: 'Count rows',
    onClick: ({ setShowCount }) => setShowCount(MenuInfoType.Row)
  }
]
