import styled from '@emotion/styled'
import React from 'react'
import {
  color,
  fontSize,
  fontWeight,
  layout,
  letterSpacing,
  lineHeight,
  space,
  textAlign,
  width
} from 'styled-system'
import { useMacsTranslation } from '../../hooks/translation'
import { theme } from '../../theme'
import { StyledSystemProps } from '../../typings/StyledSystemProps'

type Props = StyledSystemProps & {
  pointer?: boolean
  textTransform?: any
  color?: string
  letterSpacing?: string
  lineHeight?: string
}

const StyledP = styled.p<Props>`
  ${layout};
  ${space};
  ${color};
  ${width};
  ${fontSize};
  ${textAlign};
  ${fontWeight};
  ${letterSpacing};
  ${lineHeight};
  text-transform: ${(props: Props) => props.textTransform || 'none'};
  &:hover {
    cursor: ${(props: Props) => (props.pointer ? 'pointer' : 'default')};
  }
`

const P: React.FC<Props> = ({ children, ...rest }) => {
  const translation = useMacsTranslation({ text: children?.toString() || '' })
  return <StyledP {...rest}>{typeof children === 'string' ? translation : children}</StyledP>
}

export default P

P.defaultProps = {
  fontSize: 'md',
  color: theme.colors.text.default
}
