/**
 * @name flattenMenus
 * @description Reduce nested menus into a single flat array
 * @param menu Generic array
 * @returns accumulator: T[]
 */
export const flattenMenus = <T extends { [key: string]: any }>(menu: T[]): T[] => {
  //Conver objects to arrays or just return the array
  const array = Array.isArray(menu) ? menu : [menu]
  return array.reduce<T[]>((acc, value) => {
    if (value) {
      //add and values to the accumulator
      acc.push(value)
      //if there are children in the current object recursivly flatten them and add them to the accumulator
      if (value.children) {
        acc = acc.concat(flattenMenus(value.children))
      }
    }
    return acc
  }, [])
}

/**
 * @name flattenParents
 * @description Reduce nested menus parents into a single flat array
 * @param menu Generic array
 * @returns accumulator: T[]
 */
export const flattenParents = <T extends { [key: string]: any }>(menu: T[]): T[] => {
  //Conver objects to arrays or just return the array
  const array = Array.isArray(menu) ? menu : [menu]
  return array.reduce<T[]>((acc, value) => {
    //add and values to the accumulator
    acc.push(value)
    //if there are children in the current opject recursivly flatten them and add them to the accumulator
    if (value.parent) {
      acc = acc.concat(flattenParents(value.parent))
    }
    return acc
  }, [])
}
