import { Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Mosaic, MosaicWindow } from 'react-mosaic-component'
import 'react-mosaic-component/react-mosaic-component.css'
import {
  Hooks,
  useColumnOrder,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table'
import usePersistState from '../../hooks/persistState'
import { useMacsTranslationColumn } from '../../hooks/translation'
import ContextMenuInfo from './components/contextMenuInfo'
import WarningMessage from './components/warningMessage'
import './custom.css'
import { contextMenuHook } from './hooks/contextMenuHook'
import { orderHookClient } from './hooks/orderHookClient'
import { ColumnDefinition, ColumnManagerWithPreset, orderHookServer } from './hooks/orderHookServer'
import TableDesign from './Table'
import { SidePanelProps, TableMainProps, TITLE_MAP, ViewId } from './Table.Props'
import TableContainer, { MenuInfoType, useTableHook } from './Table.Store'

/**
 * @render react
 * @name TableMain container
 * @description Renders table components and handles all logic
 */
const TableMain = <D extends {}>({
  useSingleRowSelect,
  data,
  columns: columnStructure,
  name,
  hasMore,
  loadNextPage,
  isNextPageLoading,
  totalCount,
  onRowSelect,
  autoResetSelectedRows,
  autoResetPage,
  defaultSelectedRowIds,
  screenContextList,
  fieldsActive,
  tablePanelWidth,
  onRelease,
  SidePanel,
  applyId,
  tableHeaderProps,
  Filter,
  itemSize,
  onRowDoubleClick,
  isDisplayRow,
  isServerColumns
}: TableMainProps<D>) => {
  const translated = useMacsTranslationColumn({ columns: columnStructure })
  const tableColumns = React.useMemo(() => translated, [translated])
  const hiddenServerColumns = tableColumns
    .filter((tableColumn) => !tableColumn.isVisible)
    .map((tableColumn) => tableColumn.accessor!.toString())

  const [columnConfig] = usePersistState<ColumnManagerWithPreset<D>>(
    'columnManager',
    'localStorage'
  )
  const [currentColumnPreset] = usePersistState<{
    [key: string]: string
  }>('columnPreset', 'localStorage')
  const preset = currentColumnPreset[name] ? currentColumnPreset[name] : 'default'
  const currentColumnConfiguration = columnConfig[name]?.[preset]

  const hiddenClientColumns =
    currentColumnConfiguration?.filter((config) => !config.isVisible).map((config) => config.id) ||
    []
  const sortColumn = (columnOne: ColumnDefinition<D>, columnTwo: ColumnDefinition<D>) => {
    if (columnOne.order < columnTwo.order) return -1
    return columnOne.order > columnTwo.order ? 1 : 0
  }
  const columnOrderClient =
    currentColumnConfiguration
      ?.sort((columnOne, columnTwo) => sortColumn(columnOne, columnTwo))
      .map((column) => column.id) || []

  const columnOrdeServer =
    tableColumns
      .sort((columnOne, columnTwo) => {
        const orderOne = columnOne?.order || 0
        const orderTwo = columnTwo?.order || 0
        if (orderOne < orderTwo) return -1
        return orderOne > orderTwo ? 1 : 0
      })
      .map((column) => column.accessor!.toString()) || []
  const itemCount = hasMore ? data.length + 1 : data.length === 1 ? data.length + 1 : data.length
  const loadMoreItems = loadNextPage ? (isNextPageLoading ? () => {} : loadNextPage) : () => {}
  const isItemLoaded = (index: number) => !hasMore || index < data.length
  const rowCount: number = totalCount ?? data.length
  const orderHook = isServerColumns ? orderHookServer : orderHookClient
  const [
    {
      showCountModal,
      warning: { index, showWarning }
    },
    {
      setTableHook,
      setHasSidebar,
      setShowCount,
      setToggleFooter,
      setDisableRowSelect,
      setShowWarning,
      setIsOpen
    }
  ] = useTableHook()
  const tableHook = useTable<D>(
    {
      autoResetSelectedRows: autoResetSelectedRows,
      columns: tableColumns,
      data: data,
      initialState: {
        //@ts-expect-error - Type '{}' is not assignable to type 'Record<IdType<D>, boolean>' - but having it null crashes the appllication
        selectedRowIds: defaultSelectedRowIds || {},
        hiddenColumns: isServerColumns ? hiddenServerColumns : hiddenClientColumns,
        columnOrder: isServerColumns ? columnOrdeServer : columnOrderClient
      },
      autoResetPage: !autoResetPage
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks: Hooks<D>) =>
      !isDisplayRow && contextMenuHook(hooks, screenContextList, useSingleRowSelect),
    (hooks: Hooks<D>) => !isDisplayRow && orderHook(hooks, name)
  )
  //Update all Table State Values
  const setTableAction = () => {
    setTableHook({
      ...tableHook,
      isItemLoaded,
      itemCount,
      loadMoreItems,
      hasMore,
      isNextPageLoading,
      totalCount,
      useSingleRowSelect,
      name,
      rowCount,
      tableHeaderProps,
      fieldsActive,
      itemSize,
      onRowDoubleClick,
      isDisplayRow
    })
  }
  //On initial load only set these state values
  useEffect(() => {
    setHasSidebar(!!SidePanel)
  }, [])
  //On row select update the state values
  useEffect(() => {
    setTableAction()
    onRowSelect && onRowSelect(tableHook.selectedFlatRows)
  }, [tableHook.selectedFlatRows])
  //On remove/reorder columns update the state value
  useEffect(() => {
    setTableAction()
  }, [tableHook.visibleColumns])
  //On fieldsActive status change update the state vaue
  useEffect(() => {
    if (!fieldsActive) {
      setShowCount(MenuInfoType.None)
      setToggleFooter(false)
      setIsOpen(false)
    }
  }, [fieldsActive])
  if (SidePanel) {
    return (
      <Mosaic<ViewId>
        renderTile={(id, path) => (
          <MosaicWindow<ViewId> draggable={false} path={path} title={TITLE_MAP[id]}>
            {id === 'table' && (
              <Flex justifyContent="center" alignItems="center" flex={1} height="100%">
                {showCountModal !== MenuInfoType.None && <ContextMenuInfo cardRef={null} />}
                <TableDesign Filter={Filter} />
              </Flex>
            )}
            {id === 'sidebar' && (
              <Flex
                justifyContent="center"
                alignItems="center"
                flex={1}
                height="100%"
                id="side-panel-container"
              >
                {showWarning && (
                  <WarningMessage
                    index={index}
                    setDisableRowSelect={setDisableRowSelect}
                    setShowWarning={setShowWarning}
                    toggleAllRowsSelected={tableHook.toggleAllRowsSelected}
                    useSingleRowSelect
                    rows={tableHook.rows}
                    applyId={applyId}
                  />
                )}
                <SidePanel
                  selectedRowIds={tableHook.selectedRowIds}
                  selectedFlatRows={tableHook.selectedFlatRows}
                  fieldsActive={fieldsActive}
                  setDisableRowSelect={setDisableRowSelect}
                  setShowWarning={setShowWarning}
                />
              </Flex>
            )}
          </MosaicWindow>
        )}
        //allow resizing only if fields are active
        resize={{ minimumPaneSizePercentage: fieldsActive ? 0 : 100 }}
        //on Release of resize
        onRelease={(newNode) => onRelease && onRelease(newNode)}
        initialValue={{
          direction: 'row',
          first: 'table',
          second: 'sidebar',
          splitPercentage: fieldsActive ? tablePanelWidth : 100
        }}
      />
    )
  }
  return (
    <Flex justifyContent="center" alignItems="center" flex={1} height="100%">
      <TableDesign Filter={Filter} />
    </Flex>
  )
}

/**
 * @render react
 * @name Table container
 * @description Main Renders Table - used as main entry point for table component
 */
const Table = <D extends {}>({
  data,
  columns,
  name,
  hasMore,
  loadNextPage,
  isNextPageLoading,
  totalCount,
  useSingleRowSelect,
  onRowSelect,
  autoResetSelectedRows,
  autoResetPage,
  defaultSelectedRowIds,
  screenContextList,
  SidePanel,
  tablePanelWidth,
  fieldsActive,
  onRelease,
  applyId,
  tableHeaderProps,
  Filter,
  itemSize,
  onRowDoubleClick,
  isDisplayRow,
  isServerColumns
}: TableMainProps<D>) => (
  <TableContainer scope={name}>
    <TableMain
      useSingleRowSelect={useSingleRowSelect}
      name={name}
      data={data}
      columns={columns}
      hasMore={hasMore}
      loadNextPage={loadNextPage}
      isNextPageLoading={isNextPageLoading}
      totalCount={totalCount}
      onRowSelect={onRowSelect}
      autoResetSelectedRows={autoResetSelectedRows}
      autoResetPage={autoResetPage}
      defaultSelectedRowIds={defaultSelectedRowIds}
      screenContextList={screenContextList}
      SidePanel={SidePanel}
      tablePanelWidth={tablePanelWidth}
      fieldsActive={fieldsActive}
      onRelease={onRelease}
      applyId={applyId}
      tableHeaderProps={tableHeaderProps}
      Filter={Filter}
      itemSize={itemSize}
      onRowDoubleClick={onRowDoubleClick}
      isDisplayRow={isDisplayRow}
      isServerColumns={isServerColumns}
    />
  </TableContainer>
)
export type { SidePanelProps }
export default Table
