import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  Text
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ErrorMessage, FieldProps } from 'formik'
import * as React from 'react'
import { isFirefox } from 'react-device-detect'
import { IMaskInput } from 'react-imask'
import { useMacsTranslationFunction } from '../../../hooks/translation'
import { theme } from '../../../theme'
import { Label, LabelProps } from '../styles'

const StyledInput = styled(Input)`
  border: ${theme.colors.border.default};
  margin-bottom: 0;
  max-width: ${(props) => props.maxWidth};
`
const dontForward = ['maxWidth', 'paddingX']
const MaskedInput = styled(IMaskInput, {
  shouldForwardProp: (prop) => {
    if (dontForward.includes(prop.toString())) {
      return false
    }
    return true
  }
})<InputProps>`
  border: ${theme.colors.border.default};
  margin-bottom: 0;

  align-items: center;
  appearance: none;
  background-color: #fff;
  border-color: inherit;
  border-radius: 0.25rem;
  border: 1px solid #d9d9d9;
  display: flex;
  font-size: 1rem;
  height: 2.5rem;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  &:focus {
    z-index: 1;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }

  &:disabled {
    opacity: 0.4;
  }
`

type ConnectedNumberInputProps = LabelProps &
  FieldProps &
  NumberInputProps & {
    label?: string
    unit: string
    precision?: number
    inputRightAddonText?: string
    superScript?: string
    isRequired?: boolean
    disabled?: boolean
    updateOnBlur?: boolean
  }

const ConnectedNumberInput: React.FC<ConnectedNumberInputProps> = ({
  label,
  field,
  placeholder,
  unit,
  precision,
  step,
  min,
  max,
  defaultValue,
  form,
  onChange,
  onBlur,
  isDisabled,
  inputRightAddonText,
  superScript,
  isRequired,
  disabled,
  updateOnBlur,
  ...rest
}) => {
  const translation = useMacsTranslationFunction()

  const renderInput = () => {
    if (precision) {
      const { maxWidth, paddingX, ...maskedRest } = rest

      const configBlocks = {
        thisInput: {
          mask: Number,
          scale: precision, // digits after point, 0 for integers
          signed: true, // disallow negative
          thousandsSeparator: '', // any single char
          padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
          normalizeZeros: true, // appends or removes zeros at ends
          radix: '.', // fractional delimiter
          mapToRadix: ['.'] // symbols to process as radix
        }
      }
      return (
        <MaskedInput
          {...field}
          {...maskedRest}
          as={StyledInput}
          type="text"
          id={field.name}
          placeholder={placeholder || label}
          paddingX="12px"
          blocks={configBlocks}
          mask="thisInput"
          value={field.value?.toString() || '0'}
          maxWidth={isFirefox && inputRightAddonText ? 'calc(100% - 36px)' : 'auto'}
        />
      )
    }
    if (updateOnBlur) {
      return (
        <NumberInput
          width="100%"
          precision={precision}
          step={step || 1}
          min={min || 0}
          max={max || Number.MAX_SAFE_INTEGER}
          onBlur={(e) => {
            //@ts-ignore - Property 'defaultValue' does not exist on type 'EventTarget & HTMLDivElement'
            form.setFieldValue(field.name, parseInt(e.target.defaultValue))
          }}
          defaultValue={parseInt(field.value) || 0}
          isDisabled={isDisabled}
          maxWidth={isFirefox && inputRightAddonText ? 'calc(100% - 36px)' : 'auto'}
        >
          <NumberInputField
            id={field.name}
            name={field.name}
            roundedLeft={!!unit ? 0 : 4}
            focusBorderColor="brand.500"
            placeholder={placeholder || label}
            paddingX="12px"
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )
    }

    return (
      <NumberInput
        width="100%"
        precision={precision}
        step={step || 1}
        min={min || 0}
        max={max || Number.MAX_SAFE_INTEGER}
        defaultValue={defaultValue}
        //@ts-ignore - onChange is there
        onChange={(valueAsString: string, valueAsNumber: number) => {
          onChange && onChange(valueAsString, valueAsNumber)
          form.setFieldValue(field.name, valueAsNumber)
        }}
        value={parseInt(field.value) || 0}
        focusInputOnChange={false}
        isDisabled={isDisabled}
        maxWidth={isFirefox && inputRightAddonText ? 'calc(100% - 36px)' : 'auto'}
      >
        <NumberInputField
          onBlur={onBlur}
          onKeyUp={() => form.setFieldTouched(field.name, true)}
          id={field.name}
          name={field.name}
          roundedLeft={!!unit ? 0 : 4}
          focusBorderColor="brand.500"
          placeholder={placeholder || label}
          paddingX="12px"
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    )
  }

  const addonText = inputRightAddonText && (
    <Text>
      {translation(inputRightAddonText)}
      {superScript && <Text as="sup">{translation(superScript)}</Text>}
    </Text>
  )

  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      {label && <Label htmlFor={field.name}>{translation(label)}</Label>}
      <InputGroup width="100%" background="transparent">
        <InputLeftAddon
          background="transparent"
          children={
            <React.Fragment>
              {isRequired && !disabled && (
                <Box width="1px" height="100%" background={theme.colors.background.required} />
              )}
              {!isRequired && !disabled && (
                <Box width="1px" height="100%" background="transparent" />
              )}
            </React.Fragment>
          }
          bg="white"
          border="none"
          padding="4px"
        />
        {!!unit && <InputLeftAddon>{translation(unit)}</InputLeftAddon>}
        {renderInput()}
        {inputRightAddonText && <InputRightAddon paddingX={1} children={addonText} />}
      </InputGroup>
      <ErrorMessage name={field.name}>
        {(msg) => {
          return (
            <Text mt="4px" ml="8px" color="intent.error">
              {msg}
            </Text>
          )
        }}
      </ErrorMessage>
    </Flex>
  )
}

export default ConnectedNumberInput

ConnectedNumberInput.defaultProps = {
  mb: 1,
  fontWeight: 'lighter',
  precision: 0
}
