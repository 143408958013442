import { Box, IconButton, Modal, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react'
import * as CSS from 'csstype'
import * as React from 'react'
import { X } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { useMacsTranslation } from '../../hooks/translation'
import Card from '../Card'
import CardHeader from '../Card/CardHeader'
import { H5 } from '../Typography'

type ModalWrapProps = ModalProps & {
  title: string
  showCloseButton?: boolean
  maxWidth?: string
  overflow?: CSS.Property.Overflow
  footer?: Function
}

const ModalWrap: React.FC<ModalWrapProps> = ({
  children,
  title,
  onClose,
  isOpen,
  size,
  showCloseButton = true,
  maxWidth,
  overflow,
  footer,
  ...rest
}) => {
  const translated = useMacsTranslation({ text: title })

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  return (
    <Modal
      size={size || '6xl'}
      isCentered
      preserveScrollBarGap
      scrollBehavior="inside"
      trapFocus
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
    >
      <ModalOverlay overflow="hidden" />
      <ModalContent
        shadow="none"
        bg="transparent"
        rounded="md"
        position="initial"
        overflow="visible"
        maxWidth={maxWidth}
        margin={0}
        justifyContent="center"
      >
        <Card
          overflow={isTabletOrMobile ? 'auto' : 'inherit'}
          id="main-modal-container"
          //@ts-ignore - data-testid does exist in the rest object
          data-testid={rest['data-testid']}
          rounded="md"
          m={1}
          maxHeight="90vh"
        >
          <CardHeader alignItems="center" flexDirection="row" justifyContent="space-between">
            <H5 mb={0}>{translated}</H5>
            {showCloseButton && (
              <IconButton
                onClick={onClose}
                size="sm"
                aria-label="Close Modal"
                icon={<X />}
                variant="ghost"
              />
            )}
          </CardHeader>
          <Box overflow={overflow || `visible`}>{children}</Box>
          {/* Sticky footer always at the bottom of the modal */}
          {footer && footer()}
        </Card>
      </ModalContent>
    </Modal>
  )
}

export default ModalWrap

ModalWrap.defaultProps = {
  title: 'Modal Heading'
}
