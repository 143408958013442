import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { Row } from 'react-table'
import { Button } from '../..'
import { H5, P } from '../../Typography'

type WarningMessageProps = {
  index?: number | null
  useSingleRowSelect?: boolean
  toggleAllRowsSelected: (value: boolean) => void
  setDisableRowSelect: (disabled?: boolean | undefined) => void | Promise<void>
  setShowWarning: (warning?: boolean | undefined) => void | Promise<void>
  rows: Row<any>[]
  applyId: string
}

/**
 * @render react
 * @name WarningMessage container
 * @description Modal that shows over sidebar
 */
const WarningMessage: React.FC<WarningMessageProps> = ({
  index,
  setDisableRowSelect,
  setShowWarning,
  toggleAllRowsSelected,
  useSingleRowSelect,
  rows,
  applyId
}) => {
  return (
    <Flex
      background="white"
      flexDirection="column"
      alignItems="center"
      position="absolute"
      zIndex="2"
      padding={2}
      border="1px solid #E1E1E1"
      borderRadius="8px"
    >
      <H5> You have unsaved changes.</H5>
      <Box mt={2}>
        <P>Discard changes</P>
      </Box>
      <Flex>
        <Button
          text="Update"
          color="white"
          background="intent.success"
          variant="outline"
          mr={2}
          onClick={() => {
            document.getElementById(applyId)?.click()
            setDisableRowSelect && setDisableRowSelect(false)
            setShowWarning && setShowWarning(false)

            setTimeout(() => {
              if (index !== undefined && index !== null) {
                useSingleRowSelect && toggleAllRowsSelected(false)
                rows[index].toggleRowSelected()
              }
            }, 0)
          }}
        />
        <Button
          text="Discard"
          color="white"
          background="intent.error"
          variant="outline"
          mr={2}
          onClick={() => {
            if (index !== undefined && index !== null) {
              useSingleRowSelect && toggleAllRowsSelected(false)
              rows[index].toggleRowSelected()
            }
            setDisableRowSelect && setDisableRowSelect(false)
            setShowWarning && setShowWarning(false)
          }}
        />
        <Button
          text="Cancel"
          autoFocus
          variant="outline"
          onClick={() => {
            setShowWarning && setShowWarning(false)
          }}
        />
      </Flex>
    </Flex>
  )
}

export default WarningMessage
