import { RefObject, useEffect } from 'react'

const useClickedOutside = (
  ref: RefObject<HTMLDivElement>,
  state: {
    isOpen: boolean
    setIsOpen: (
      isOpen: boolean
    ) => void | Promise<void> | React.Dispatch<React.SetStateAction<boolean>>
  }
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node) && state.isOpen) {
        state.setIsOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, state.isOpen])
}

export default useClickedOutside
