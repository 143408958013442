import { useEnumQuery } from '../generated/graphql'

const useGetEnum = (enumName: string, currentOption?: string) => {
  const { data: enumData } = useEnumQuery({
    variables: { name: enumName }
  })
  const enumOptions =
    enumData?.enum?.values?.map(({ name }) => ({
      label: name,
      value: name
    })) || []
  const selectedOption = currentOption
    ? enumOptions.find((option) => option.value === currentOption)
    : null

  return { enumData, enumOptions, selectedOption }
}

export default useGetEnum
