import { MenuList } from '..'
import { flattenMenus } from './flattenMenus'

/**
 * @name convertToSave
 * @description convert menu list to object that can be saved by the mutation
 * @param menu MenuList Array or Menu List Object
 * @returns MenuList Array
 */
export const convertToSave = (menu: MenuList | MenuList[]): any => {
  if (Array.isArray(menu)) {
    return menu.flatMap((m) => {
      return {
        title: m.title,
        screenId: m.screenId,
        order: m.order,
        enabled: m.enabled,
        defaultGroupId: m.defaultGroupId,
        children: convertToSave(m.children || [])
      }
    })
  } else {
    return {
      title: menu.title,
      screenId: menu.screenId,
      order: menu.order,
      parentId: menu.parentId,
      enabled: menu.enabled,
      defaultGroupId: menu.defaultGroupId,
      children: convertToSave(menu.children || [])
    }
  }
}

/**
 * @name convertDelete
 * @description convert menu list to object that can be deleted by the mutation
 * @param menu MenuList Array or Menu List Object
 * @returns MenuList Array
 */
const convertDelete = (menu: MenuList | MenuList[]): any => {
  if (Array.isArray(menu)) {
    return menu.flatMap((m) => {
      return {
        id: m.id,
        isNew: m.isNew,
        children: convertDelete(m.children || [])
      }
    })
  } else {
    return {
      id: menu.id,
      isNew: menu.isNew,
      children: convertDelete(menu.children || [])
    }
  }
}

/**
 * @name mutations
 * @description converts and creates the Create, Update, Delete objects that get sent to the BE when being saved
 * @param menu MenuList Array
 * @param parentIds First level parent Ids
 * @returns Object of create,
    removeScreen,
    createDefaultScreen,
    creatDefaultGroup,
    update
 */
export const mutations = (menus: MenuList[], _parentIds: number[]) => {
  const newMenus = flattenMenus(menus).filter((menu) => menu.isNew)
  const create = flattenMenus(menus)
    .filter((menu) => menu.isNew)
    .map((menu) => {
      return convertToSave(menu)
    })

  const removeScreen = flattenMenus(menus)
    .filter((menu) => menu.removeFromUpdate)
    .flatMap((child) => child.children)
    .filter((screen) => screen?.screenId && !screen.defaultOnly)
    .map((screen) => ({ id: screen?.id }))

  const update = flattenMenus(menus)
    .map((menu) => {
      const findParent = menus.find((parent) => parent.id === menu.parentId)
      return {
        ...menu,
        defaultGroupOnly: findParent?.defaultGroupOnly || menu.defaultGroupOnly
      }
    })
    .filter((menu) => {
      const isRemoved = removeScreen.find((removed) => removed.id === menu.id)
      return (
        menu.isUpdated &&
        !menu.removeFromUpdate &&
        !menu.defaultOnly &&
        !menu.defaultGroupOnly &&
        !isRemoved &&
        !flattenMenus(newMenus).find((newMenu) => newMenu.id === menu.id)
      )
    })
    .map((menu) => {
      let defaultGroupId = menu.defaultGroupId
      if (menu.parentId === null && menu.screenId) {
        defaultGroupId = menu.screenId.toString()
      }
      return {
        id: menu.id,
        parentId: menu.parentId,
        order: menu.order,
        title: menu.title,
        enabled: menu.enabled,
        defaultGroupId: defaultGroupId
      }
    })

  const createDefaultScreen = flattenMenus(menus)
    .filter((menu) => {
      const parentExists = flattenMenus(create).find(
        (createMenu) => createMenu.screenId === menu.screenId
      )

      return menu.defaultOnly && !parentExists
    })
    .map((menu) => {
      return {
        id: menu.id,
        parentId: menu.parentId,
        order: menu.order,
        screenId: menu.screenId,
        title: menu.title,
        enabled: menu.enabled
      }
    })

  const creatDefaultGroup = flattenMenus(menus)
    .filter((menu) => menu.defaultGroupOnly)
    .map((menu) => {
      return convertToSave(menu)
    })

  const convertToDelete = flattenMenus(menus)
    .filter((menu) => menu.isNew)
    .flatMap((menu) => {
      return convertDelete(menu)
    })
  const removeCreate = flattenMenus(convertToDelete)
    .filter((menu) => menu.isNew === undefined)
    .map((menu) => ({ id: menu.id }))

  return {
    create,
    removeScreen,
    createDefaultScreen,
    creatDefaultGroup,
    update,
    removeCreate
  }
}
