import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react'
import React from 'react'
import { useMacsTranslation } from '../../hooks/translation'

const Tooltip: React.FC<ChakraTooltipProps> = ({
  label,
  shouldWrapChildren,
  placement,
  children,
  ...rest
}) => {
  const translation = useMacsTranslation({ text: label?.toString() || '' })

  return (
    <ChakraTooltip
      hasArrow
      label={translation}
      aria-label={translation}
      shouldWrapChildren={shouldWrapChildren}
      placement={placement}
      {...rest}
    >
      {children}
    </ChakraTooltip>
  )
}

export default Tooltip
