import {
  Box,
  Button as ChakraButton,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Switch
} from '@chakra-ui/react'
import _, { isArray } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { MoreVertical, Plus, Square } from 'react-feather'
import { ReactSortable } from 'react-sortablejs'
import { HeaderProps, Hooks, IdType, Renderer } from 'react-table'
import { Button, Col, Container, DropdownMenu, Row, StyledInput, Tooltip } from '../..'
import { useClickedOutside } from '../../../hooks'
import usePersistState from '../../../hooks/persistState'
import DropDownButton from '../../DropDownButton'
import { useTableHook } from '../Table.Store'

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.2,
  ghostClass: 'ghost',
  handle: '.handle'
}

export type ColumnDefinition<D extends {}> = {
  id: IdType<D>
  title: Renderer<HeaderProps<D>> | undefined
  isVisible?: boolean
  order: number
}

export type ColumnManager<D extends {}> = {
  [key: string]: ColumnDefinition<D>[]
}

export type ColumnManagerWithPreset<D extends {}> = {
  [key: string]: {
    [key: string]: ColumnDefinition<D>[]
  }
}

/**
 * @name orderHook container
 * @description renders context menu on header and rows
 * @param hooks react table hooks
 */
export const orderHookClient = <D extends {}>(hooks: Hooks<D>, name: string) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    // Let's make a column for selection
    {
      id: 'orderMenuClient',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: (props) => {
        const [
          { localState, fetchConfig, isOpen },
          { setLocalState, setFetchConfig, setIsOpen }
        ] = useTableHook()
        const wrapperRef = useRef<HTMLDivElement>(null)
        useClickedOutside(wrapperRef, { isOpen, setIsOpen })
        const [showSaveAs, setShowSaveAs] = useState(false)
        const [saveAs, setSaveAs] = useState('')

        const [currentColumnPreset, setCurrentColumnPreset] = usePersistState<{
          [key: string]: string
        }>('columnPreset', 'localStorage')
        const preset = currentColumnPreset[name] || 'default'
        const isDefaultPreset = preset === 'default'

        const [columnConfig, setColumnConfig] = usePersistState<ColumnManagerWithPreset<D>>(
          'columnManager',
          'localStorage'
        )
        const [defaultColumnConfig, setDefaultColumnConfig] = usePersistState<ColumnManager<D>>(
          'defaultColumnConfig',
          'localStorage'
        )

        const savedPresets = columnConfig[name]
          ? Object.keys(columnConfig[name]).map((key) => ({
              title: key,
              onClick: () => {
                const pre = localState
                  .map((ls, index) => ({
                    ...ls,
                    ...columnConfig[name][key][index]
                  }))
                  .sort((columnOne, columnTwo) => {
                    if (columnOne.order < columnTwo.order) return -1
                    return columnOne.order > columnTwo.order ? 1 : 0
                  })
                setLocalState(pre)
                setCurrentColumnPreset({ [name]: key })
                setTimeout(() => {
                  pre.forEach((item) => {
                    props.toggleHideColumn(item.id, !item.isVisible)
                  })
                  props.setColumnOrder(pre.map((d) => d.id))
                }, 0)
              }
            }))
          : []

        useEffect(() => {
          if (!defaultColumnConfig[name] || fetchConfig) {
            const columnManager = props.allColumns
              .filter(
                (column) =>
                  column.id !== 'contextMenu' &&
                  column.id !== 'orderMenu' &&
                  column.id !== 'orderMenuClient'
              )
              .map((column, index) => {
                const foundColumnConfig =
                  columnConfig[name]?.[preset]?.find((s) => s.id === column.id) || {}
                const isVisible = columnConfig[name]?.[preset]
                  ? columnConfig[name][preset]?.find((s) => s.id === column.id)?.isVisible
                  : true
                return {
                  id: column.id,
                  title: column.Header,
                  isVisible,

                  order: columnConfig[name]
                    ? _.findIndex(columnConfig[name][preset], foundColumnConfig)
                    : index
                }
              })
            setFetchConfig(false)
            setLocalState(columnManager)
            setColumnConfig({
              ...columnConfig,
              [name]: {
                ...columnConfig[name],
                [preset]: columnManager
              }
            })
            setDefaultColumnConfig({ ...defaultColumnConfig, [name]: columnManager })
          }
        }, [])

        return (
          <Popover isLazy isOpen={isOpen}>
            <PopoverTrigger>
              <ChakraButton
                minWidth={0}
                padding={0}
                background="transparent"
                onClick={() => setIsOpen(true)}
              >
                <Flex alignItems="center" cursor="pointer">
                  <Flex flexDirection="column">
                    <Icon as={Square} fontSize="8px" />
                    <Icon as={Square} fontSize="8px" />
                    <Icon as={Square} fontSize="8px" />
                  </Flex>
                  <Icon as={Plus} fontSize="8px" />
                </Flex>
              </ChakraButton>
            </PopoverTrigger>
            <PopoverContent ref={wrapperRef}>
              <PopoverArrow />
              <PopoverHeader>
                <DropdownMenu padding={1} menuItems={savedPresets} title={preset} portal={false} />
              </PopoverHeader>
              <PopoverCloseButton onClick={() => setIsOpen(false)} />
              <PopoverBody padding={0}>
                {showSaveAs && (
                  <Flex padding={1}>
                    <StyledInput
                      margin={0}
                      placeholder="Save As"
                      autoFocus
                      value={saveAs}
                      onChange={(e) => setSaveAs(e.currentTarget.value)}
                    />
                  </Flex>
                )}
                {!showSaveAs && (
                  <ReactSortable
                    list={localState}
                    setList={(newState) => {
                      const s = newState.map((ns, i) => ({ ...ns, order: i }))
                      setLocalState(s)
                    }}
                    {...sortableOptions}
                    onEnd={() => {
                      props.setColumnOrder(localState.map((d) => d.id))
                    }}
                  >
                    {isArray(localState) &&
                      localState.map((item, index) => (
                        <Flex
                          flex={1}
                          minHeight="35px"
                          alignItems="center"
                          key={item.id}
                          borderBottom={
                            index + 1 < localState.length ? '1px solid #E7E6E6' : 'none'
                          }
                        >
                          <Container fluid padding={1} paddingY={0}>
                            <Row middle="xs">
                              <Col xs={1}>
                                <Flex flexWrap="nowrap">
                                  <Flex className="handle" cursor="move">
                                    <MoreVertical height="14px" />
                                    <Box marginLeft="-20px">
                                      <MoreVertical height="14px" />
                                    </Box>
                                  </Flex>
                                </Flex>
                              </Col>
                              <Col xs>{item.title}</Col>
                              <Col xs={2}>
                                <Tooltip
                                  hasArrow
                                  label="One header must be enabled"
                                  isDisabled={
                                    localState.filter((ls) => ls.isVisible).length > 1 ||
                                    !item.isVisible
                                  }
                                >
                                  <Box>
                                    <Switch
                                      size="md"
                                      color="menuManager.switch"
                                      defaultChecked={item.isVisible}
                                      isChecked={item.isVisible}
                                      isDisabled={
                                        item.isVisible &&
                                        localState.filter((ls) => ls.isVisible).length === 1
                                      }
                                      onChange={() => {
                                        props.toggleHideColumn(item.id, item.isVisible)
                                        const c = localState.map((ls) => {
                                          if (ls.id === item.id) {
                                            return {
                                              ...ls,
                                              isVisible: !ls.isVisible
                                            }
                                          }
                                          return ls
                                        })
                                        setLocalState(c)
                                      }}
                                    />
                                  </Box>
                                </Tooltip>
                              </Col>
                            </Row>
                          </Container>
                        </Flex>
                      ))}
                  </ReactSortable>
                )}
              </PopoverBody>
              <PopoverFooter display="flex" justifyContent="center">
                {showSaveAs && (
                  <Button
                    bg="primary.base"
                    color="text.onPrimary"
                    text="Save Preset"
                    onClick={() => {
                      setIsOpen(false)
                      setShowSaveAs(false)
                      setColumnConfig({
                        ...columnConfig,
                        [name]: {
                          ...columnConfig[name],
                          [saveAs]: localState
                        }
                      })
                      setCurrentColumnPreset({ [name]: saveAs })
                    }}
                  />
                )}
                {!showSaveAs && (
                  <DropDownButton
                    text={isDefaultPreset ? 'Save As' : 'Save'}
                    bg="primary.base"
                    color="text.onPrimary"
                    portal={false}
                    onClick={() => {
                      if (isDefaultPreset) {
                        setShowSaveAs(true)
                      } else {
                        setIsOpen(false)
                        setColumnConfig({
                          ...columnConfig,
                          [name]: {
                            ...columnConfig[name],
                            [preset]: localState
                          }
                        })
                      }
                    }}
                    menuItems={[
                      {
                        title: 'Save As',
                        onClick: () => {
                          setShowSaveAs(true)
                        }
                      },
                      {
                        title: 'Rest',
                        onClick: () => {
                          const pre = localState
                            .map((ls, index) => ({
                              ...ls,
                              ...columnConfig[name][currentColumnPreset[name]][index]
                            }))
                            .sort((columnOne, columnTwo) => {
                              if (columnOne.order < columnTwo.order) return -1
                              return columnOne.order > columnTwo.order ? 1 : 0
                            })
                          setTimeout(() => {
                            pre.forEach((item) => {
                              props.toggleHideColumn(item.id, !item.isVisible)
                            })
                            props.setColumnOrder(pre.map((d) => d.id))
                          }, 0)
                          setLocalState(pre)
                        }
                      }
                    ]}
                  />
                )}
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        )
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to render a checkbox

      flex: 0,
      minWidth: 8,
      width: 8,
      isVisible: false
    }
  ])
}
