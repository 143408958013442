import { IconButton } from '@chakra-ui/react'
import { FieldArrayRenderProps, FieldInputProps } from 'formik'
import React from 'react'
import { Minus } from 'react-feather'

const RemoveField: React.FC<{
  field: FieldInputProps<any>
  arrayHelpers: FieldArrayRenderProps
  index: number
  onRemove?: Function
}> = ({ field, arrayHelpers, index, onRemove }) => {
  return (
    <IconButton
      marginLeft="auto"
      height="15px"
      width="15px"
      minWidth="auto"
      aria-label="add more"
      icon={<Minus />}
      bg="primary.base"
      color="white"
      onClick={() => {
        if (onRemove) {
          onRemove()
        } else {
          arrayHelpers.form.setFieldValue(field.name, '')
          arrayHelpers.remove(index)
          arrayHelpers.form.values?.filterOnRemove && arrayHelpers.form.handleSubmit()
        }
      }}
    />
  )
}

export default RemoveField
