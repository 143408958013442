/**
 * This function serves to strip the value property
 * out of the form's Select component.
 * @param {object} data - This is the object containing
 * all the form values that we'd like to clean up.
 */
export function stripSelectValues<T extends object>(data: T, stripException?: string): T {
  try {
    Object.keys(data).forEach((item: string) => {
      if (item.startsWith('react-select')) {
        // @ts-ignore - No index signature with a parameter of type 'string' was found on type '{}'
        delete data[item]
      }
      // @ts-ignore - No index signature with a parameter of type 'string' was found on type '{}'
      const val = data[item]
      if (Boolean(Array.isArray(val))) {
        // @ts-ignore - No index signature with a parameter of type 'string' was found on type '{}'
        data[item] = val.map((obj: any) => {
          if (Boolean(Array.isArray(obj))) {
            return stripSelectValues(obj)
          }

          if (stripException !== undefined && stripException in obj) {
            return { [stripException]: obj.value }
          }

          if ('label' in obj && 'value' in obj) {
            return obj.value
          }

          return stripSelectValues(obj)
        })
      }
      if (val && typeof val === 'object' && 'label' in val && 'value' in val) {
        const selectValue = val.value
        // @ts-ignore - No index signature with a parameter of type 'string' was found on type '{}'
        data[item] = selectValue
      }
    })
    return data
  } catch (error) {
    return data
  }
}

export function getSelectValue(data: { label: string; value: string } | string | number) {
  if (!data) return
  if (typeof data === 'object') {
    return data.value
  }
  return data
}
