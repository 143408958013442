import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React from 'react'
import { Button, Col, ConnectedSelect, Container, Row } from '../../../components'
import { H5, P } from '../../../components/Typography'

const CopyMenu: React.FC = () => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Flex flex={1} flexDirection="column" textAlign="left">
          <H5>Copy Menu</H5>
          <Flex>
            <P fontSize="12px">
              Copying this menu enables you to use USERNAME menu labels & display, as seen above,
              for another user.
            </P>
          </Flex>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Container fluid>
            <Row>
              <Col xs={4}>
                <Field
                  name="copyTo"
                  label="Copy menu to"
                  options={[]}
                  component={ConnectedSelect}
                />
              </Col>
              <Flex flex={1} justifyContent="flex-end">
                <Button text="Apply" bg="primary.base" color="text.onPrimary" mt="25px" />
              </Flex>
            </Row>
          </Container>
        </Formik>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default CopyMenu
