import React, { FC, PropsWithChildren } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AdvancedDataProvider from './AdvancedData.context'
import ApplicationProvider from './ApplicationProvider.context'
import AuthenticationProvider from './AuthenticationProvider.context'
import CarreirSetupProvider from './CarrierSetupProvider.context'
import DestinationProvider from './DestinationProvider.context'
import GoodReceiptProvider from './GoodReceipt.context'
import ProducSetupProvider from './ProductSetupProvider.context'
import StockOwnerProvider from './StockOwnerProvider.context'
import GlobalTableStore from './Stores/Global.Table.Store'
import TableProvider from './TableProvider.context'
import UserProvider from './UserProvider.context'

const DragAndDrop = ({ children }: PropsWithChildren<{}>) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
}

export const AppProvider: FC = ({ children }) => {
  return (
    <DragAndDrop>
      <AuthenticationProvider>
        <ApplicationProvider>
          <GlobalTableStore isGlobal>
            <DestinationProvider>
              <ProducSetupProvider>
                <StockOwnerProvider>
                  <CarreirSetupProvider>
                    <UserProvider>
                      <AdvancedDataProvider>
                        <GoodReceiptProvider>
                          <TableProvider>{children}</TableProvider>
                        </GoodReceiptProvider>
                      </AdvancedDataProvider>
                    </UserProvider>
                  </CarreirSetupProvider>
                </StockOwnerProvider>
              </ProducSetupProvider>
            </DestinationProvider>
          </GlobalTableStore>
        </ApplicationProvider>
      </AuthenticationProvider>
    </DragAndDrop>
  )
}
