import { Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import { Button, CardFooter, ModalWrap } from '..'
import { useMacsTranslationFunction } from '../../hooks/translation'
import { theme } from '../../theme'

type ConfirmationModalProps = {
  onClose: (() => void) | undefined
  isOpen: boolean | undefined
  isLoading: boolean
  actionButtonText: string
  actionButtonVariant: string
  onSubmit: () => void
  message: string
  variable?: string
  background?: string
  title?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onClose,
  isOpen,
  isLoading,
  actionButtonText,
  actionButtonVariant,
  onSubmit,
  message,
  variable,
  background,
  title
}) => {
  const translated = useMacsTranslationFunction()
  return (
    <ModalWrap
      title={title || 'Confirmation'}
      onClose={() => {
        onClose && onClose()
      }}
      isOpen={isOpen || false}
    >
      <Flex flexDirection="row" position="relative" p={2}>
        <Text textAlign="center">
          {translated(message)} {variable}
        </Text>
      </Flex>
      <CardFooter flexDirection="row" flex={1}>
        <Flex justifyContent="flex-end" flex={1}>
          <Button mr={2} variant="outline" type="submit" onClick={onClose} text="Cancel" />
          <Button
            isLoading={isLoading}
            variant={actionButtonVariant}
            onClick={onSubmit}
            text={actionButtonText}
            color="white"
            background={background || theme.colors.intent.error}
          />
        </Flex>
      </CardFooter>
    </ModalWrap>
  )
}

export default ConfirmationModal
