import { Maybe } from 'graphql/jsutils/Maybe'
import { Action, createContainer, createHook, createStore } from 'react-sweet-state'
import { PresetsForTableQuery } from '../../generated/graphql'

export enum TableNames {
  UserPermissions = 'userPermissions',
  GoodsReceiptBooked = 'goodsReceipt.booked',
  GoodsRecieptRecieved = 'goodsReceipt.received'
}

export type State = {
  currentPreset: {
    [key in TableNames]: Maybe<string>
  }
  presets: {
    [key in TableNames]: PresetsForTableQuery | undefined
  }
}
export type GlobalTableActions = typeof actions

const initialState: State = {
  currentPreset: {
    userPermissions: null,
    'goodsReceipt.booked': null,
    'goodsReceipt.received': null
  },
  presets: {
    userPermissions: undefined,
    'goodsReceipt.booked': undefined,
    'goodsReceipt.received': undefined
  }
}

const actions = {
  setTableCurrentPreset: (tableName: string, preset: string): Action<State> => ({
    setState,
    getState
  }) => {
    if (Object.values(TableNames).includes(tableName as TableNames)) {
      setState({
        currentPreset: {
          ...getState().currentPreset,
          [tableName]: preset
        }
      })
    } else {
      throw new Error(
        'Incorrect Table name used, If the table pulls its presets fron the Server use a name contained in the TableNames enum'
      )
    }
  },

  setPresets: (tableName: string, preset: PresetsForTableQuery | undefined): Action<State> => ({
    setState,
    getState
  }) => {
    if (Object.values(TableNames).includes(tableName as TableNames)) {
      setState({
        presets: {
          ...getState().presets,
          [tableName]: preset
        }
      })
    } else {
      throw new Error(
        'Incorrect Table name used, If the table pulls its presets fron the Server use a name contained in the TableNames enum'
      )
    }
  }
}

const Store = createStore<State, GlobalTableActions>({
  initialState,
  actions
})
const GlobalTableStore = createContainer(Store)

export const useGlobalTableHook = createHook(Store)
export default GlobalTableStore

const getUserTableCurrentPreset = (state: State) => state.currentPreset.userPermissions
export const useUserTableCurrentPreset = createHook(Store, {
  selector: getUserTableCurrentPreset
})

const getGoodRecieptBookedCurrentPreset = (state: State) =>
  state.currentPreset['goodsReceipt.booked']
export const useGoodRecieptBookedCurrentPreset = createHook(Store, {
  selector: getGoodRecieptBookedCurrentPreset
})

const getGoodRecieptRecievedCurrentPreset = (state: State) =>
  state.currentPreset['goodsReceipt.received']
export const useGoodRecieptRecievedCurrentPreset = createHook(Store, {
  selector: getGoodRecieptRecievedCurrentPreset
})
