import moment from 'moment'

export const convertDecimalToDuration = (decimal: number) => {
  const time = moment.utc(moment.duration(decimal, 'hours').asMilliseconds()).format('HH:mm:ss')
  const splitTime = time.toString().split('')
  const formattedTime = `PT${splitTime[0]}${splitTime[1]}H${splitTime[3]}${splitTime[4]}M${splitTime[6]}${splitTime[7]}S`
  return formattedTime
}

export const convertDurationToDecimal = (duration: string) => {
  const decimal = moment.duration(duration).asHours()
  return decimal
}
