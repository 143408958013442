import { Heading, HeadingProps } from '@chakra-ui/react'
import * as React from 'react'
import { useMacsTranslation } from '../../hooks/translation'

const H4: React.FC<HeadingProps> = ({ children, ...rest }) => {
  const translation = useMacsTranslation({ text: children?.toString() || '' })
  return <Heading {...rest}>{translation}</Heading>
}

export default H4

H4.defaultProps = {
  mb: 2,
  fontSize: '19px',
  fontWeight: 900,
  letterSpacing: '-0.1px'
}
