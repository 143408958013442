import { Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { MoreHorizontal } from 'react-feather'
import { CellProps, HeaderProps, Hooks } from 'react-table'
import { defualtHeaderList, defualtRowList } from '../data'
import { MenuInfoType, useTableHook } from '../Table.Store'

type onClickProps = {
  setShowCount: (hook: MenuInfoType) => void | Promise<void>
  setToggleFooter: (hook?: boolean) => void | Promise<void>
}
export type ContextList = {
  key: string
  title: string
  onClick: ({ setShowCount }: onClickProps) => void
  divider?: boolean
  isHidden?: boolean
}
export type ScreenContextList = {
  header?: ContextList[]
  row?: ContextList[]
}

enum ContextType {
  Header = 'Header',
  Row = 'Row'
}

interface ContextGeneralProps {
  contextType: ContextType
  screenContextList?: ScreenContextList
  useSingleRowSelect?: boolean
}
interface ContextCellProps extends ContextGeneralProps {
  cellProps: CellProps<any>
  headerProps?: never
}
interface ContextHeaderProps extends ContextGeneralProps {
  cellProps?: never
  headerProps: HeaderProps<any>
}

type ContextMenuProps = ContextCellProps | ContextHeaderProps

const CreateMenuItem: React.FC<{
  contextList: ContextList
  headerProps?: HeaderProps<any>
  cellProps?: CellProps<any>
}> = ({ contextList }) => {
  const [, action] = useTableHook()

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          contextList.onClick({
            setShowCount: action.setShowCount,
            setToggleFooter: action.setToggleFooter
          })
        }}
      >
        {contextList.title}
      </MenuItem>
      {contextList.divider && <MenuDivider />}
    </React.Fragment>
  )
}

const CreateMenuList: React.FC<{
  contextType: ContextType
  headerProps?: HeaderProps<any>
  cellProps?: CellProps<any>
  screenContextList?: ScreenContextList
  useSingleRowSelect?: boolean
}> = ({ contextType, screenContextList, cellProps, headerProps, useSingleRowSelect }) => {
  if (contextType === ContextType.Header) {
    return (
      <React.Fragment>
        {defualtHeaderList(headerProps, useSingleRowSelect)
          .filter((defualtHeader) => !defualtHeader.isHidden)
          .map((defualtHeader) => (
            <CreateMenuItem
              key={defualtHeader.key}
              contextList={defualtHeader}
              headerProps={headerProps}
            />
          ))}
        {screenContextList && screenContextList.header && screenContextList.header.length > 0 && (
          <MenuDivider />
        )}
        {screenContextList?.header?.map((header) => (
          <CreateMenuItem key={header.key} contextList={header} headerProps={headerProps} />
        ))}
      </React.Fragment>
    )
  }
  if (contextType === ContextType.Row) {
    return (
      <React.Fragment>
        {defualtRowList.map((defualtRow) => (
          <CreateMenuItem key={defualtRow.key} contextList={defualtRow} cellProps={cellProps} />
        ))}
        {screenContextList && screenContextList.row && screenContextList.row.length > 0 && (
          <MenuDivider />
        )}
        {screenContextList?.row?.map((row) => (
          <CreateMenuItem key={row.key} contextList={row} cellProps={cellProps} />
        ))}
      </React.Fragment>
    )
  }
  return null
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  contextType,
  cellProps,
  headerProps,
  screenContextList,
  useSingleRowSelect
}) => {
  return (
    <Menu isLazy>
      <MenuButton disabled={headerProps?.pageCount === 0} onClick={(e) => e.stopPropagation()}>
        <Icon cursor="pointer" as={MoreHorizontal} w="24px" h="24px" />
      </MenuButton>
      {contextType === ContextType.Header && (
        <MenuList>
          <CreateMenuList
            contextType={contextType}
            screenContextList={screenContextList}
            headerProps={headerProps}
            useSingleRowSelect={useSingleRowSelect}
          />
        </MenuList>
      )}
      {contextType === ContextType.Row && (
        <MenuList>
          <CreateMenuList
            contextType={contextType}
            screenContextList={screenContextList}
            cellProps={cellProps}
          />
        </MenuList>
      )}
    </Menu>
  )
}

/**
 * @name contextMenuHook container
 * @description renders context menu on header and rows
 * @param hooks react table hooks
 * @param useSingleRowSelect flag to render checkbox on header or not
 */
export const contextMenuHook = <D extends {}>(
  hooks: Hooks<D>,
  screenContextList?: ScreenContextList,
  useSingleRowSelect?: boolean
) => {
  hooks.visibleColumns.push((columns) => [
    // Let's make a column for selection
    {
      id: 'contextMenu',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: (props) => (
        <ContextMenu
          contextType={ContextType.Header}
          screenContextList={screenContextList}
          headerProps={props}
          useSingleRowSelect={useSingleRowSelect}
        />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to render a checkbox
      Cell: (props) => (
        <ContextMenu
          contextType={ContextType.Row}
          screenContextList={screenContextList}
          cellProps={props}
        />
      ),
      flex: 0,
      minWidth: 12,
      width: 12
    },
    ...columns
  ])
}
