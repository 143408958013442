import { useMediaQuery } from 'react-responsive'
export const useMinHeightOrHeight = () => {
  const maxWidth = useMediaQuery({ maxWidth: 1224 })
  const maxHeight = useMediaQuery({ maxHeight: 821 })
  const isSmallHeight = useMediaQuery({ maxHeight: 500 })

  if (isSmallHeight) {
    return { minHeight: '100vh' }
  } else if (maxWidth || maxHeight) {
    return { minHeight: '100%' }
  } else {
    return { height: '100%' }
  }
}
