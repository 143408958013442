import * as CSS from 'csstype'
import { MosaicNode } from 'react-mosaic-component'
import { Column, IdType, Row } from 'react-table'
import { ScreenContextList } from './hooks/contextMenuHook'

export type ViewId = 'table' | 'sidebar'
export const TITLE_MAP: Record<ViewId, string> = {
  table: 'table',
  sidebar: 'sidebar'
}

export type HeaderCallbacks<D extends object> = {
  add?: {
    onClick: () => void
    isDisabled?: boolean
    more?: { title: string; onClick: () => void }[]
  }
  custom?: {
    title: string
    onClick: (toggleAllRowsSelected: (value: boolean) => void, selectedFlatRow: Row<D>[]) => void
    isDisabled?: boolean
  }[]
}

export type TableHeaderProps<D extends object> = {
  height: CSS.Property.Height
  margin: CSS.Property.MarginLeft
  callbacks?: HeaderCallbacks<D>
}

export type SidePanelProps<D extends object> = {
  selectedRowIds: object
  selectedFlatRows: Array<Row<D>>
  fieldsActive?: boolean
  setDisableRowSelect: (disabled?: boolean | undefined) => void | Promise<void>
  setShowWarning: (warning?: boolean | undefined) => void | Promise<void>
}

export type TableMainProps<D extends object = {}> = {
  data: any
  columns: Column<D>[]
  name: string
  loadNextPage?: (startIndex: number, endIndex: number) => any
  hasMore?: boolean
  isNextPageLoading?: boolean
  totalCount?: number
  useSingleRowSelect?: boolean
  onRowSelect?: (rows: Row<D>[]) => void
  onRowDoubleClick?: (row: Row<D>) => void
  autoResetSelectedRows?: boolean
  autoResetPage?: boolean
  defaultSelectedRowIds?: Record<IdType<D>, boolean>
  screenContextList?: ScreenContextList
  SidePanel?: React.FC<SidePanelProps<D>>
  tablePanelWidth?: number
  fieldsActive?: boolean
  onRelease?: (node: MosaicNode<ViewId> | null) => void
  applyId: string
  tableHeaderProps?: TableHeaderProps<D>
  Filter?: React.FC<{ fieldsActive?: boolean }>
  itemSize?: number
  isDisplayRow?: boolean
  isServerColumns?: boolean
}
