import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { Button } from '../..'
import { theme } from '../../../theme'
import { P } from '../../Typography'
import { MenuInfoType, useTableHook } from '../Table.Store'

type ContextMenuInfoProps = {
  cardRef: React.RefObject<HTMLElement> | null
}

/**
 * @render react
 * @name ContextMenuInfo container
 * @description Modal that shows over the table
 */
const ContextMenuInfo: React.FC<ContextMenuInfoProps> = ({ cardRef }) => {
  const [state, action] = useTableHook()
  return (
    <Flex
      width={cardRef ? cardRef.current?.clientWidth : '100%'}
      height={cardRef ? cardRef.current?.clientHeight : '100%'}
      position="absolute"
      zIndex="2"
      background="background.modal"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        background="white"
        flexDirection="column"
        alignItems="center"
        position="absolute"
        zIndex="2"
        padding={2}
        border={theme.colors.border.sidebarTableMessages}
        borderRadius="8px"
      >
        <Flex>
          {state.showCountModal === MenuInfoType.Column && (
            <>
              <P>Column count</P>
              <Text>&nbsp;:&nbsp;</Text>
              <Text>{state.tableHook?.visibleColumns.length}</Text>
            </>
          )}
          {state.showCountModal === MenuInfoType.Row && (
            <>
              <P>Row count</P>
              <Text>&nbsp;:&nbsp;</Text>
              <Text>{state.tableHook?.rowCount}</Text>
            </>
          )}
        </Flex>
        <Flex mt={2}>
          <Button
            text="Ok"
            color="white"
            background="primary.base"
            variant="outline"
            onClick={() => action.setShowCount(MenuInfoType.None)}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ContextMenuInfo
