import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React from 'react'
import { Button, Col, ConnectedSelect, Container, Row } from '../../../components'
import { H5, P } from '../../../components/Typography'

const ImportMenu: React.FC = () => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Flex flex={1} flexDirection="column" textAlign="left">
          <H5>Import Menu</H5>
          <Flex>
            <P fontSize="12px">
              Importing a menu enables you to use another user’s menu labels & display for USERNAME
            </P>
          </Flex>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Container fluid>
            <Row>
              <Col xs={4}>
                <Field
                  name="importFrom"
                  label="Import menu from"
                  options={[]}
                  component={ConnectedSelect}
                />
              </Col>
              <Flex flex={1} justifyContent="flex-end">
                <Button text="Import" bg="primary.base" color="text.onPrimary" mt="25px" />
              </Flex>
            </Row>
          </Container>
        </Formik>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default ImportMenu
