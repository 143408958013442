import { Maybe } from 'graphql/jsutils/Maybe'
/**
 * @name convertNullToUndefined container
 * @description converts a null value to undefined or returns the data if present
 * @param data any object that might return null
 */
export const convertNullToUndefined = <T extends object>(data: Maybe<T>) => {
  if (data) {
    return data
  }
  return undefined
}
