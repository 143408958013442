import { lazy } from 'react'
import { RouteProps } from 'react-router'
import { TranslatedBreadcrumb } from '.'
import ComingSoon from '../containers/InfoPages/ComingSoon/index'
// Public
const Authenticate = lazy(() => import('../containers/Authenticate'))
// Private
const Home = lazy(() => import('../containers/Home'))
const StorageTypeSetup = lazy(() => import('../containers/StorageTypeSetup'))
const UserSetup = lazy(() => import('../containers/UserSetup'))
const WarehouseSetup = lazy(() => import('../containers/WarehouseSetup'))
const OrderEntry = lazy(() => import('../containers/OrderEntry'))
const ZoneSetup = lazy(() => import('../containers/ZoneSetup'))
const HandlingTypeSetup = lazy(() => import('../containers/HandlingTypeSetup'))
const UDCSetup = lazy(() => import('../containers/UDCSetup'))
const SupplierSetup = lazy(() => import('../containers/SupplierSetup'))
const LocationSetup = lazy(() => import('../containers/LocationSetup'))
const StockOwnerSetup = lazy(() => import('../containers/StockOwnerSetup'))
const ProductSetup = lazy(() => import('../containers/ProductSetup'))
const MHESetup = lazy(() => import('../containers/MHE'))
const DestinationSetup = lazy(() => import('../containers/DestinationSetup'))
const BudgetHolder = lazy(() => import('../containers/BudgetHolder/index'))
const StockEnquiry = lazy(() => import('../containers/StockEnquiry'))
const CarrierSetup = lazy(() => import('../containers/CarrierSetup'))
const AuditLog = lazy(() => import('../containers/AuditLog'))
const QueryBuilder = lazy(() => import('../containers/QueryBuilder'))
const GoodReceipt = lazy(() => import('../containers/GoodReceipt'))
const CreateBooking = lazy(() => import('../containers/CreateBooking'))
const PurchaseOrder = lazy(() => import('../containers/PurchaseOrder'))
const PermissionDenied = lazy(() => import('../containers/InfoPages/PermissionDenied'))
const FileUploadExample = lazy(() => import('../containers/Examples/fileUpload'))
const FileUploadExampleRest = lazy(() => import('../containers/Examples/fileUploadRest'))
const FieldMappingExample = lazy(() => import('../containers/Examples/fieldMapping'))

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: any
  component: any
  title: string
  name: string
}

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/',
    breadcrumb: TranslatedBreadcrumb,
    component: Home,
    title: 'Home',
    name: 'home'
  },
  {
    exact: true,
    path: '/storage-type-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: StorageTypeSetup,
    title: 'Storage Type Setup',
    name: 'storagetypesetup'
  },
  {
    exact: true,
    path: '/user-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: UserSetup,
    title: 'User Setup',
    name: 'usersetup'
  },
  {
    exact: true,
    path: '/warehouse-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: WarehouseSetup,
    title: 'Warehouse Setup',
    name: 'warehousesetup'
  },
  {
    exact: true,
    path: '/order-entry',
    breadcrumb: TranslatedBreadcrumb,
    component: OrderEntry,
    title: 'Order Entry',
    name: 'orderentry'
  },
  {
    exact: true,
    path: '/zone-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: ZoneSetup,
    title: 'Zone Setup',
    name: 'zonesetup'
  },
  {
    exact: true,
    path: '/handling-type-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: HandlingTypeSetup,
    title: 'Handling Type Setup',
    name: 'handlingtypesetup'
  },
  {
    exact: true,
    path: '/udc-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: UDCSetup,
    title: 'Code Setup',
    name: 'udcsetup'
  },
  {
    exact: true,
    path: '/supplier-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: SupplierSetup,
    title: 'Supplier Setup',
    name: 'suppliersetup'
  },
  {
    exact: true,
    path: '/location-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: LocationSetup,
    title: 'Location Setup',
    name: 'locationsetup'
  },
  {
    exact: true,
    path: '/stock-owner-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: StockOwnerSetup,
    title: 'Stock Owner Setup',
    name: 'stockownersetup'
  },
  {
    exact: true,
    path: '/product-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: ProductSetup,
    title: 'Product Setup',
    name: 'productsetup'
  },
  {
    exact: true,
    path: '/mhe-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: MHESetup,
    title: 'MHE Setup',
    name: 'mhesetup'
  },
  {
    exact: true,
    path: '/destination-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: DestinationSetup,
    title: 'Destination Setup',
    name: 'destinationsetup'
  },
  {
    exact: true,
    path: '/budget-holder',
    breadcrumb: TranslatedBreadcrumb,
    component: BudgetHolder,
    title: 'Budget Holder',
    name: 'budgetholder'
  },
  {
    exact: true,
    path: '/stock-enquiry',
    breadcrumb: TranslatedBreadcrumb,
    component: StockEnquiry,
    title: 'Stock Enquiry',
    name: 'stockenquiry'
  },
  {
    exact: true,
    path: '/carrier-setup',
    breadcrumb: TranslatedBreadcrumb,
    component: CarrierSetup,
    title: 'Carrier Setup',
    name: 'carriersetup'
  },
  {
    exact: true,
    path: '/audit-log',
    breadcrumb: TranslatedBreadcrumb,
    component: AuditLog,
    title: 'Audit Log',
    name: 'auditlog'
  },
  {
    exact: true,
    path: '/good-receipt',
    breadcrumb: TranslatedBreadcrumb,
    component: GoodReceipt,
    title: 'Good Receipt',
    name: 'goodreceipt'
  },
  {
    exact: true,
    path: '/create-booking',
    breadcrumb: TranslatedBreadcrumb,
    component: CreateBooking,
    title: 'Create Booking',
    name: 'createbooking'
  },
  {
    exact: true,
    path: '/purchase-order',
    breadcrumb: TranslatedBreadcrumb,
    component: PurchaseOrder,
    title: 'Purchase Order',
    name: 'purchaseorder'
  },
  {
    exact: true,
    path: '/coming-soon',
    breadcrumb: TranslatedBreadcrumb,
    component: ComingSoon,
    title: 'Coming Soon',
    name: 'comingsoon'
  },
  {
    exact: true,
    path: '/permission-denied',
    breadcrumb: TranslatedBreadcrumb,
    component: PermissionDenied,
    title: 'Permission Denied',
    name: 'permissiondenied'
  },
  {
    exact: true,
    path: '/query-builder-demo',
    breadcrumb: TranslatedBreadcrumb,
    component: QueryBuilder,
    title: 'Query Builder Demo',
    name: 'qbdemo'
  },
  {
    exact: true,
    path: '/file-upload-example',
    breadcrumb: TranslatedBreadcrumb,
    component: FileUploadExample,
    title: 'File Upload Example',
    name: 'fileUploadExample'
  },
  {
    exact: true,
    path: '/file-upload-example-rest',
    breadcrumb: TranslatedBreadcrumb,
    component: FileUploadExampleRest,
    title: 'File Upload Example Rest',
    name: 'fileUploadExample Rest'
  },
  {
    exact: true,
    path: '/field-mapping-example',
    breadcrumb: TranslatedBreadcrumb,
    component: FieldMappingExample,
    title: 'Field Mapping Example',
    name: 'filedMappingExample'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    path: '/authenticate',
    component: Authenticate,
    title: 'Authenticate'
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
