import { Accordion, ButtonGroup, Flex, IconButton, Spinner } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Plus } from 'react-feather'
import { ReactSortable } from 'react-sortablejs'
import { Col, Container, Row } from '../../../../components'
import { P } from '../../../../components/Typography'
import { Screen } from '../../../../generated/graphql'
import { CancelButton, ResetButton, SaveButton } from './actionButtons'
import MenuGroup from './group'
import { setParentMenus, useFetchMenus } from './Helpers/general'
import MenuItem from './item'
import './menu.css'
import SearchMenu from './search'

export type TRemoveMenu = { id: number }[]

//General Sort Objects shared between group and items
export const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.2,
  ghostClass: 'ghost',
  handle: '.handle'
}
//Menu strucute
export type MenuList = {
  screen: Screen | null
  parent?: MenuList[] | null
  children?: MenuList[] | null
  title: string | null
  id: number
  userId: number | null
  parentId: number | null
  order: number | null
  screenId: number | null
  enabled?: boolean
  depth?: number
  isNew?: boolean
  isUpdated?: boolean
  isDeleted?: boolean
  toggleOn?: boolean
  defaultOrder?: number
  defaultParentId?: number
  defaultOnly?: boolean
  defaultGroupOnly?: boolean
  defaultGroupId?: string
  accordionIndex?: number
  removeOnReset?: boolean
  removeFromUpdate?: boolean
  index?: number[]
}

//Properties both groups and items receive
export type TMenu = {
  menu: MenuList
  menuIndex: number[]
  setMenus: React.Dispatch<React.SetStateAction<MenuList[]>>
  removeMenus: TRemoveMenu
  setRemoveMenus: React.Dispatch<React.SetStateAction<TRemoveMenu>>
  accordionRef: React.RefObject<HTMLDivElement>
  defaultUpdated?: {
    screen: MenuList[]
    group: MenuList[]
  }
  isManager?: boolean
}

//Renders either a ground or item based on if the screen has a screenId or not
export const MenuRender: React.FC<TMenu> = React.memo(
  ({
    menu,
    menuIndex,
    setMenus,
    removeMenus,
    setRemoveMenus,
    accordionRef,
    defaultUpdated,
    isManager
  }) => {
    if (!menu) return null
    if (!menu.screenId) {
      return (
        <MenuGroup
          menu={menu}
          menuIndex={menuIndex}
          setMenus={setMenus}
          removeMenus={removeMenus}
          setRemoveMenus={setRemoveMenus}
          accordionRef={accordionRef}
          defaultUpdated={defaultUpdated}
          isManager={isManager}
        />
      )
    } else {
      return (
        <MenuItem
          menu={menu}
          menuIndex={menuIndex}
          setMenus={setMenus}
          removeMenus={removeMenus}
          setRemoveMenus={setRemoveMenus}
          accordionRef={accordionRef}
          isManager={isManager}
        />
      )
    }
  }
)

/**
 * @render react
 * @name Menu container
 * @description Main Menu component
 */
const Menu: React.FC<{
  onModalClose?: () => void
  isManager?: boolean
}> = ({ onModalClose, isManager }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [removeMenus, setRemoveMenus] = useState<TRemoveMenu>([])
  const {
    menus,
    setMenus,
    defaultMenus,
    menusLoading,
    parentIds,
    defaultUpdated,
    setDefaultUpdated
  } = useFetchMenus(isManager)
  return (
    <Container fluid padding={2}>
      <Flex ref={ref} />
      {menusLoading && (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {!menusLoading && (
        <React.Fragment>
          {(defaultUpdated.screen.length > 0 || defaultUpdated.group.length > 0) && (
            <P color="black">Addition menus have been added. Please save before making changes</P>
          )}
          <SearchMenu menus={menus} isManager={isManager} />
          <Flex height="450px" overflow="scroll" mb={2} flex={1}>
            <Flex>
              <IconButton
                isDisabled={defaultUpdated.screen.length > 0 || defaultUpdated.group.length > 0}
                aria-label="Add"
                bg="primary.base"
                color="white"
                icon={<Plus />}
                height="30px"
                onClick={() => {
                  const newCategory = {
                    id: Math.ceil(Date.now() + Math.random()),
                    parentId: null,
                    screenId: null,
                    userId: null,
                    title: `New Category ${menus.length}`,
                    order: -1,
                    screen: null,
                    parent: null,
                    isNew: true,
                    enabled: true,
                    removeFromUpdate: true,
                    defaultGroupId: `New Category ${menus.length} - ${Math.ceil(
                      Date.now() + Math.random()
                    )}`,
                    children: []
                  }
                  setMenus([newCategory, ...menus])
                }}
              />
            </Flex>
            <Col xs>
              <Accordion allowMultiple>
                <ReactSortable
                  sort={defaultUpdated.screen.length === 0 && defaultUpdated.group.length === 0}
                  list={menus}
                  setList={(currentList, _, store) => {
                    if (store.dragging) {
                      setMenus(() => setParentMenus(currentList))
                    }
                  }}
                  {...sortableOptions}
                  group="shared"
                >
                  {menus.map((menu, menuIndex) => (
                    <Accordion allowMultiple key={menu.id}>
                      <MenuRender
                        menu={menu}
                        menuIndex={[menuIndex]}
                        setMenus={setMenus}
                        removeMenus={removeMenus}
                        setRemoveMenus={setRemoveMenus}
                        accordionRef={ref}
                        defaultUpdated={defaultUpdated}
                        isManager={isManager}
                      />
                    </Accordion>
                  ))}
                </ReactSortable>
              </Accordion>
            </Col>
          </Flex>
          <Row end="xs">
            <Col xs>
              <ButtonGroup>
                <CancelButton
                  menus={menus}
                  removeMenus={removeMenus}
                  parentIds={parentIds}
                  onModalClose={onModalClose}
                />
                <ResetButton
                  menus={menus}
                  setMenus={setMenus}
                  removeMenus={removeMenus}
                  setRemoveMenus={setRemoveMenus}
                  parentIds={parentIds}
                />
                <SaveButton
                  defaultMenus={defaultMenus}
                  menus={menus}
                  setMenus={setMenus}
                  removeMenus={removeMenus}
                  setRemoveMenus={setRemoveMenus}
                  parentIds={parentIds}
                  onModalClose={onModalClose}
                  setDefaultUpdated={setDefaultUpdated}
                  isManager={isManager}
                />
              </ButtonGroup>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Container>
  )
}

export default Menu
