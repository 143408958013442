import { Flex } from '@chakra-ui/react'
import React from 'react'
import { HeaderGroup } from 'react-table'
import { theme } from '../../../theme'
import { useTableHook } from '../Table.Store'
import { TableCell, TableHead } from './styles'

/**
 * @render react
 * @name Footers container
 * @description Table Column Footers
 * @param footerGroups table footers
 */
const Footers = <D extends {}>() => {
  const [state] = useTableHook()

  return (
    <TableHead
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 0
      }}
    >
      {state.toggleFooter &&
        state.tableHook?.footerGroups.map((footerGroup: HeaderGroup<D>) => (
          <Flex
            //@ts-expect-error - 'key' is specified more than once, so this usage will be overwritten
            key={footerGroup.id}
            flex={1}
            flexDirection="row"
            {...footerGroup.getFooterGroupProps()}
          >
            {footerGroup.headers.map((column) => {
              return (
                <TableCell
                  {...column.getHeaderProps()}
                  key={column.id}
                  px={1}
                  py={2}
                  bg={theme.colors.background.muted}
                  justifyContent="space-between"
                  flex={column.flex}
                  width={column.width}
                  maxWidth={column.maxWidth}
                  minWidth={column.minWidth}
                >
                  {/* Render Footer */}
                  {column.isNumber && (
                    <Flex fontWeight="bold" justifyContent="flex-end" width="100%">
                      {state.tableHook?.rows.reduce((sum: any, row: any) => {
                        return isNaN(row.values[column.id] + sum) ? 0 : row.values[column.id] + sum
                      }, 0)}
                    </Flex>
                  )}
                  {/* Render Footer */}
                </TableCell>
              )
            })}
          </Flex>
        ))}
    </TableHead>
  )
}

export default Footers
