import { Spinner } from '@chakra-ui/react'
import { Field } from 'formik'
import React, { useEffect, useState } from 'react'
import { Cell } from 'react-table'
import { AutoSizer } from 'react-virtualized'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { theme } from '../../../theme'
import { useTableHook } from '../Table.Store'
import { TableCell, TableRow } from './styles'

/**
 * @render react
 * @name Rows container
 * @description table rows
 */
const Rows = <D extends {}>({ headerRef }: { headerRef: React.RefObject<HTMLDivElement> }) => {
  const [{ tableHook, warning }, { setShowWarning, setIndex }] = useTableHook()
  const [headerWidth, setHeaderWidth] = useState<number>(0)
  //Memo Cells and Rows - keeps them from rerendering on every change
  const MemoRow = React.useMemo(() => TableRow, [])
  const MemoCell = React.useMemo(() => TableCell, [])

  //Updates the tables witdth based on the headers width
  useEffect(() => {
    if (headerRef.current) {
      if (headerRef.current.clientWidth > 0) {
        setHeaderWidth(headerRef.current.clientWidth)
      }
    }
  }, [headerRef.current?.clientWidth, tableHook?.visibleColumns])

  if (!tableHook) return null

  //Renders the actual row component
  const RenderRow = ({ index, style }: any) => {
    if (!tableHook) return null
    const rowRef = React.useRef(null)
    let timeout: number | null = null

    const onClickAction = (cell: Cell<D>) => {
      if (cell.column.id === 'contextMenu') return null
      if (!warning.disableRowSelect) {
        tableHook.useSingleRowSelect && tableHook.toggleAllRowsSelected(false)
        row.toggleRowSelected()
        setShowWarning(false)
      } else {
        setShowWarning(true)
        setIndex(index)
      }
    }

    const onClick = (cell: Cell<D>) => {
      if (tableHook.onRowDoubleClick) {
        if (timeout === null) {
          timeout = window.setTimeout(() => {
            timeout = null
            onClickAction(cell)
          }, 300)
        }
      } else {
        onClickAction(cell)
      }
    }

    const onDoubleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: Cell<D>) => {
      if (cell.column.id === 'contextMenu') return null
      if (!warning.disableRowSelect) {
        e.preventDefault()
        timeout && window.clearTimeout(timeout)
        timeout = null
        tableHook.onRowDoubleClick && tableHook.onRowDoubleClick(row)
      }
    }

    const row = tableHook.rows[index]
    if (!tableHook.isItemLoaded(index)) {
      //Loading State
      return (
        <TableRow style={style} justifyContent="center" ref={rowRef}>
          <TableCell justifyContent="center">
            <Spinner size="sm" />
          </TableCell>
        </TableRow>
      )
    } else if (!row) {
      //A hidden extra row
      return null
    } else {
      //Row with data
      tableHook.prepareRow(row)
      return (
        <MemoRow
          id={`row-${tableHook.name}-${row.id}`}
          ref={rowRef}
          style={{
            background: row.isSelected ? theme.colors.primary.muted : 'transparent'
          }}
          {...row.getRowProps({
            style: {
              ...style,
              background: row.isSelected ? theme.colors.primary.muted : 'transparent'
            }
          })}
          className="tr"
        >
          {row.cells.map((cell: Cell<D>) => {
            return (
              <MemoCell
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                //@ts-expect-error - 'key' is specified more than once,
                key={cell.row.index}
                justifyContent={
                  cell.column.id === 'contextMenu'
                    ? 'center'
                    : cell.column.isNumber
                    ? 'flex-end'
                    : 'flex-start'
                }
                padding={cell.column.id === 'contextMenu' ? 0 : 1}
                flex={cell.column.flex}
                maxWidth={cell.column.maxWidth}
                minWidth={cell.column.minWidth}
                width={cell.column.width}
                bg={cell.column.backgroundColor}
                onClick={() => {
                  if (!cell.column.editable) {
                    onClick(cell)
                  }
                }}
                onDoubleClick={(e) => onDoubleClick(e, cell)}
                {...cell.getCellProps()}
              >
                {!cell.column.editable ? (
                  cell.render('Cell')
                ) : (
                  <Field
                    name={`${cell.column.name?.parent}[${index}].${cell.column.name?.child}`}
                    updateOnBlur
                    component={cell.column.component}
                  />
                )}
              </MemoCell>
            )
          })}
        </MemoRow>
      )
    }
  }
  if (tableHook.isDisplayRow) {
    const row = tableHook.rows[0]
    tableHook.prepareRow(row)
    return (
      <TableRow height="100%" {...row.getRowProps()} className="tr">
        {row.cells.map((cell: Cell<D>) => {
          return (
            <TableCell
              //@ts-expect-error - 'key' is specified more than once,
              key={cell.row.index}
              padding={1}
              justifyContent="flex-start"
              flex={cell.column.flex}
              maxWidth={cell.column.maxWidth}
              minWidth={cell.column.minWidth}
              width={cell.column.width}
              bg={cell.column.backgroundColor}
              id={cell.column.backgroundColor ? 'active-row' : ''}
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </TableCell>
          )
        })}
      </TableRow>
    )
  }
  return (
    <React.Fragment>
      {/* Empty data set */}
      {tableHook.itemCount === 0 && (
        <TableRow style={{ justifyContent: 'center', height: '100%' }}>
          <TableCell justifyContent="center">
            {!tableHook.isNextPageLoading && !tableHook.hasMore ? 'No Data' : <Spinner size="sm" />}
          </TableCell>
        </TableRow>
      )}
      {/*Data set greater than zero*/}
      {tableHook.itemCount > 0 && (
        <div style={{ height: '100%', width: '100%' }}>
          <AutoSizer>
            {({ width, height }) => {
              return (
                <InfiniteLoader
                  isItemLoaded={tableHook.isItemLoaded}
                  itemCount={tableHook.itemCount}
                  loadMoreItems={tableHook.loadMoreItems}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      ref={(list) => {
                        if (ref) {
                          //@ts-ignore - This expression is not callable
                          ref(list)
                        }
                      }}
                      className="List"
                      style={{ overflowX: 'hidden' }}
                      height={height}
                      itemCount={tableHook.itemCount}
                      itemSize={tableHook.itemSize || 35}
                      onItemsRendered={onItemsRendered}
                      width={headerWidth || width}
                    >
                      {RenderRow}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              )
            }}
          </AutoSizer>
        </div>
      )}
    </React.Fragment>
  )
}

export default Rows
