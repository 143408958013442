import { MenuList, TRemoveMenu } from '..'
import { flattenMenus, flattenParents } from './flattenMenus'

/**
 * @name convertGroups
 * @description recursevly mutate groups adding additional values to them
 * @param menu MenuList Array or MenuList Object
 * @param enabled boolean
 * @returns MenuList Array
 */
export const convertGroups = (menu: MenuList | MenuList[], enabled?: boolean): any => {
  if (Array.isArray(menu)) {
    return menu.flatMap((menuItem) => {
      return {
        ...menuItem,
        enabled: enabled,
        isUpdated: true,
        children: convertGroups(menuItem.children || [], enabled)
      }
    })
  } else {
    return {
      ...menu,
      enabled: enabled,
      isUpdated: true,
      children: convertGroups(menu.children || [], enabled)
    }
  }
}

/**
 * @name showAdd
 * @description gets the depth of the object within the array
 * @param depth depth of the menu
 * @returns boolean
 */
export const showAdd = (depth?: number) => {
  if (depth === undefined) return true
  if (depth < 3) return true
  return false
}

/**
 * @name showDelete
 * @description looks for screen inside groups and returns if the delete button should be shown or not
 * @param children groups children
 * @returns boolean
 */
export const showDelete = (children?: MenuList[] | null, removeOnReset?: boolean) => {
  const hasScreen = children?.filter((child) => child.screenId !== null)
  const isParent = children?.filter((child) => child.screenId === null)
  if (removeOnReset === false) {
    return false
  }
  if (hasScreen && hasScreen.length > 0) {
    return false
  }
  if (isParent && isParent.length > 0) {
    const hasScreen2 = flattenMenus(isParent)?.filter((child) => child.screenId !== null)
    return hasScreen2 && hasScreen2.length > 0 ? false : true
  }

  return true
}

/**
 * @name toggleGroup
 * @description on switch toggle, toggle all children into the parents state
 * @param sourceList MenuList Array
 * @param menuIndex number Array
 * @returns MenuList Array
 */
export const toggleGroup = (sourceList: MenuList[], menuIndex: number[]) => {
  const tempList = [...sourceList]
  const _blockIndex = [...menuIndex]
  const lastIndex = _blockIndex.pop() || 0
  const lastArr = _blockIndex.reduce((arr, i) => arr[i].children || [], tempList)
  if (!lastArr[lastIndex].defaultOnly) {
    lastArr[lastIndex].isUpdated = true
  }
  lastArr[lastIndex].enabled = !lastArr[lastIndex].enabled
  lastArr[lastIndex] = convertGroups(lastArr[lastIndex], lastArr[lastIndex].enabled)
  return tempList
}

/**
 * @name deleteGroup
 * @description removes group from the list as well as flags the id for removal on save
 * @param sourceList MenuList Array
 * @param menuIndex number Array
 * @param setRemoveMenus set state event
 * @param removeMenus state value
 * @returns MenuList Array
 */
export const deleteGroup = (
  sourceList: MenuList[],
  menuIndex: number[],
  setRemoveMenus: React.Dispatch<React.SetStateAction<TRemoveMenu>>,
  removeMenus: TRemoveMenu
) => {
  const tempList = [...sourceList]
  const _blockIndex = [...menuIndex]
  const lastIndex = _blockIndex.pop() || 0
  const lastArr = _blockIndex.reduce((arr, i) => arr[i].children || [], tempList)
  if (!lastArr[lastIndex].isNew && !lastArr[lastIndex].removeFromUpdate) {
    setRemoveMenus([...removeMenus, { id: lastArr[lastIndex].id }])
  }
  const index = lastArr.indexOf(lastArr[lastIndex])
  if (index > -1) {
    lastArr.splice(index, 1)
  }

  return tempList
}

/**
 * @name addNewGroup
 * @description adds a new group as a child of the current group
 * @param sourceList MenuList Array
 * @param menuIndex number Array
 * @returns MenuList Array
 */
export const addNewGroup = (sourceList: MenuList[], menuIndex: number[]) => {
  const tempList = [...sourceList]
  const _blockIndex = [...menuIndex]
  const lastIndex = _blockIndex.pop() || 0
  const lastArr = _blockIndex.reduce((arr, i) => arr[i].children || [], tempList)
  const children = lastArr[lastIndex].children || []

  const flatParents = flattenParents([lastArr[lastIndex]]).map((m) => m.isNew)
  const newChildren = [
    {
      //value of id is not important it just needs to be unique
      id: Math.ceil(Date.now() + Math.random()),
      parentId: lastArr[lastIndex].id,
      screenId: null,
      userId: null,
      title: `${lastArr[lastIndex].title} ${children.length + 1}`,
      order: -1,
      screen: null,
      parent: [lastArr[lastIndex]],
      depth: (lastArr[lastIndex].depth || 0) + 1,
      isNew: !flatParents.includes(true),
      removeFromUpdate: true,
      enabled: true,
      defaultGroupId: `New Category ${lastArr.length} - ${Math.ceil(Date.now() + Math.random())}`,
      children: []
    },
    ...children
  ]
  lastArr[lastIndex].children = newChildren
  return tempList
}

/**
 * @name setGroupMenus
 * @description on drag functionality setting the groups positions when draged and dropped
 * @param sourceList MenuList Array
 * @param currentList MenuList Array
 * @param menuIndex number Array
 * @returns MenuList Array
 */

export const setGroupMenus = (
  sourceList: MenuList[],
  currentList: MenuList[],
  menuIndex: number[]
) => {
  const tempList = [...sourceList]
  const _blockIndex = [...menuIndex]
  const lastIndex = _blockIndex.pop() || 0

  const lastArr = _blockIndex.reduce((arr, i) => {
    return arr[i].children || []
  }, tempList)

  const updatedCurrentList = currentList.map((list, index) => {
    if (lastArr[lastIndex] === undefined) return list
    if (list.parentId === lastArr[lastIndex].id || lastArr[lastIndex].isNew) {
      return {
        ...list,
        order: index,
        parentId: lastArr[lastIndex].id,
        isUpdated: !lastArr[lastIndex].isNew
      }
    }
    return {
      ...list,
      parentId: lastArr[lastIndex].id,
      order: index,
      isUpdated: true
    }
  })

  if (lastArr[lastIndex]) {
    lastArr[lastIndex].children = updatedCurrentList
  }

  return tempList
}
