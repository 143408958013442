import { ButtonGroup, Flex, IconButton } from '@chakra-ui/react'
import React from 'react'
import { Plus } from 'react-feather'
import { Button, Col, Container, DropdownMenu, Row } from '../..'
import { useTableHook } from '../Table.Store'

/**
 * @render react
 * @name TableHeader container
 * @description Renders a header for all sidebars - this is rended in the main sidebar component
 * @param height main height of the component
 * @param margin margin positioning of the component
 * @param callbacks functions of the type of buttons
 * @param fieldsActive active state of the form
 * @param Filter filter component
 */

const TableHeader: React.FC<{ Filter?: React.FC<{ fieldsActive?: boolean }> }> = ({ Filter }) => {
  const [state] = useTableHook()
  const callbacks = state.tableHook?.tableHeaderProps?.callbacks
  return (
    <Container fluid bg="advancedTable.sidePanel">
      <Row>
        {Filter && (
          <Col xs>
            <Filter fieldsActive={state.tableHook?.fieldsActive || false} />
          </Col>
        )}
        <Col xs>
          {callbacks && (
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              height={state.tableHook?.tableHeaderProps?.height}
              margin={state.tableHook?.tableHeaderProps?.margin}
            >
              {/* Custom Callbacks are any unique functions that need to be added */}
              {callbacks.custom &&
                callbacks.custom.map((custom, index) => (
                  <Button
                    key={index}
                    text={custom.title}
                    isDisabled={
                      state.warning.disableRowSelect ||
                      (custom.isDisabled &&
                        state.tableHook?.selectedFlatRows &&
                        state.tableHook?.selectedFlatRows.length === 0) ||
                      custom.isDisabled ||
                      (state.tableHook?.selectedFlatRows &&
                        state.tableHook?.selectedFlatRows.length === 0)
                    }
                    mx={1}
                    onClick={() => {
                      if (state.tableHook) {
                        custom.onClick &&
                          custom.onClick(
                            state.tableHook.toggleAllRowsSelected,
                            state.tableHook.selectedFlatRows
                          )
                      }
                    }}
                  />
                ))}

              {/*Add Callback is the main function on each table */}
              {callbacks.add && (
                <ButtonGroup isAttached variant="outline" mx={1}>
                  <IconButton
                    icon={<Plus />}
                    aria-label="Add New"
                    bg="primary.base"
                    color="white"
                    marginLeft="auto"
                    data-testid="createNew"
                    isDisabled={state.warning.disableRowSelect || callbacks.add.isDisabled}
                    onClick={() => {
                      if (callbacks.add) {
                        state.tableHook?.toggleAllRowsSelected(false)
                        callbacks.add.onClick()
                      }
                    }}
                  />
                  {/*Add More Callback will render the dropdown with any extra fuctions tied to it*/}
                  {callbacks.add.more && (
                    <DropdownMenu
                      bg="primary.base"
                      color="solid.white"
                      isDisabled={state.warning.disableRowSelect || callbacks.add.isDisabled}
                      menuItems={callbacks.add.more}
                      paddingX={0}
                    />
                  )}
                </ButtonGroup>
              )}
            </Flex>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default TableHeader
