import React from 'react'
import InfoPage from '..'

/**
 * @render react
 * @name PageNotFound container
 * @description Page Not Found Info Page
 */
const PageNotFound: React.FC = () => {
  return (
    <InfoPage
      header="Page Not Found (404)"
      description="This page does not exist, please check you have the right url."
    />
  )
}

export default PageNotFound
