import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex
} from '@chakra-ui/react'
import React from 'react'
import { H5, P } from '../../../components/Typography'
import { theme } from '../../../theme'
import Menu from './Menu'

const DisplayMenu: React.FC<{
  onModalClose?: () => void
  isManager?: boolean
}> = ({ onModalClose, isManager }) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Flex flex={1} flexDirection="column" textAlign="left">
          <H5>Menu Display</H5>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Flex
          flex={1}
          background={theme.colors.menuManager.displayHeader}
          height="30px"
          alignItems="center"
          paddingX={1}
          marginBottom={1}
        >
          <P color="solid.white" padding={1}>
            Desktop Menu
          </P>
        </Flex>
        <Menu onModalClose={onModalClose} isManager={isManager} />
      </AccordionPanel>
    </AccordionItem>
  )
}

export default DisplayMenu
