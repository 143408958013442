import { Box } from '@chakra-ui/react'
import React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { HeaderGroup } from 'react-table'
import { theme } from '../../../theme'
import { TableCell } from './styles'

/**
 * @render react
 * @name Headers container
 * @description Table Column Headers
 * @param headerGroup table headers
 */

const Headers = <D extends {}>({ headerGroup }: { headerGroup: HeaderGroup<D> }) => {
  return (
    <React.Fragment>
      {headerGroup.headers.map((column) => (
        //Render Cell
        <TableCell
          {...column.getHeaderProps()}
          {...column.getSortByToggleProps()}
          key={column.id}
          px={1}
          py={2}
          position={column.position || 'unset'}
          right={0}
          bg={column.backgroundColor || theme.colors.background.muted}
          justifyContent={
            column.id === 'contextMenu'
              ? 'center'
              : //@ts-ignore - isNumber is not defined on column, but custom props are pssed doen
              column.isNumber
              ? 'flex-end'
              : 'space-between'
          }
          flex={column.flex}
          width={column.width}
          maxWidth={column.maxWidth}
          minWidth={column.minWidth}
        >
          {/* Render Header */}
          <Box fontWeight="bold">{column.render('Header')}</Box>
          {/* Render Header */}
          {/* Sorting indicator */}
          {column.isSorted ? (
            column.isSortedDesc ? (
              <ChevronDown size={20} />
            ) : (
              <ChevronUp size={20} />
            )
          ) : (
            ''
          )}
          {/* Sorting indicator */}
        </TableCell>
        //Render Cell
      ))}
    </React.Fragment>
  )
}

export default Headers
