import _ from 'lodash'
import { arrayToTree } from 'performant-array-to-tree'
import { MenuList, TRemoveMenu } from '..'
import { flattenMenus } from './flattenMenus'

type ResetType = 'All' | 'Order' | 'Title'

/**
 * @name resetMenu
 * @description reset menus title, order, parent
 * @param menus MenuList array
 * @param setMenus Set menus state
 * @param setRemoveMenus Set removed menus state
 * @param resetType  Set type of value that must be reset
 */
export const resetMenu = (
  menus: MenuList[],
  setMenus: React.Dispatch<React.SetStateAction<MenuList[]>>,
  setRemoveMenus: React.Dispatch<React.SetStateAction<TRemoveMenu>>,
  resetType: ResetType
) => {
  const screens = flattenMenus(menus)
    .filter((menu) => {
      if (resetType === 'Title') {
        return true
      }
      return !menu.isNew
    })
    .filter((menu) => {
      if (resetType === 'Title') {
        return true
      }
      return !menu.removeOnReset
    })
    .map((menu) => {
      if (menu.screenId) {
        return resetMenuReturnData(menu, resetType)
      }
      if (resetType !== 'Title' && menu.defaultGroupId) {
        return {
          ...menu,
          order: menu.defaultOrder,
          parentId: menu.defaultParentId === undefined ? null : menu.defaultParentId,
          isUpdated: menu.defaultParentId === menu.parentId ? false : true,
          isDeleted: true
        }
      }
      return menu
    })
  const sortScreens =
    resetType === 'Title' ? screens : _.sortBy(screens, ['parentId', 'defaultOrder'])
  const sorted = arrayToTree(sortScreens, {
    dataField: null
  }) as MenuList[]
  setMenus(sorted)

  if (resetType === 'All' || resetType === 'Order') {
    const remove = flattenMenus(menus)
      .filter((menu) => menu.removeOnReset)
      .map((menu) => ({ id: menu.id }))
    setRemoveMenus(remove)
  }
}

/**
 * @name resetMenuReturnData
 * @description format the return data based on the reset type
 * @param menu Menu Objectt
 * @param resetType  Set type of value that must be reset
 */
const resetMenuReturnData = (menu: MenuList, resetType: ResetType) => {
  const sameParentIds = menu.defaultParentId === menu.parentId ? false : true
  const sameOrders = menu.defaultOrder === menu.order ? false : true
  const sameTitle = menu.title === menu.screen?.defaultTitle ? false : true

  switch (resetType) {
    case 'All':
      return {
        ...menu,
        title: menu.screen?.defaultTitle,
        order: menu.defaultOrder,
        parentId: menu.defaultParentId,
        isUpdated: sameParentIds || sameOrders || sameTitle,
        isDeleted: true
      }

    case 'Title':
      return {
        ...menu,
        title: menu.screen?.defaultTitle,
        isUpdated: sameTitle,
        isDeleted: true
      }
    case 'Order':
      return {
        ...menu,
        order: menu.defaultOrder,
        parentId: menu.defaultParentId,
        isUpdated: sameParentIds || sameOrders,
        isDeleted: true
      }

    default:
      return menu
  }
}
