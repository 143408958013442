import { Box, Flex, InputGroup, InputLeftAddon, InputProps } from '@chakra-ui/react'
import { ErrorMessage, FieldArrayRenderProps, FieldProps } from 'formik'
import moment from 'moment'
import * as React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { Calendar } from 'react-feather'
import TimePicker, { TimePickerValue } from 'react-time-picker'
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '../../../constants'
import { useMacsTranslation } from '../../../hooks/translation'
import { theme } from '../../../theme'
import { Text } from '../../Typography'
import RemoveField from '../removeField'
import { Label, LabelProps } from '../styles'
import { DatePickerSelect, DatePickerWrapper } from './styles'
import './timePicker.css'

type ConnectedDatePickerProps = LabelProps &
  FieldProps &
  InputProps & {
    label?: string
    openDirection?: 'down' | 'up'
    isDayBlocked?: (day: any) => boolean
    isOutsideRange?: boolean
    isRequired?: boolean
    disabled?: boolean
    displayFormat?: string
    appendToBody?: boolean
    isDefault?: boolean
    arrayHelpers?: FieldArrayRenderProps
    index?: number
    onRemove?: Function
    show?: {
      timePicker?: boolean
      currentDate?: boolean
    }
  }

const ConnectedDatePicker: React.FC<ConnectedDatePickerProps> = ({
  form,
  field,
  label,
  isDisabled,
  placeholder,
  openDirection,
  isDayBlocked,
  isOutsideRange,
  isRequired,
  disabled,
  displayFormat,
  appendToBody = true,
  isDefault,
  arrayHelpers,
  index = 0,
  onRemove,
  show = {
    timePicker: true,
    currentDate: false
  },
  ...rest
}) => {
  const translated = useMacsTranslation({ text: label || '' })
  const [value, onChange] = React.useState<TimePickerValue>('00:00')
  const [date, setDate] = React.useState(field.value ? moment(new Date(field.value)) : null)
  const [focused, setFocus] = React.useState<boolean | null>(false)
  React.useEffect(() => {
    const time = moment(field.value).isValid() ? moment(field.value).format(TIME_FORMAT) : '00:00'
    onChange(time)
    setDate(field.value ? moment(new Date(field.value)) : null)
  }, [field])

  const renderYears = () => {
    const arr = []
    for (let i = -100; i <= 5; i++) {
      arr.push(
        <option key={moment().year() + i} value={moment().year() + i}>
          {moment().year() + i}
        </option>
      )
    }
    return arr
  }
  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <Flex>
        <Label htmlFor={field.name}>{translated}</Label>
        {arrayHelpers && !isDefault && (
          <RemoveField
            field={field}
            arrayHelpers={arrayHelpers}
            index={index}
            onRemove={onRemove}
          />
        )}
      </Flex>
      <InputGroup>
        <InputLeftAddon
          children={
            <React.Fragment>
              {isRequired && !disabled && (
                <Box width="1px" height="100%" background={theme.colors.background.required}></Box>
              )}
              {!isRequired && !disabled && <Box width="1px" height="100%"></Box>}
            </React.Fragment>
          }
          bg="white"
          border="none"
          padding="4px"
        />

        <DatePickerWrapper>
          <SingleDatePicker
            appendToBody={appendToBody}
            showClearDate
            isDayBlocked={isDayBlocked}
            openDirection={openDirection}
            customInputIcon={<Calendar size={20} opacity={0.3} />}
            inputIconPosition="after"
            showDefaultInputIcon={true}
            hideKeyboardShortcutsPanel={true}
            displayFormat={displayFormat}
            placeholder={placeholder || ''}
            numberOfMonths={1}
            onFocusChange={({ focused }) => setFocus(focused)}
            onDateChange={(date) => {
              const validTime = value ? value : '00:00'
              const dateTime = moment(
                date?.utc().format(DATE_FORMAT) + ' ' + validTime,
                DATE_TIME_FORMAT
              )
              if (dateTime.isValid()) {
                setDate(dateTime)
                form.setFieldValue(field.name, dateTime.toISOString())
              } else {
                setDate(null)
                form.setFieldValue(field.name, null)
              }
            }}
            date={date}
            // @ts-ignore - their typings are inconsistent for focused value
            focused={focused}
            id={field.name}
            disabled={isDisabled}
            isOutsideRange={
              isOutsideRange ? (day) => day.isBefore(moment().subtract(1, 'day')) : () => false
            }
            renderCalendarInfo={() => {
              if (show.timePicker) {
                return (
                  <Flex>
                    <TimePicker
                      className="small-width"
                      disableClock
                      onChange={(time) => {
                        onChange(time)
                        if (date?.isValid()) {
                          const validTime = time ? time : '00:00'
                          const dateTime = moment(
                            date?.format(DATE_FORMAT) + ' ' + validTime,
                            DATE_TIME_FORMAT
                          )
                          setDate(dateTime)
                          form.setFieldValue(field.name, dateTime.toISOString())
                        }
                      }}
                      value={value}
                    />
                  </Flex>
                )
              }

              return null
            }}
            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
              return (
                <Flex flexDirection="row" justifyContent="center">
                  <DatePickerSelect
                    mr={2}
                    name={field.name}
                    value={month.month()}
                    onChange={(e) => {
                      onMonthSelect(month, e.target.value)
                    }}
                  >
                    {moment.months().map((lbl, value) => (
                      <option key={value} value={value}>
                        {lbl}
                      </option>
                    ))}
                  </DatePickerSelect>
                  <DatePickerSelect
                    name={field.name}
                    value={month.year()}
                    onChange={(e) => {
                      onYearSelect(month, e.target.value)
                    }}
                  >
                    {renderYears()}
                  </DatePickerSelect>
                </Flex>
              )
            }}
          />
        </DatePickerWrapper>
      </InputGroup>

      <ErrorMessage name={field.name}>
        {(msg) => (
          <Text mt="4px" ml="8px" color="intent.error">
            {msg}
          </Text>
        )}
      </ErrorMessage>
    </Flex>
  )
}

export default ConnectedDatePicker

ConnectedDatePicker.defaultProps = {
  mb: 2,
  fontWeight: 'lighter',
  displayFormat: DATE_FORMAT
}
