import { Uppy, UppyFile } from '@uppy/core'
//@ts-expect-error - no types avaliable
import isNetworkError from '@uppy/utils/lib/isNetworkError'
//@ts-expect-error -  no types avaliable
import NetworkError from '@uppy/utils/lib/NetworkError'
import ProgressTimeout from '@uppy/utils/lib/ProgressTimeout'
import { RateLimitedQueue } from '@uppy/utils/lib/RateLimitedQueue'

type Options = RequestInit & {
  body: FormData
  onProgress: (ev: ProgressEvent) => void
  useUpload?: boolean
  uppy: Uppy
  file: UppyFile
  requests: RateLimitedQueue
  opts: {
    getResponseData: (responseText: string, xhr: XMLHttpRequest) => Object
    getResponseError: (responseText: string, xhr: XMLHttpRequest) => Object
    validateStatus: (status: number, responseText: string, xhr: XMLHttpRequest) => boolean
    headers: Object
    mutation: any
  }
}

export const customFetch = (url: string, options: Options) => {
  if (!options.useUpload) {
    return fetch(url, options)
  }
  return createCustomFetch(url, options)
}

const createCustomFetch = (
  url: string,
  { method, uppy, file, requests, opts, onProgress, body, headers }: Options
) => {
  return new Promise<any>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method || 'get', url)

    const timer = new ProgressTimeout(30 * 1000, () => {
      xhr.abort()
      queuedRequest.done()
      uppy.emit('upload-error', file, 'Error')
      reject('Error')
    })
    xhr.upload.addEventListener('progress', (ev) => {
      timer.progress()
      if (ev.lengthComputable) {
        uppy.emit('upload-progress', file, {
          uploader: this,
          bytesUploaded: ev.loaded,
          bytesTotal: ev.total
        })
      }
    })
    xhr.onload = (ev: any) => {
      const body = opts.getResponseData(xhr.responseText, xhr)
      uppy.emit('upload-success', file, { status: 200, body })
      timer.done()
      queuedRequest.done()

      const status = ev?.target?.status
      if (opts.validateStatus(status, xhr.responseText, xhr)) {
        return resolve({
          ok: true,
          text: () => Promise.resolve(ev.target.responseText),
          json: () => Promise.resolve(JSON.parse(ev.target.responseText)),
          file
        })
      } else {
        const error = buildResponseError(xhr, opts.getResponseError(xhr.responseText, xhr))
        const response = {
          status: status,
          body
        }

        uppy.emit('upload-error', file, error, response)
        return reject(error)
      }
    }
    uppy.on('file-removed', () => {
      timer.done()
      queuedRequest.done()
      xhr.abort()
      const error = buildResponseError(xhr, opts.getResponseError(xhr.responseText, xhr))
      uppy.emit('upload-error', file, error, { status: null })
    })
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'))
    }
    if (xhr.upload) {
      xhr.upload.onprogress = onProgress
    }

    const queuedRequest = requests.run(() => {
      uppy.emit('upload-started', file)
      for (const header in headers || {})
        xhr.setRequestHeader(
          header,
          //@ts-expect-error - header of type string can not be used as a key for options.headers
          headers[header]
        )
      xhr.send(body)
      return () => {
        timer.done()
        xhr.abort()
      }
    })
  })
}

const buildResponseError = (xhr: XMLHttpRequest, err: any) => {
  let error = err
  // No error message
  if (!error) error = new Error('Upload error')
  // Got an error message string
  if (typeof error === 'string') error = new Error(error)
  // Got something else
  if (!(error instanceof Error)) {
    error = Object.assign(new Error('Upload error'), { data: error })
  }
  if (isNetworkError(xhr)) {
    error = new NetworkError(error, xhr)
    return error
  }
  error.request = xhr
  return error
}
