import {
  CurrentUserFragment,
  Maybe,
  useCurrentUserQuery,
  UserMenusFragment
} from '../generated/graphql'
import { useMacsToast } from './useToast'

type TData = Maybe<{ __typename: 'WMSUser' } & CurrentUserFragment & UserMenusFragment>

/**
 *
 * @name useCurrentUser
 * @description Calls useCurrentUserQuery and returns all relevant current user information
 * @returns  getCurrentUser function
 */
export const useCurrentUser = () => {
  const macsToast = useMacsToast()
  const { data, error } = useCurrentUserQuery()
  /**
   *
   * @name getCurrentUser
   * @description Returns the current users information in a callback function or shows an error message
   * @param onSuccess callback function that executes when the current user is fetched and passes the users information up
   * @returns  getCurrentUser function
   */
  const getCurrentUser = (onSuccess: (data: TData) => void) => {
    if (data?.currentUser?.__typename === 'WMSUser') {
      onSuccess(data.currentUser)
    } else if (data?.currentUser?.__typename === 'DatabaseError') {
      macsToast({
        status: 'error',
        title: 'Database Error',
        description: data.currentUser.message
      })
    } else if (data?.currentUser?.__typename === 'ValidationError') {
      macsToast({
        status: 'error',
        title: 'Validation Error',
        description: data.currentUser.messages
      })
    }

    if (error) {
      macsToast({
        status: 'error',
        title: 'Unknown Error',
        description: error.message
      })
    }
  }

  return getCurrentUser
}
