import { Input } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { theme } from '../../../theme'

const StyledInput = styled(Input)`
  background: ${theme.colors.background.surface};
  border: ${theme.colors.border.default};
  max-width: ${(props) => props.maxWidth};
`

StyledInput.defaultProps = {
  marginBottom: 2,
  paddingX: '12px'
}

export default StyledInput
