import * as React from 'react'
import {
  LicencePlateTypesQuery,
  StockOwnerProductStatusesQuery,
  useLicencePlateTypesLazyQuery,
  useStockOwnerProductStatusesLazyQuery
} from '../generated/graphql'

type GoodReceiptContextType = {
  tabIndex: number
  updateTabIndex: (index: number) => void
  tabAccessor: string
  updateTabAccessor: (accessor: string) => void
  maxSize: number
  createMaxSize: (maxSize: number) => void
  productStatusesData: StockOwnerProductStatusesQuery
  callProductStatusesLazyQuery: (stockOwnerId: number) => void
  licencPlateTypeData: LicencePlateTypesQuery
  callLicencePlateLazyQuery: () => void
  isAdhoc: boolean
  createIsAdhoc: (adhoc: boolean) => void
}

export const GoodReceiptContext = React.createContext<GoodReceiptContextType>({
  tabIndex: 0,
  updateTabIndex: () => null,
  tabAccessor: '',
  updateTabAccessor: () => null,
  maxSize: 0,
  createMaxSize: () => null,
  productStatusesData: {} as StockOwnerProductStatusesQuery,
  callProductStatusesLazyQuery: () => null,
  licencPlateTypeData: {} as LicencePlateTypesQuery,
  callLicencePlateLazyQuery: () => null,
  isAdhoc: false,
  createIsAdhoc: () => null
})

export const useGoodReceiptContext = () => React.useContext(GoodReceiptContext)

const GoodReceiptProvider: React.FC<{}> = ({ children }) => {
  const [productStatusesData, setProductStatusesData] = React.useState<
    StockOwnerProductStatusesQuery
  >({})
  const [licencPlateTypeData, setSicencPlateTypeData] = React.useState<LicencePlateTypesQuery>({})
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [tabAccessor, setTabAccessor] = React.useState<string>('expected')
  const [maxSize, setMaxSize] = React.useState<number>(0)
  const [isAdhoc, setIsAdhoc] = React.useState<boolean>(false)

  const [stockOwnerProductStatusesLazyQuery] = useStockOwnerProductStatusesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProductStatusesData(data)
    }
  })
  const [licencePlateTypesLazyQuery] = useLicencePlateTypesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSicencPlateTypeData(data)
    }
  })

  function createIsAdhoc(adhoc: boolean) {
    setIsAdhoc(adhoc)
  }

  function callProductStatusesLazyQuery(stockOwnerId: number) {
    stockOwnerProductStatusesLazyQuery({
      variables: {
        id: stockOwnerId
      }
    })
  }
  function callLicencePlateLazyQuery() {
    licencePlateTypesLazyQuery()
  }

  function updateTabIndex(index: number) {
    setTabIndex(index)
  }

  function updateTabAccessor(accessor: string) {
    setTabAccessor(accessor)
  }

  function createMaxSize(maxSize: number) {
    setMaxSize(maxSize)
  }

  return (
    <GoodReceiptContext.Provider
      value={{
        tabIndex,
        updateTabIndex,
        tabAccessor,
        updateTabAccessor,
        maxSize,
        createMaxSize,
        productStatusesData,
        callProductStatusesLazyQuery,
        licencPlateTypeData,
        callLicencePlateLazyQuery,
        isAdhoc,
        createIsAdhoc
      }}
    >
      {children}
    </GoodReceiptContext.Provider>
  )
}

export default GoodReceiptProvider
