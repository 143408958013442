import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'
import { useAppContext } from '../contexts/ApplicationProvider.context'
import { logger } from '../utils'

export const useIsTranslationReady = (name: string) => {
  const { createPageName } = useAppContext()
  const { ready } = useTranslation(name, { useSuspense: false })
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    logger({ name })
    createPageName(name).then(() => {
      setIsReady(!!ready)
    })
  }, [ready])
  return isReady
}

export const useMacsTranslation = ({ name, text }: { name?: string; text: string }) => {
  const { pageName } = useAppContext()
  const { t, ready } = useTranslation(name || pageName.translation, { useSuspense: false })
  const [translated, setTranslated] = useState('')
  useEffect(() => {
    const translation = ready ? t(text) : text
    setTranslated(translation)
  }, [t, text, ready])

  return translated
}

export const useMacsTranslationFunction = (name?: string) => {
  const { pageName } = useAppContext()
  const { t, ready } = useTranslation(name || pageName.translation, { useSuspense: false })
  return ready ? t : (text: string) => text
}

export const useMacsTranslationColumn = ({ columns }: { columns: Column<any>[] }) => {
  const { pageName } = useAppContext()
  const { t, ready } = useTranslation(pageName.translation, { useSuspense: false })
  const [translated, setTranslated] = useState<Column<any>[]>(columns)

  useEffect(() => {
    if (ready) {
      const translatedColumns = columns.map((column) => {
        const Header = t(column.Header?.toString() || '')
        return {
          ...column,
          Header
        }
      })
      setTranslated(translatedColumns)
    } else {
      setTranslated(columns)
    }
  }, [t, ready, columns])

  return translated
}

type TabProps = {
  label: string
  accessor: string
  content: JSX.Element
}
export const useMacsTranslationTabs = ({ tabs }: { tabs: TabProps[] }) => {
  const { pageName } = useAppContext()
  const { t, ready } = useTranslation(pageName.translation, { useSuspense: false })
  const [translated, setTranslated] = useState<TabProps[]>(tabs)

  useEffect(() => {
    if (ready) {
      const translatedTabs = tabs.map((tab) => {
        const label = t(tab.label)
        return {
          ...tab,
          label
        }
      })
      setTranslated(translatedTabs)
    } else {
      setTranslated(tabs)
    }
  }, [t, ready, tabs])

  return translated
}

type RadioProp = {
  label: string
  value: string
  tooltip?: string
}
export const useMacsTranslationRadioGroup = ({ radio }: { radio: RadioProp[] }) => {
  const { pageName } = useAppContext()
  const { t, ready } = useTranslation(pageName.translation, { useSuspense: false })
  const [translated, setTranslated] = useState<RadioProp[]>(radio)

  useEffect(() => {
    if (ready) {
      const translatedRadio = radio.map((radio) => {
        const label = t(radio.label)

        if (radio.tooltip) {
          const tooltip = t(radio.tooltip)
          return {
            ...radio,
            label,
            tooltip
          }
        }
        return {
          ...radio,
          label
        }
      })
      setTranslated(translatedRadio)
    } else {
      setTranslated(radio)
    }
  }, [t, ready, radio])

  return translated
}
