import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { MenuList } from '..'
import { UserSetupFormValues } from '../../..'
import {
  MenuEntry,
  useCurrentUserLazyQuery,
  useDefaultMenuLazyQuery,
  useUpdateUserMenusMutation,
  useUserMenuByUserIdLazyQuery
} from '../../../../../generated/graphql'
import { useMacsToast } from '../../../../../hooks/useToast'
import { formatGqlError } from '../../../../../utils'
import { flattenMenus } from './flattenMenus'
import { menuMerger } from './menuMerger'
import { convertToSave } from './save'

/**
 * @name setParentMenus
 * @description on drag functionality setting the main groups positions when draged and dropped
 * @param currentList MenuList Array
 * @returns MenuList Array
 */
export const setParentMenus = (currentList: MenuList[]) => {
  return currentList.map((list, index) => {
    return {
      ...list,
      parentId: null,
      order: index,
      isUpdated: true
    }
  })
}

/**
 * @name editTitle
 * @description sets both group and screens title on change
 * @param sourceList MenuList Array
 * @param menuIndex number Array
 * @param e onChange event for the input
 * @returns MenuList Array
 */
export const editTitle = (
  sourceList: MenuList[],
  menuIndex: number[],
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const tempList = [...sourceList]
  const _blockIndex = [...menuIndex]
  const lastIndex = _blockIndex.pop() || 0
  const lastArr = _blockIndex.reduce((arr, index) => arr[index].children || [], tempList)
  if (!lastArr[lastIndex].defaultOnly) {
    lastArr[lastIndex].isUpdated = true
  }
  lastArr[lastIndex].title = e.target?.value
  return tempList
}

export const useSaveButton = () => {
  const macsToast = useMacsToast()
  const [updateUserMutation, { loading: updateMenuLoading }] = useUpdateUserMenusMutation({
    onError: (e) => {
      macsToast({
        title: 'An error has occured',
        description: formatGqlError(e),
        status: 'error'
      })
    },
    onCompleted: (data) => {
      if (data.updateUser?.__typename === 'WMSUser') {
        macsToast({
          title: 'Menu Updated',
          description: 'User menu updated',
          status: 'success'
        })
      } else if (data.updateUser?.__typename === 'DatabaseError') {
        macsToast({
          title: 'An error has occured',
          description: data.updateUser.message,
          status: 'error'
        })
      } else if (data.updateUser?.__typename === 'ValidationError') {
        macsToast({
          title: 'An error has occured',
          description: data.updateUser?.messages,
          status: 'error'
        })
      } else {
        macsToast({
          title: 'An error has occured',
          description: 'Unknown Error',
          status: 'error'
        })
      }
    }
  })
  return { updateUserMutation, updateMenuLoading }
}

export const useFetchMenus = (isManager = true) => {
  const { values, setFieldValue } = useFormikContext<UserSetupFormValues>()
  const [menus, setMenus] = useState<MenuList[]>([])
  const [defaultMenus, setDefaultMenus] = useState<MenuEntry[]>([])
  const [parentIds, setParentIds] = useState<number[]>([])
  const [menusLoading, setMenusLoading] = useState(true)
  const [defaultUpdated, setDefaultUpdated] = useState<{ screen: MenuList[]; group: MenuList[] }>({
    screen: [],
    group: []
  })

  const [defaultMenu] = useDefaultMenuLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (defaultMenus) => {
      if (
        userMenu?.userById?.__typename === 'WMSUser' &&
        userMenu.userById.userMenus &&
        defaultMenus.defaultMenu
      ) {
        const mergedMenus = menuMerger(
          defaultMenus.defaultMenu as MenuEntry[],
          userMenu.userById.userMenus as MenuEntry[],
          isManager
        )
        setDefaultMenus(defaultMenus.defaultMenu as MenuEntry[])
        const data = JSON.parse(JSON.stringify(mergedMenus)) as MenuList[]
        setMenus(data)
        const pId = data.map((d) => d.id)
        setParentIds(pId)

        const create = flattenMenus(menus)
          .filter((menu) => menu.isNew)
          .map((menu) => {
            return convertToSave(menu)
          })

        const createDefaultScreen = flattenMenus(data).filter((menu) => {
          const parentExists = flattenMenus(create).find(
            (createMenu) => createMenu.screenId === menu.screenId
          )
          return menu.defaultOnly && !parentExists
        })
        const creatDefaultGroup = flattenMenus(data)
          .filter((menu) => menu.defaultGroupOnly)
          .map((menu) => {
            return convertToSave(menu)
          })
        setDefaultUpdated({
          screen: createDefaultScreen,
          group: creatDefaultGroup
        })
        setMenusLoading(false)
      }
    }
  })
  const [userMenuByUserId, { data: userMenu }] = useUserMenuByUserIdLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      defaultMenu()
    }
  })

  const [currentUserQuery] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.currentUser?.__typename === 'WMSUser') {
        setFieldValue('id', data.currentUser.id)
        userMenuByUserId({
          variables: {
            id: data.currentUser.id
          }
        })
      }
    }
  })

  useEffect(() => {
    if (isManager) {
      userMenuByUserId({
        variables: {
          id: values.id
        }
      })
    } else {
      currentUserQuery()
    }
  }, [])

  return {
    menus,
    setMenus,
    defaultMenus,
    parentIds,
    menusLoading,
    defaultUpdated,
    setDefaultUpdated
  }
}
