import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { persistCache } from 'apollo-cache-persist'
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types'
import { createUploadLink } from 'apollo-upload-client'
import { IDENTITY_SERVER, USER_CONTEXT_STORAGE_KEY } from './constants'
import introspectionQueryResultData from './fragmentTypes.json'
import { customFetch } from './utils/'
import { getIdentityServerToken } from './utils/getIdentityServerToken'

const cache = new InMemoryCache({ possibleTypes: introspectionQueryResultData.possibleTypes })

const httpLink = createUploadLink({
  uri: IDENTITY_SERVER.graphQLEndpoint,
  fetch: typeof window === 'undefined' ? global.fetch : customFetch
})

const middlewareLink = new ApolloLink((operation, forward) => {
  const getUserContext = () => {
    const context = localStorage.getItem(USER_CONTEXT_STORAGE_KEY)

    return context ? JSON.parse(context) : null
  }

  const userContext = getUserContext()

  operation.variables = {
    ...operation.variables,
    userContext
  }

  return forward(operation)
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from (local/session) storage if it exists
  const token = getIdentityServerToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})
//@ts-expect-error - createUploadLink and Apollo offical link do not always match in types
const link = ApolloLink.from([middlewareLink, authLink, httpLink])

export const client = new ApolloClient({
  link,
  cache
})

export const persistedClient = async () => {
  await persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>
  })

  return client
}
