import { useToast } from '@chakra-ui/react'
import React from 'react'
import { Notification } from '../components'

type Status = 'error' | 'info' | 'warning' | 'success'
type MacsTostProps = {
  status: Status
  title: string
  description: string | null | undefined | (string | null)[]
}
export const useMacsToast = () => {
  const toast = useToast()

  const macsToast = ({ status, title, description }: MacsTostProps) => {
    if (Array.isArray(description)) {
      description.forEach((message) => {
        toast({
          duration: 6000,
          isClosable: true,
          position: 'top-right',
          render: ({ onClose }: any) => (
            <Notification
              status={status}
              title={title}
              description={message || ''}
              onClose={onClose}
            />
          )
        })
      })
    } else {
      toast({
        duration: 6000,
        isClosable: true,
        position: 'top-right',
        render: ({ onClose }: any) => (
          <Notification
            status={status}
            title={title}
            description={description || ''}
            onClose={onClose}
          />
        )
      })
    }
  }

  return macsToast
}
