import * as React from 'react'
import { CustomCarrierAccount } from '../containers/CarrierSetup/index'
import { CarrierAccount, CarrierService } from '../generated/graphql'
import { stripSelectValues } from '../utils/stripSelectValues'

type ConfigType = { accountId: number } & {
  configuration: {
    id: number
    setting: string
    value: string
  }[]
}

type CarreirSetupContextType = {
  tabIndex: number
  updateTabIndex: (index: number) => void

  newServices: CarrierService[]
  createNewServices: (service: CarrierService) => void
  deleteNewServices: (code: string) => void
  updatedServices: CarrierService[]
  createUpdatedServices: (service: CarrierService) => void
  deletableServices: CarrierService[]
  deleteServices: (services: CarrierService[]) => void
  clearServices: () => void

  newAccounts: CarrierAccount[]
  createNewAccounts: (account: CustomCarrierAccount) => void
  deleteNewAccounts: (accountCode: string) => void
  updatedAccounts: CarrierAccount[]
  createUpdatedAccounts: (account: CustomCarrierAccount) => void
  deletableAccounts: CarrierAccount[]
  deleteAccounts: (Accounts: CarrierAccount[]) => void
  clearAccounts: () => void

  carrierConfig: ConfigType[]
  clearConfig: () => void
}

export const CarreirSetupContext = React.createContext<CarreirSetupContextType>({
  tabIndex: 0,
  updateTabIndex: () => null,

  newServices: [],
  createNewServices: () => null,
  deleteNewServices: () => null,
  updatedServices: [],
  createUpdatedServices: () => null,
  deletableServices: [],
  deleteServices: () => null,
  clearServices: () => null,

  newAccounts: [],
  createNewAccounts: () => null,
  deleteNewAccounts: () => null,
  updatedAccounts: [],
  createUpdatedAccounts: () => null,
  deletableAccounts: [],
  deleteAccounts: () => null,
  clearAccounts: () => null,

  carrierConfig: [],
  clearConfig: () => null
})

export const useCarreirSetupContext = () => React.useContext(CarreirSetupContext)

const CarreirSetupProvider: React.FC<{}> = ({ children }) => {
  const [updatedServices, setUpdatedServices] = React.useState<CarrierService[]>([])
  const [newServices, setNewServices] = React.useState<CarrierService[]>([])
  const [deletableServices, setDeletableServices] = React.useState<CarrierService[]>([])

  const [updatedAccounts, setUpdatedAccounts] = React.useState<CarrierAccount[]>([])
  const [newAccounts, setNewAccounts] = React.useState<CarrierAccount[]>([])
  const [deletableAccounts, setDeletableAccounts] = React.useState<CarrierAccount[]>([])

  const [carrierConfig, setCarrierConfig] = React.useState<ConfigType[]>([])

  const [tabIndex, setTabIndex] = React.useState<number>(0)

  function updateTabIndex(index: number) {
    setTabIndex(index)
  }

  function createNewServices(service: CarrierService) {
    setNewServices([...newServices, service])
  }

  function deleteNewServices(code: string) {
    const filteredNewServices = newServices.filter((newService) => newService.code !== code)
    setNewServices(filteredNewServices)
  }

  function createUpdatedServices(service: CarrierService) {
    setUpdatedServices([...updatedServices, service])
  }

  function deleteServices(services: CarrierService[]) {
    setDeletableServices(services)
  }

  function clearServices() {
    setNewServices([])
    setUpdatedServices([])
    setDeletableServices([])
  }

  function createNewAccounts(account: CustomCarrierAccount) {
    //@ts-ignore - Property 'selectedConfig' does not exist on type 'CarrierAccount'  - added on later as custom
    const { selectedConfig, fetchConfig, configuration, ...accountRest } = account
    const strippedAccount = stripSelectValues(accountRest) as CarrierAccount
    setNewAccounts([...newAccounts, strippedAccount])
    if (configuration && configuration.length > 0) {
      const config = {
        accountId: accountRest.id,
        configuration
      }
      //@ts-ignore - Type 'CarrierConfig | Maybe<CarrierConfig>[]' is not assignable to type 'CarrierConfig'
      setCarrierConfig([...carrierConfig, config])
    }
  }

  function deleteNewAccounts(accountCode: string) {
    const filteredNewAccounts = newAccounts.filter(
      (newAccount) => newAccount.account !== accountCode
    )
    setNewAccounts(filteredNewAccounts)
  }

  function createUpdatedAccounts(account: CustomCarrierAccount) {
    //@ts-ignore - Property 'selectedConfig' does not exist on type 'CarrierAccount' - added on later as custom
    const { configuration, selectedConfig, fetchConfig, ...accountRest } = account
    const strippedAccount = stripSelectValues(accountRest) as CarrierAccount
    if (configuration && configuration.length > 0) {
      const config = {
        accountId: accountRest.id,
        configuration
      }
      //@ts-ignore - Type 'CarrierConfig | Maybe<CarrierConfig>[]' is not assignable to type 'CarrierConfig'
      setCarrierConfig([...carrierConfig, config])
    }
    setUpdatedAccounts([...updatedAccounts, strippedAccount])
  }

  function deleteAccounts(accounts: CarrierAccount[]) {
    setDeletableAccounts(accounts)
  }

  function clearAccounts() {
    setNewAccounts([])
    setUpdatedAccounts([])
    setDeletableAccounts([])
  }

  function clearConfig() {
    setCarrierConfig([])
  }

  return (
    <CarreirSetupContext.Provider
      value={{
        tabIndex,
        updateTabIndex,

        newServices,
        createNewServices,
        deleteNewServices,
        updatedServices,
        createUpdatedServices,
        deletableServices,
        deleteServices,
        clearServices,

        newAccounts,
        createNewAccounts,
        deleteNewAccounts,
        updatedAccounts,
        createUpdatedAccounts,
        deletableAccounts,
        deleteAccounts,
        clearAccounts,

        carrierConfig,
        clearConfig
      }}
    >
      {children}
    </CarreirSetupContext.Provider>
  )
}

export default CarreirSetupProvider
