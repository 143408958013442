import { extendTheme } from '@chakra-ui/react'

const colors = {
  background: {
    base: '#E9E9E9',
    muted: '#FAFAFA',
    surface: '#FFFFFF',
    required: '#F85F04',
    modal: 'rgba(0,0,0,0.3)'
  },
  border: {
    default: '1px solid #D9D9D9',
    error: '1px solid #F85F04',
    base: '#D9D9D9',
    required: '#F85F04',
    divide: '#9BEAE1',
    dropDownButton: '1px solid #E2E8F0',
    sidebarTableMessages: '1px solid #E1E1E1'
  },
  intent: {
    success: '#38A169',
    error: '#E53E3E',
    warning: '#DD6B34',
    info: '#3182CE'
  },
  text: {
    default: '#262626',
    lightGray: '#656565',
    gray: '#585858',
    muted: '#A7AFB9',
    link: '#178FFF',
    onPrimary: '#FFFFFF'
  },
  primary: {
    base: '#63BFB4',
    muted: '#DBF0EE'
  },
  solid: {
    black: '#262626',
    muted: '#F5F5F5',
    white: '#FFFFFF',
    tab: '#1890FF',
    field: '#F1F1F1'
  },
  import: {
    canDrag: '#64BFB4',
    canDrop: '#D8EAE8',
    text: {
      default: '#656565',
      mapped: '#6F9E02'
    },
    background: '#E5E5E5',
    remove: '#F54345',
    activeSetting: '#64BFB4',
    clickedImport: 'rgba(100,191,180,0.15)'
  },
  sidebar: {
    base: '#000B16',
    subMenu: {
      base: '#001A34'
    }
  },
  advancedTable: {
    filter: '#F0F0F0',
    tableHeader: '#E1E1E1',
    sidePanel: '#E1E1E1',
    valueText: '#707070'
  },
  progressBar: {
    primary: { 500: '#63BFB4' }
  },
  menuManager: {
    displayHeader: '#6A6A6A',
    edit: '#64BFB4',
    switch: '#1890FF',
    remove: '#F54345',
    popover: '#EFEEEE'
  }
}

const borders = {
  default: '1px solid #D9D9D9',
  import: {
    mapping: {
      systemGenerated: '5px solid #BEBEBE',
      destination: {
        divider: '1px solid #64BFB4',
        box: '1px solid #d9d9d9',
        dropBox: ' 1px dashed rgba(0,0,0,0.37)',
        dropBoxGreen: '1px solid #64BFB4'
      },
      import: {
        dragBox: '1px solid rgba(0, 0, 0, 0.15)'
      }
    }
  }
}

const makeTheme = extendTheme({
  styles: {
    global: (props) => ({
      ' html,body': {
        backgroundColor: props.theme.colors.background.base,
        fontSize: '14px'
      },
      button: {
        fontWeight: 'normal !important',
        '&:disabled': {
          opacity: 0.4
        }
      },
      fieldset: {
        display: 'contents',
        width: '100%'
      },
      'fieldset&:disabled': {
        '.react-select__control,input,textarea': {
          backgroundColor: props.theme.colors.background.muted,
          '&:hover': {
            backgroundColor: props.theme.colors.background.muted
          }
        }
      },
      'input,textarea': {
        '&:disabled': {
          backgroundColor: `${props.theme.colors.background.muted} !important`,
          opacity: '0.4',
          '&:hover': {
            backgroundColor: props.theme.colors.background.muted
          }
        }
      }
    })
  },
  fonts: {
    heading: 'PingFang SC, sans-serif',
    body: 'PingFang SC, sans-serif'
  },
  colors: {
    ...(colors as typeof colors)
  },
  borders: {
    ...(borders as typeof borders)
  },
  inputHeight: '32px',
  boxShadow: {
    thin: '0 1px 4px 0 #CCD0D2',
    thick: '0 8px 10px 0 #C7CDD4'
  },
  space: {
    px: '1px',
    0: '0',
    1: '8px',
    2: '16px',
    3: '24px',
    4: '32px',
    5: '40px',
    6: '48px',
    7: '56px',
    8: '64px'
  },
  // transition: 'all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
})

const theme = makeTheme

export { theme }
