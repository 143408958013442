import { Divider, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { UnderConstruction } from '../../components'
import { H1, P } from '../../components/Typography'
import { useMacsTranslationFunction } from '../../hooks/translation'
import { ScrollView } from '../../layouts/PageWrap/styles'

type InfoPageProps = {
  header: string
  description: string
}

/**
 * @render react
 * @name InfoPage container
 * @description Creates a blank page with macs logo and a message to the user
 * @param header - string header
 * @param description - string description
 * @returns React Functional Componenet
 */
const InfoPage: FC<InfoPageProps> = ({ header, description }) => {
  const translated = useMacsTranslationFunction()
  return (
    <ScrollView
      display="flex"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Flex flexDirection="column" width="90%" mt="13rem">
        <UnderConstruction height="auto" />
        <Divider opacity={1} height="1px" borderColor="#CCCCCC" mt={2} mb={5} />
        <H1 textAlign="center" color="primary.base">
          {translated(header)}
        </H1>
        <P textAlign="center" mt={3} fontSize="18px">
          {translated(description)}
        </P>
      </Flex>
    </ScrollView>
  )
}

export default InfoPage
