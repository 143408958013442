import * as React from 'react'
import { SelectObject } from '../components/FormElements/ConnectedSelect'
import { TConfirmMethod, TOrderStatus, TPickMethod, TStatuses } from '../containers/StockOwnerSetup'
import { TCategory } from '../containers/StockOwnerSetup/index'
import { CategoryDefinition, ProductStatus } from '../generated/graphql'

type StockOwnerContextType = {
  tabIndex: number
  updateTabIndex: (index: number) => void
  newStatuses: TStatuses[]
  createNewStatuses: (status: TStatuses) => void
  deleteNewStatuses: (code: string) => void
  updatedStatuses: TStatuses[]
  createUpdatedStatues: (statuses: TStatuses) => void
  deletableStatuses: ProductStatus[]
  deleteStatuses: (status: ProductStatus[]) => void
  updatedCategories: TCategory[]
  createUpdatedCategories: (category: TCategory) => void
  newCategories: TCategory[]
  deleteNewCategories: (code: string) => void
  createNewCategories: (category: TCategory) => void
  deletableCategories: CategoryDefinition[]
  deleteCategories: (category: CategoryDefinition[]) => void
  newOrderStatuses: TOrderStatus[]
  createNewOrderStatues: (status: TOrderStatus) => void
  deleteNewOrderStatuses: (code: string) => void
  updatedOrderStatuses: TOrderStatus[]
  createUpdatedOrderStatuses: (status: TOrderStatus) => void
  deletableOrderStatuses: TOrderStatus[]
  deleteOrderStatuses: (statuses: TOrderStatus[]) => void
  newLinkedStockOwners: SelectObject[]
  createLinkedStockOwners: (options: SelectObject[]) => void
  newPickMethods: TPickMethod[]
  createNewPickMethods: (pickMethod: TPickMethod) => void
  deleteNewPickMethods: (name: string) => void
  updatedPickMethods: TPickMethod[]
  createUpdatedPickMethods: (pickMethod: TPickMethod) => void
  deletablePickMethods: TPickMethod[]
  deletePickMethods: (pickMethods: TPickMethod[]) => void
  newConfirmMethods: TConfirmMethod[]
  createNewConfirmMethods: (confirmMethod: TConfirmMethod) => void
  deleteNewConfirmMethods: (name: string) => void
  updatedConfirmMethods: TConfirmMethod[]
  createUpdatedConfirmMethods: (confirmMethod: TConfirmMethod) => void
  deletableConfirmMethods: TConfirmMethod[]
  deleteConfirmMethods: (confirmMethod: TConfirmMethod[]) => void
  clearStatuses: () => void
  clearCategories: () => void
  clearOrderStatuses: () => void
  clearPickMethods: () => void
  clearConfirmMethods: () => void
}

export const StockOwnerContext = React.createContext<StockOwnerContextType>({
  tabIndex: 0,
  updateTabIndex: () => null,
  newStatuses: [],
  createNewStatuses: () => null,
  deleteNewStatuses: () => null,
  updatedStatuses: [],
  createUpdatedStatues: () => null,
  deletableStatuses: [],
  deleteStatuses: () => null,
  updatedCategories: [],
  createUpdatedCategories: () => null,
  newCategories: [],
  deleteNewCategories: () => null,
  createNewCategories: () => null,
  deletableCategories: [],
  deleteCategories: () => null,
  newOrderStatuses: [],
  createNewOrderStatues: () => null,
  deleteNewOrderStatuses: () => null,
  updatedOrderStatuses: [],
  createUpdatedOrderStatuses: () => null,
  deletableOrderStatuses: [],
  deleteOrderStatuses: () => null,
  newLinkedStockOwners: [],
  createLinkedStockOwners: () => null,
  newPickMethods: [],
  createNewPickMethods: () => null,
  deleteNewPickMethods: () => null,
  updatedPickMethods: [],
  createUpdatedPickMethods: () => null,
  deletablePickMethods: [],

  newConfirmMethods: [],
  createNewConfirmMethods: () => null,
  deleteNewConfirmMethods: () => null,
  updatedConfirmMethods: [],
  createUpdatedConfirmMethods: () => null,
  deletableConfirmMethods: [],
  deleteConfirmMethods: () => null,

  deletePickMethods: () => null,
  clearStatuses: () => null,
  clearCategories: () => null,
  clearOrderStatuses: () => null,
  clearPickMethods: () => null,
  clearConfirmMethods: () => null
})

export const useStockOwnerContext = () => React.useContext(StockOwnerContext)

const StockOwnerProvider: React.FC<{}> = ({ children }) => {
  const [updatedStatuses, setUpdatedStatuses] = React.useState<TStatuses[]>([])
  const [newStatuses, setNewStatuses] = React.useState<TStatuses[]>([])
  const [deletableStatuses, setDeletableStatuses] = React.useState<ProductStatus[]>([])

  const [updatedCategories, setUpdatedCategories] = React.useState<TCategory[]>([])
  const [newCategories, setNewCategories] = React.useState<TCategory[]>([])
  const [deletableCategories, setDeletableCategories] = React.useState<CategoryDefinition[]>([])

  const [updatedOrderStatuses, setUpdatedOrderStatuses] = React.useState<TOrderStatus[]>([])
  const [newOrderStatuses, setNewOrderStatuses] = React.useState<TOrderStatus[]>([])
  const [deletableOrderStatuses, setDeletableOrderStatuses] = React.useState<TOrderStatus[]>([])

  const [updatedPickMethods, setUpdatedPickMethods] = React.useState<TPickMethod[]>([])
  const [newPickMethods, setNewPickMethods] = React.useState<TPickMethod[]>([])
  const [deletablePickMethods, setDeletablePickMethods] = React.useState<TPickMethod[]>([])

  const [updatedConfirmMethods, setUpdatedConfirmMethods] = React.useState<TConfirmMethod[]>([])
  const [newConfirmMethods, setNewConfirmMethods] = React.useState<TConfirmMethod[]>([])
  const [deletableConfirmMethods, setDeletableConfirmMethods] = React.useState<TConfirmMethod[]>([])

  const [newLinkedStockOwners, setNewLinkedStockOwners] = React.useState<SelectObject[]>([])

  const [tabIndex, setTabIndex] = React.useState<number>(0)

  function updateTabIndex(index: number) {
    setTabIndex(index)
  }

  function createLinkedStockOwners(options: SelectObject[]) {
    setNewLinkedStockOwners(options || [])
  }

  function createNewConfirmMethods(confirmMethod: TConfirmMethod) {
    setNewConfirmMethods([...newConfirmMethods, confirmMethod])
  }
  function deleteNewConfirmMethods(name: string) {
    const filteredNewConfirmMethod = newConfirmMethods.filter(
      (newConfirmMethod) => newConfirmMethod.name !== name
    )
    setNewConfirmMethods(filteredNewConfirmMethod)
  }

  function createUpdatedConfirmMethods(confirmMethod: TConfirmMethod) {
    setUpdatedConfirmMethods([...updatedConfirmMethods, confirmMethod])
  }

  function deleteConfirmMethods(confirmMethod: TConfirmMethod[]) {
    setDeletableConfirmMethods(confirmMethod)
  }

  function createNewPickMethods(pickMethod: TPickMethod) {
    setNewPickMethods([...newPickMethods, pickMethod])
  }
  function deleteNewPickMethods(name: string) {
    const filteredNewPickMethod = newPickMethods.filter(
      (newPickMethod) => newPickMethod.name !== name
    )
    setNewPickMethods(filteredNewPickMethod)
  }

  function createUpdatedPickMethods(pickMethod: TPickMethod) {
    setUpdatedPickMethods([...updatedPickMethods, pickMethod])
  }

  function deletePickMethods(pickMethods: TPickMethod[]) {
    setDeletablePickMethods(pickMethods)
  }

  function createNewOrderStatues(status: TOrderStatus) {
    setNewOrderStatuses([...newOrderStatuses, status])
  }
  function deleteNewOrderStatuses(code: string) {
    const filteredNewStatuses = newOrderStatuses.filter((newStatuse) => newStatuse.code !== code)
    setNewOrderStatuses(filteredNewStatuses)
  }

  function createUpdatedOrderStatuses(status: TOrderStatus) {
    setUpdatedOrderStatuses([...updatedOrderStatuses, status])
  }

  function deleteOrderStatuses(statuses: TOrderStatus[]) {
    setDeletableOrderStatuses(statuses)
  }

  function createNewStatuses(status: TStatuses) {
    setNewStatuses([...newStatuses, status])
  }

  function deleteNewStatuses(code: string) {
    const filteredNewStatuses = newStatuses.filter((newStatuse) => newStatuse.code !== code)
    setNewStatuses(filteredNewStatuses)
  }

  function createUpdatedStatues(status: TStatuses) {
    setUpdatedStatuses([...updatedStatuses, status])
  }

  function deleteStatuses(statuses: ProductStatus[]) {
    setDeletableStatuses(statuses)
  }

  function createNewCategories(category: TCategory) {
    setNewCategories([...newCategories, category])
  }

  function deleteNewCategories(code: string) {
    const filteredNewCategories = newCategories.filter((newCategory) => newCategory.code !== code)
    setNewCategories(filteredNewCategories)
  }

  function createUpdatedCategories(category: TCategory) {
    setUpdatedCategories([...updatedCategories, category])
  }

  function deleteCategories(category: CategoryDefinition[]) {
    setDeletableCategories(category)
  }

  function clearStatuses() {
    setNewStatuses([])
    setUpdatedStatuses([])
    setDeletableStatuses([])
  }

  function clearCategories() {
    setNewCategories([])
    setUpdatedCategories([])
  }

  function clearOrderStatuses() {
    setUpdatedOrderStatuses([])
    setNewOrderStatuses([])
    setDeletableOrderStatuses([])
  }

  function clearPickMethods() {
    setUpdatedPickMethods([])
    setNewPickMethods([])
    setDeletablePickMethods([])
  }

  function clearConfirmMethods() {
    setUpdatedConfirmMethods([])
    setNewConfirmMethods([])
    setDeletableConfirmMethods([])
  }

  return (
    <StockOwnerContext.Provider
      value={{
        tabIndex,
        updateTabIndex,
        newStatuses,
        createNewStatuses,
        deleteNewStatuses,
        updatedStatuses,
        createUpdatedStatues,
        deletableStatuses,
        deleteStatuses,
        updatedCategories,
        createUpdatedCategories,
        newCategories,
        deleteNewCategories,
        createNewCategories,
        deletableCategories,
        deleteCategories,
        newOrderStatuses,
        createNewOrderStatues,
        deleteNewOrderStatuses,
        updatedOrderStatuses,
        createUpdatedOrderStatuses,
        deletableOrderStatuses,
        deleteOrderStatuses,
        newLinkedStockOwners,
        createLinkedStockOwners,
        newPickMethods,
        createNewPickMethods,
        deleteNewPickMethods,
        updatedPickMethods,
        createUpdatedPickMethods,
        deletablePickMethods,
        deletePickMethods,
        newConfirmMethods,
        createNewConfirmMethods,
        deleteNewConfirmMethods,
        updatedConfirmMethods,
        createUpdatedConfirmMethods,
        deletableConfirmMethods,
        deleteConfirmMethods,
        clearStatuses,
        clearCategories,
        clearOrderStatuses,
        clearPickMethods,
        clearConfirmMethods
      }}
    >
      {children}
    </StockOwnerContext.Provider>
  )
}

export default StockOwnerProvider
