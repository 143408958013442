/**
 * @render react
 * @name Table container
 * @description renders the complete table component
 */
import { Flex } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { HeaderGroup } from 'react-table'
import { Card, TopSection } from '../..'
import ContextMenuInfo from '../components/contextMenuInfo'
import { MenuInfoType, useTableHook } from '../Table.Store'
import Footers from './footer'
import Headers from './headers'
import Rows from './rows'
import { StyledTable, TableHead } from './styles'
import TableHeader from './tableHeader'

/**
 * @render react
 * @name Table container
 * @description creates the table layout
 */
const Table: React.FC<{ Filter?: React.FC<{ fieldsActive?: boolean }> }> = ({ Filter }) => {
  const [{ tableHook, showCountModal, hasSideBar }] = useTableHook()
  const cardRef = useRef<HTMLElement>(null)
  const headerRef = React.useRef<HTMLDivElement>(null)

  return (
    <Card
      ref={cardRef}
      flexDirection="column"
      flex={1}
      maxWidth="100%"
      width="100%"
      height="100%"
      zIndex={0}
      floating={false}
    >
      {/* Header */}
      <TopSection p={0}>
        <TableHeader Filter={Filter} />
      </TopSection>
      {/* Header */}
      <StyledTable {...tableHook?.getTableProps()}>
        {showCountModal !== MenuInfoType.None && !hasSideBar && (
          <ContextMenuInfo cardRef={cardRef} />
        )}
        {/* Table Column Headers */}
        <TableHead>
          {tableHook?.headerGroups.map((headerGroup: HeaderGroup<any>) => (
            <Flex
              ref={headerRef}
              //@ts-expect-error - 'key' is specified more than once, so this usage will be overwritten
              key={headerGroup.id}
              flex={1}
              flexDirection="row"
              {...headerGroup.getHeaderGroupProps()}
            >
              <Headers headerGroup={headerGroup} />
            </Flex>
          ))}
        </TableHead>
        {/* Table Column Headers */}
        {/* Table Rows  */}
        <Rows headerRef={headerRef} />
        {/* Table Rows  */}
        <Footers />
      </StyledTable>
    </Card>
  )
}

export default Table
