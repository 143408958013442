import moment from 'moment'
import React, { FC, Suspense } from 'react'
import { Circle, Home } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router, Redirect as ReactRouterRedirect } from 'react-router-dom'
import { FillLoader, SideBar } from '../components'
import { P } from '../components/Typography'
import PageNotFound from '../containers/InfoPages/PageNotFound'
import { flattenMenus } from '../containers/UserSetup/MenuManager/Menu/Helpers/flattenMenus'
import { Maybe, MenuEntry, ServerPermission } from '../generated/graphql'
import { useAuthentication } from '../hooks'
import { useIsTranslationReady, useMacsTranslation } from '../hooks/translation'
import { theme } from '../theme'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'

interface RouteType extends RouteProps {
  component: any
  title: string
  name?: string
}

export const TranslatedBreadcrumb: React.FC<{ title: string }> = ({ title }) => {
  const translated = useMacsTranslation({
    text: title
  })
  return <React.Fragment>{translated}</React.Fragment>
}

const STATIC_NAV_ITEMS = [
  {
    to: '/',
    title: 'Home',
    icon: <Home size={20} color="white" className="sidebar-menu-icon" />,
    level: 1
  },
  {
    title: 'Examples',
    level: 1,
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    items: [
      {
        to: '/file-upload-example',
        title: 'File Upload Example',
        level: 2
      },
      {
        to: '/file-upload-example-rest',
        title: 'File Upload Example Rest',
        level: 2
      },
      {
        to: '/field-mapping-example',
        title: 'Field Mapping Example',
        level: 2
      }
    ]
  }
]
const DEFAULT_SCREENS = [
  {
    to: '/coming-soon',
    title: 'Coming Soon'
  },
  {
    to: '/permission-denied',
    title: 'Permission Denied'
  },
  {
    to: '/user-setup',
    title: 'User Setup'
  },
  {
    to: '/purchase-order',
    title: 'Purchase Order'
  }
]

const Redirect: FC<{ redirectFn: () => void }> = ({ redirectFn }) => {
  redirectFn()
  return null
}

type ConvertMenue = {
  title: string
  level: number
  items?: ConvertMenue[]
  to?: string
  icon?: JSX.Element
}

export const convertMenus = (menu?: Maybe<Maybe<MenuEntry>[]>): ConvertMenue[] | undefined => {
  if (menu && menu.length > 0) {
    return menu.flatMap((m) => {
      const title = `${m?.title}`
      const to = `/${m?.screen?.action}`
      const level = (m?.depth || 0) + 1
      const icon =
        level === 1 ? <Circle size={20} color="white" className="sidebar-menu-icon" /> : undefined

      if (m?.screen?.action) {
        return {
          title,
          to,
          level
        }
      } else {
        return {
          title,
          icon,
          items: convertMenus(m?.children),
          level
        }
      }
    })
  }
}

export const SideBarContainer: React.FC = ({ children }) => {
  const { cognitoUser } = useAuthentication()
  const convertedMenus = convertMenus(cognitoUser.user.menus) || []
  return (
    <SideBar
      mode="push"
      color="white"
      bg="gray.800"
      navItems={[...STATIC_NAV_ITEMS, ...convertedMenus]}
      hovercolor={theme.colors.gray[500]}
      accentcolor={theme.colors.gray[500]}
    >
      {children}
    </SideBar>
  )
}

const PrivateRoute = ({ component: Component, name, ...rest }: RouteType) => {
  const { authenticating, isAuthenticated, signInRedirect, cognitoUser } = useAuthentication()
  const isReady = useIsTranslationReady(name || 'translation')

  if (authenticating) {
    return <FillLoader bg="gray.50" />
  }
  const hasMenu = flattenMenus(cognitoUser.user.menus || []).find(
    (menu) => `/${menu.screen?.action}` === rest.path
  )
  const isStaticMenu = STATIC_NAV_ITEMS.find((menu) => menu.to === rest.path)
  const isDefaultMenu = DEFAULT_SCREENS.find((menu) => menu.to === rest.path)
  const isAdminOrSuper =
    cognitoUser.user.accessLevel === ServerPermission.Administrator ||
    cognitoUser.user.accessLevel === ServerPermission.SuperUser
  if (isAuthenticated) {
    if (!hasMenu && !isStaticMenu && !isDefaultMenu && !isAdminOrSuper) {
      return <ReactRouterRedirect to="/permission-denied" />
    }
    return (
      <Route {...rest}>
        <SideBarContainer>
          <React.Suspense fallback={<FillLoader bg="gray.50" />}>
            {!isReady && <FillLoader bg="gray.50" />}
            {isReady && <Component />}
          </React.Suspense>
        </SideBarContainer>
      </Route>
    )
  }

  return <Redirect redirectFn={signInRedirect} />
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
    <P textAlign="center" width="100%" paddingY={2} fontSize="sm" marginTop="-48px">
      Copyright &copy; {moment().year()} MACS Software
    </P>
  </Route>
)

const Navigation = () => {
  const { ready } = useTranslation('translation', { useSuspense: false })
  return (
    <Router>
      <Suspense fallback={<FillLoader />}>
        <Switch>
          {PUBLIC_ROUTES.map((route) => {
            return <PublicRoute key={route.path} {...route} />
          })}
          {PRIVATE_ROUTES.map((route) => {
            return <PrivateRoute key={route.path} {...route} />
          })}
          <SideBarContainer>
            <React.Suspense fallback={<FillLoader bg="gray.50" />}>
              {!ready && <FillLoader bg="gray.50" />}
              {ready && <PageNotFound />}
            </React.Suspense>
          </SideBarContainer>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Navigation
