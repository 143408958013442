import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  InputRightElement,
  Text
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ErrorMessage, FieldArrayRenderProps, FieldInputProps, FieldProps, useField } from 'formik'
import * as React from 'react'
import { isFirefox } from 'react-device-detect'
import { Search, X } from 'react-feather'
import { IMaskInput } from 'react-imask'
import { useMacsTranslation, useMacsTranslationFunction } from '../../../hooks/translation'
import { theme } from '../../../theme'
import RemoveField from '../removeField'
import { Label, LabelProps } from '../styles'

const StyledInput = styled(Input)<InputProps>`
  background: ${theme.colors.background.surface};
  border: ${theme.colors.border.default};
  margin-bottom: 0;
  max-width: ${(props) => props.maxWidth};
`
const MaskedInput = styled(IMaskInput, {
  shouldForwardProp: (prop) => {
    return prop === 'maxWidth' || prop === 'useIconButton' || prop === 'hideLabel' ? false : true
  }
})<InputProps>`
  background: ${theme.colors.background.surface};
  border: ${(props) => props.border};
  margin-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;

  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  font-size: 1rem;
  height: 2.5rem;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  max-height: 35px;
  max-width: ${(props) => props.maxWidth};
  &:focus {
    z-index: 1;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }
  border-right: ${(props) => props.useIconButton && 0};
  border-top-right-radius: ${(props) => props.useIconButton && 0};
  border-bottom-right-radius: ${(props) => props.useIconButton && 0};

  &:disabled {
    opacity: 0.4;
  }
`

export type ConnectedFormGroupProps = LabelProps &
  FieldProps &
  InputProps & {
    label?: string
    labelMinWidth?: string
    labelMarginRight?: string
    updateOnBlur?: string
    inputLeftAddonText?: string
    inputLeftBackground?: string
    inputRightAddonText?: string
    mask?: any
    prepare?: Function
    noBottomMargin?: boolean
    isRequired?: boolean
    disabled?: boolean
    arrayHelpers?: FieldArrayRenderProps
    isDefault?: boolean
    index?: number
    onClear?: React.ReactNode
    onRemove?: Function
    useIconButton?: boolean
    hideLeftAddon?: boolean
    searchInput?: () => void
  }

const ConnectedFormLabel: React.FC<{
  field: FieldInputProps<any>
  labelMinWidth: any
  labelMarginRight: any
  label: string
  arrayHelpers?: FieldArrayRenderProps
  index?: number
  isDefault?: boolean
  onRemove?: Function
}> = React.memo(
  ({
    field,
    labelMinWidth,
    labelMarginRight,
    label,
    arrayHelpers,
    index = 0,
    isDefault,
    onRemove
  }) => {
    const translated = useMacsTranslation({ text: label })
    return (
      <Flex>
        <Label htmlFor={field.name} minWidth={labelMinWidth} marginRight={labelMarginRight}>
          {translated}
        </Label>
        {arrayHelpers && !isDefault && (
          <RemoveField
            field={field}
            arrayHelpers={arrayHelpers}
            index={index}
            onRemove={onRemove}
          />
        )}
      </Flex>
    )
  }
)

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  type,
  label,
  field,
  placeholder,
  flexDirection,
  labelMinWidth,
  labelMarginRight,
  updateOnBlur,
  form,
  defaultValue,
  inputLeftAddonText,
  inputLeftBackground,
  inputRightAddonText,
  mask,
  prepare,
  noBottomMargin,
  isRequired,
  disabled,
  arrayHelpers,
  index,
  isDefault,
  onClear,
  onRemove,
  useIconButton,
  hideLeftAddon = false,
  searchInput,
  ...rest
}) => {
  const translated = useMacsTranslationFunction()
  const [, meta] = useField(field.name)
  const renderInput = () => {
    if (mask) {
      //@ts-ignore - Property 'initialValue' does not exist
      const { isDisabled, maxWidth, initialValue, paddingX, ...maskedRest } = rest
      return (
        <MaskedInput
          useIconButton={useIconButton}
          disabled={rest.isDisabled}
          type={type}
          id={field.name}
          name={field.name}
          placeholder={translated(placeholder || label || '')}
          mask={mask}
          prepare={prepare}
          onAccept={(value: string) => form.setFieldValue(field.name, value)}
          value={field.value}
          onBlur={(e: React.FocusEvent<any>) => field.onBlur(e)}
          {...maskedRest}
          maxWidth={isFirefox && inputRightAddonText ? 'calc(100% - 36px)' : 'auto'}
          border={
            meta.error !== undefined && meta.touched
              ? theme.colors.border.error
              : theme.colors.border.default
          }
        />
      )
    }

    /* 
      NOTE: 
      updateOnBlur is needed as field and rest cause the input to not respond when onChange and onBlur are provided
      Formik provides FastField which does so this is an optimization to improve typing speed on larege forms
    */
    if (updateOnBlur) {
      return (
        <StyledInput
          type={type}
          id={field.name}
          placeholder={translated(placeholder || label || '')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
          onBlur={({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
            form.setFieldValue(field.name, currentTarget.value)
          }}
          defaultValue={field.value || ''}
          paddingX="12px"
        />
      )
    }
    //@ts-ignore - Property 'initialValue' does not exist
    const { advancedSearch, initialValue, filter, hideLabel, ...filteredRest } = rest
    return (
      <StyledInput
        {...field}
        type={type}
        id={field.name}
        placeholder={translated(placeholder || label || '')}
        paddingX="12px"
        value={field.value || ''}
        {...filteredRest}
        borderBottomRightRadius={searchInput ? 0 : filteredRest.borderRadius}
        borderTopRightRadius={searchInput ? 0 : filteredRest.borderRadius}
        disabled={filteredRest.isDisabled}
        maxWidth={isFirefox && inputRightAddonText ? 'calc(100% - 36px)' : 'auto'}
      />
    )
  }
  return (
    <Flex
      flexDirection={flexDirection || 'column'}
      width="100%"
      mr={rest.mr}
      ml={rest.ml}
      mt={rest.mt}
      mb={rest.mb}
    >
      {label && (
        <ConnectedFormLabel
          field={field}
          labelMinWidth={labelMinWidth}
          labelMarginRight={labelMarginRight}
          label={label}
          arrayHelpers={arrayHelpers}
          index={index}
          isDefault={isDefault}
          onRemove={onRemove}
        />
      )}
      <InputGroup>
        {!hideLeftAddon && (
          <InputLeftAddon
            background="transparent"
            children={
              <React.Fragment>
                {isRequired && !disabled && (
                  <Box
                    width="1px"
                    height="100%"
                    background={theme.colors.background.required}
                  ></Box>
                )}
                {!isRequired && !disabled && (
                  <Box width="1px" height="100%" background="transparent"></Box>
                )}
              </React.Fragment>
            }
            bg="white"
            border="none"
            padding="4px"
          />
        )}

        {inputLeftAddonText && (
          <InputLeftAddon
            paddingX={1}
            children={inputLeftAddonText}
            background={inputLeftBackground}
            color={inputLeftBackground ? 'white' : 'black'}
          />
        )}

        {renderInput()}
        {inputRightAddonText && (
          <InputRightAddon paddingX={1} children={translated(inputRightAddonText)} />
        )}
        {onClear && (
          <InputRightAddon paddingX={0} height={rest.height} bg="white" children={onClear} />
        )}
        {searchInput && (
          <InputRightElement
            marginTop={rest.marginTop}
            width="20px"
            right="40px"
            children={
              <IconButton
                minWidth={0}
                display="flex"
                justifyContent="end"
                alignItems="center"
                color="import.remove"
                variant="unstyled"
                aria-label="clear search"
                onClick={() => {
                  form.setFieldValue(field.name, '')
                }}
                icon={<X width="18px" height="18px" />}
              />
            }
          />
        )}
        {searchInput && (
          <InputRightAddon
            marginTop={rest.marginTop}
            padding={0}
            background="background.surface"
            borderColor="border.base"
            children={
              <IconButton
                display="flex"
                variant="unstyled"
                aria-label="search"
                onClick={() => {
                  form.setFieldValue(field.name, '')
                  searchInput()
                }}
                icon={<Search width="14px" />}
              />
            }
          />
        )}
      </InputGroup>
      <ErrorMessage name={field.name}>
        {(msg) => (
          <Text mt="4px" ml="8px" color="intent.error" data-testid={`error-${field.name}`}>
            {translated(msg)}
          </Text>
        )}
      </ErrorMessage>
    </Flex>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 1,
  type: 'text'
}
