import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react'
import React from 'react'
import { useMacsTranslation } from '../../hooks/translation'

export type ButtonProps = ChakraButtonProps & {
  text: string
}

const Button: React.FC<ButtonProps> = ({ isDisabled, bg, color, onClick, text, ...rest }) => {
  const translated = useMacsTranslation({ text: text })

  return (
    <ChakraButton isDisabled={isDisabled} bg={bg} color={color} onClick={onClick} {...rest}>
      {translated}
    </ChakraButton>
  )
}

Button.defaultProps = {
  bg: 'white',
  paddingLeft: 2,
  paddingRight: 2
}

export default Button
