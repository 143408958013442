import * as React from 'react'

export type TableContextType = {
  endCursor: string | null
  createEndCursor: (cursor: string | null) => void
  hasMore: boolean
  createHasMore: (hasMore: boolean) => void
  isNextPageLoading: boolean
  createIsNextPageLoaded: (loaded: boolean) => void
  toggleSelection: boolean
  createToggleSelection: (toggle: boolean) => void
  data: any
  items: any
  createItems: (items: any) => void
  clearAll: () => void
  totalItemsCount: number
  createTotalItemsCount: (totalCount: number) => void
}

export const TableContext = React.createContext<TableContextType>({
  endCursor: null,
  createEndCursor: () => null,
  hasMore: false,
  createHasMore: () => null,
  isNextPageLoading: false,
  createIsNextPageLoaded: () => null,
  toggleSelection: false,
  createToggleSelection: () => null,
  data: [],
  items: [],
  createItems: () => null,
  clearAll: () => null,
  totalItemsCount: 0,
  createTotalItemsCount: () => null
})

export const useTableContext = () => React.useContext(TableContext)

const TableProvider: React.FC<{}> = ({ children }) => {
  const [totalItemsCount, setTotalItemsCount] = React.useState<any>(false)
  const [endCursor, setEndCursor] = React.useState<string | null>(null)
  const [items, setItems] = React.useState<any>([])
  const [hasMore, setHasMore] = React.useState<boolean>(true)
  const [isNextPageLoading, setIsNextPageLoading] = React.useState<boolean>(false)
  const [toggleSelection, setToggleSelection] = React.useState<any>(false)

  const data = React.useMemo(() => items, [items])

  function createEndCursor(cursor: string | null) {
    setEndCursor(cursor)
  }
  function createHasMore(more: boolean) {
    setHasMore(more)
  }
  function createIsNextPageLoaded(next: boolean) {
    setIsNextPageLoading(next)
  }
  function createToggleSelection(toggle: boolean) {
    setToggleSelection(toggle)
  }
  function createItems(items: any) {
    setItems(items)
  }
  function createTotalItemsCount(totalCount: number) {
    setTotalItemsCount(totalCount)
  }

  function clearAll() {
    setEndCursor(null)
    setItems([])
    setHasMore(true)
    createTotalItemsCount(0)
  }
  return (
    <TableContext.Provider
      value={{
        endCursor,
        hasMore,
        isNextPageLoading,
        toggleSelection,
        data,
        items,
        totalItemsCount,
        createEndCursor,
        createHasMore,
        createIsNextPageLoaded,
        createToggleSelection,
        createItems,
        clearAll,
        createTotalItemsCount
      }}
    >
      {children}
    </TableContext.Provider>
  )
}

export default TableProvider
