import { Box, Flex, FlexProps, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ErrorMessage, FieldArrayRenderProps, FieldInputProps, FieldProps } from 'formik'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import Select, { Props as SelectProps } from 'react-select'
import Creatable from 'react-select/creatable'
import { Option } from 'react-select/src/filters'
import { get } from 'styled-system'
import { useMacsTranslation } from '../../../hooks/translation'
import { theme } from '../../../theme'
import { Text } from '../../Typography'
import RemoveField from '../removeField'
import { Label, LabelProps } from '../styles'

export type SelectObject = { label: string; value: string | boolean | number }

export type ConnectedFormSelectProps = LabelProps &
  FieldProps &
  FlexProps &
  SelectProps & {
    options: SelectObject[]
    name: string
    label?: string
    color?: string
    placeholder?: string
    disabled?: boolean
    isMulti?: boolean
    isLoading?: boolean
    onSelect?: (value: any, option: SelectObject) => void
    hideArrow?: boolean
    openMenuOnClick?: boolean
    onKeyDown?: any
    hideLabelOverflow?: boolean
    allowSelectAll?: boolean
    isRequired?: boolean
    arrayHelpers?: FieldArrayRenderProps
    index?: number
    isDefault?: boolean
    hideLabel?: boolean
  }

export const StyledSelect = styled(Select)`
  min-width: ${(props) => props.minWidth || 'auto'};
  flex: 1;
  & .react-select__placeholder {
    white-space: nowrap;
  }
  & .react-select__menu {
    margin-top: 0;
    z-index: 2;
  }
  & .react-select__control {
    min-height: ${theme.inputHeight};
  }
  & .react-select__menu .react-select__menu-list .react-select__option--is-selected {
    background-color: ${get('colors.background.500', '#EEE')};
  }
  & .react-select__control--is-focused {
    border: ${theme.colors.border.default};
    box-shadow: none;
  }
  & .react-select__control:hover {
    border: ${theme.colors.border.default};
    box-shadow: none;
  }

  & .react-select__indicator {
    padding: 6px 8px;
  }

  &.react-select--is-disabled {
    opacity: 0.5;
  }
`
export const StyledSelectCreatable = styled(Creatable)`
  min-width: ${(props) => props.minWidth || 'auto'};
  flex: 1;
  & .react-select__placeholder {
    white-space: nowrap;
  }
  & .react-select__menu {
    margin-top: 0;
    z-index: 2;
  }
  & .react-select__control {
    min-height: ${theme.inputHeight};
  }
  & .react-select__menu .react-select__menu-list .react-select__option--is-selected {
    background-color: ${get('colors.background.500', '#EEE')};
  }
  & .react-select__control--is-focused {
    border: ${theme.colors.border.default};
    box-shadow: none;
  }
  & .react-select__control:hover {
    border: ${theme.colors.border.default};
    box-shadow: none;
  }

  & .react-select__indicator {
    padding: 6px 8px;
  }

  &.react-select--is-disabled {
    opacity: 0.5;
  }
`
const ConnectedSelectLabel: React.FC<{
  field: FieldInputProps<any>
  label: string
  hideLabelOverflow?: boolean
  arrayHelpers?: FieldArrayRenderProps
  index?: number
  isDefault?: boolean
}> = React.memo(
  ({ field, label, arrayHelpers, index = 0, isDefault }) => {
    const translated = useMacsTranslation({ text: label })
    return (
      <Flex minHeight={label === 'no_label' ? '25px' : 'auto'}>
        {label && label !== 'no_label' && <Label htmlFor={field.name}>{translated}</Label>}
        {arrayHelpers && !isDefault && (
          <RemoveField field={field} arrayHelpers={arrayHelpers} index={index} />
        )}
      </Flex>
    )
  },
  () => true
)

const ConnectedFormSelect: React.FC<ConnectedFormSelectProps> = ({
  field,
  label,
  disabled,
  options,
  isMulti,
  form,
  isLoading,
  menuPlacement = 'auto',
  onSelect,
  noOptionsMessage,
  placeholder,
  onInputChange,
  menuIsOpen,
  hideArrow,
  openMenuOnClick,
  inputValue,
  onKeyDown,
  value,
  closeMenuOnSelect = true,
  customStyles,
  onMenuOpen,
  menuPortalTarget,
  defaultValue,
  hideLabelOverflow,
  isClearable = true,
  allOption,
  allowSelectAll,
  isRequired,
  arrayHelpers,
  index,
  maxMenuHeight,
  isDefault,
  hideLabel,
  ...rest
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 61.875em)' })
  const selectOptions = allowSelectAll ? [{ label: allOption, value: '*' }, ...options] : options
  const defualtStyle = {
    option: (provided: object) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    menu: (styles: any) => ({
      ...styles,
      width: isTabletOrMobile ? '100%' : 'auto',
      minWidth: '100%'
    }),
    dropdownIndicator: (provided: any, state: { selectProps: { menuIsOpen: any } }) => ({
      ...provided,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'scaleY(-1)' : null
    })
  }
  return (
    <Flex {...rest}>
      {!hideLabel && (
        <ConnectedSelectLabel
          field={field}
          hideLabelOverflow={hideLabelOverflow}
          label={label || ''}
          arrayHelpers={arrayHelpers}
          index={index}
          isDefault={isDefault}
        />
      )}

      <InputGroup>
        <InputLeftAddon
          children={
            <React.Fragment>
              {isRequired && !disabled && (
                <Box width="1px" height="100%" background={theme.colors.background.required}></Box>
              )}
              {!isRequired && !disabled && <Box width="1px" height="100%"></Box>}
            </React.Fragment>
          }
          bg="white"
          border="none"
          padding="4px"
          opacity={!isRequired && !disabled ? 0 : 'inherit'}
        />
        <StyledSelect
          //@ts-ignore -  Type 'false' is not assignable to type 'Partial<SelectComponents<OptionTypeBase>> | undefined'
          components={
            hideArrow && { DropdownIndicator: () => null, IndicatorSeparator: () => null }
          }
          inputId={field.name}
          classNamePrefix="react-select"
          isDisabled={disabled}
          name={field.name}
          options={selectOptions}
          isMulti={isMulti}
          isLoading={isLoading}
          onBlur={field.onBlur}
          //@ts-ignore - SelectObject is not the expected type
          onChange={(option: SelectObject) => {
            if (onSelect) {
              onSelect(option?.value, option)
            }
            form.setFieldValue(field.name, option)
          }}
          defaultValue={defaultValue}
          value={value || field.value}
          noOptionsMessage={noOptionsMessage}
          placeholder={placeholder || ''}
          menuPlacement={menuPlacement}
          filterOption={(option: Option, rawInput: string) =>
            rawInput
              .split(' ')
              .reduce(
                (acc: boolean, cur) =>
                  acc && option.label.toLowerCase().includes(cur.toLowerCase()),
                true
              )
          }
          menuIsOpen={menuIsOpen}
          onInputChange={onInputChange}
          openMenuOnClick={openMenuOnClick}
          inputValue={inputValue}
          onKeyDown={onKeyDown}
          closeMenuOnSelect={closeMenuOnSelect}
          styles={{ ...defualtStyle, ...customStyles }}
          onMenuOpen={onMenuOpen}
          isClearable={isClearable}
          menuPortalTarget={menuPortalTarget}
          maxMenuHeight={maxMenuHeight}
        />
      </InputGroup>
      <ErrorMessage name={field.name}>
        {(msg) => {
          return (
            <Text mt="4px" ml="8px" color="intent.error">
              {msg}
            </Text>
          )
        }}
      </ErrorMessage>
    </Flex>
  )
}

export default ConnectedFormSelect

ConnectedFormSelect.defaultProps = {
  options: [],
  mb: 1,
  flex: 1,
  minWidth: '150px',
  flexDirection: 'column',
  customStyles: {}
}
