import { Flex, Radio, RadioGroup } from '@chakra-ui/react'
import { FieldProps } from 'formik'
import React from 'react'
import { useMacsTranslation, useMacsTranslationRadioGroup } from '../../../hooks/translation'
import Tooltip from '../../Tooltip'
import { Label, LabelProps } from '../styles'

export type ConnectedRadioGroupProps = LabelProps &
  FieldProps & {
    label: string
    defualtValue: string
    radio: {
      label: string
      value: string
      tooltip?: string
    }[]
  }

const ConnectedRadioGroup: React.FC<ConnectedRadioGroupProps> = ({ form, field, label, radio }) => {
  const translation = useMacsTranslation({ text: label })
  const radioTranslation = useMacsTranslationRadioGroup({ radio })

  return (
    <Flex flexDirection="column" justifyContent="center">
      <Label mb={2}>{translation}</Label>
      <RadioGroup
        spacing={4}
        value={field.value}
        onChange={(value) => {
          form.setFieldValue(field.name, value)
        }}
      >
        {radioTranslation.map((radio, index) => {
          return (
            <Radio key={index} value={radio.value} marginX={1}>
              {radio.tooltip && (
                <Tooltip label={radio.tooltip} shouldWrapChildren placement="bottom">
                  {radio.label}
                </Tooltip>
              )}
              {!radio.tooltip && radio.label}
            </Radio>
          )
        })}
      </RadioGroup>
    </Flex>
  )
}

export default ConnectedRadioGroup
