import { Accordion, ExpandedIndex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { ModalWrap } from '../../../components'
import CopyMenu from './copyMenu'
import DisplayMenu from './displayMenu'
import ImportMenu from './importMenu'

type MenuManagerProps = {
  isOpen: boolean
  onClose?: () => void
  isManager?: boolean
}

/**
 * @render react
 * @name MenuManager container
 * @description Manage Menu of a user
 */

const MenuManager: React.FC<MenuManagerProps> = ({ onClose, isOpen, isManager = true }) => {
  const [accordionIndex, setAccordionIndex] = useState<ExpandedIndex>()

  return (
    <ModalWrap
      size="6xl"
      title={`Manage Menu Display for`}
      closeOnOverlayClick={false}
      onClose={() => {
        setAccordionIndex(undefined)
        onClose && onClose()
      }}
      isOpen={isOpen}
      overflow="scroll"
    >
      <Accordion
        allowMultiple
        index={accordionIndex}
        defaultIndex={isManager ? 1 : 0}
        onChange={(index) => {
          setAccordionIndex(index)
        }}
      >
        {/* Import Menu */}
        {isManager && <ImportMenu />}
        {/* Import Menu */}
        {/* Display Menu */}
        <DisplayMenu onModalClose={onClose} isManager={isManager} />
        {/* Display Menu */}
        {/* Copy Menu */}
        {isManager && <CopyMenu />}
        {/* Copy Menu */}
      </Accordion>
    </ModalWrap>
  )
}

export default MenuManager
