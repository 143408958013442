import { MenuList } from '..'

/**
 * @name toggleItem
 * @description on switch functionality for a screen
 * @param sourceList MenuList Array
 * @param menuIndex number Array
 * @returns MenuList Array
 */
export const toggleItem = (sourceList: MenuList[], menuIndex: number[]) => {
  const tempList = [...sourceList]
  const _blockIndex = [...menuIndex]
  const lastIndex = _blockIndex.pop() || 0
  const lastArr = _blockIndex.reduce((arr, i) => arr[i].children || [], tempList)
  if (!lastArr[lastIndex].defaultOnly) {
    lastArr[lastIndex].isUpdated = true
  }
  lastArr[lastIndex].enabled = !lastArr[lastIndex].enabled
  return tempList
}
