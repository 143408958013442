import { BoxProps, Flex, FlexProps } from '@chakra-ui/react'
import * as React from 'react'
import { useMacsTranslation } from '../../../hooks/translation'
import { P } from '../../Typography'

type CardHeaderProps = FlexProps &
  BoxProps & {
    title?: string
    ref?: ((instance: any) => void) | React.MutableRefObject<any> | null
  }

const CardHeader: React.FC<CardHeaderProps> = React.forwardRef<any, CardHeaderProps>(
  ({ children, title = 'Section Title', ...rest }, ref) => {
    if (children) {
      return (
        <Flex {...rest} ref={ref}>
          {children}
        </Flex>
      )
    }
    const translated = useMacsTranslation({ text: title })
    return (
      <Flex {...rest} p={0} ref={ref}>
        <P fontSize="16px" mb={2}>
          {translated}
        </P>
      </Flex>
    )
  }
)

export default CardHeader

CardHeader.defaultProps = {
  alignItems: 'flex-start',
  p: 2,
  roundedTopLeft: 4,
  roundedTopRight: 4,
  flexDirection: 'column'
}
