import _ from 'lodash'
import { arrayToTree } from 'performant-array-to-tree'
import { MenuList } from '..'
import { MenuEntry } from '../../../../../generated/graphql'
import { flattenMenus } from './flattenMenus'

/**
 * @name userScreens
 * @description Flatten and return all screens filtering out the groups
 * @param userMenus MenuEntry Array
 * @returns MenuEntry Array
 */
const userScreens = (userMenus: MenuEntry[]) =>
  flattenMenus(userMenus)?.filter((userMenu) => userMenu?.screenId)
const userGroups = (userMenus: MenuEntry[]) =>
  flattenMenus(userMenus)?.filter((userMenu) => !userMenu?.screenId)

/**
 * @name defaultScreens
 * @description Flatten and return all default screens not in the userScreen array as well as flagging any groups that dont exist
 * @param userMenus MenuEntry Array
 * @returns MenuEntry Array
 */
const defaultScreens = (defaultMenus: MenuEntry[], userMenus: MenuEntry[]) => {
  return flattenMenus(defaultMenus)
    ?.filter(
      (defaultMenu) =>
        !userScreens(userMenus)?.find((user) => user?.screenId === defaultMenu?.screenId)
    )
    .filter((defaultMenu) => {
      return !userGroups(userMenus)?.find(
        (user) => user?.defaultGroupId === defaultMenu?.defaultGroupId
      )
    })
    .map((menu) => {
      const defaultParent = flattenMenus(userMenus).find(
        (userMenu) => userMenu.defaultGroupId === menu.parent?.defaultGroupId
      )
      return {
        ...menu,
        enabled: false,
        defaultOrder: menu.order,
        defaultParentId: defaultParent?.id || menu.parentId,
        parentId: defaultParent?.id || menu.parentId,
        defaultGroupOnly: !menu.parentId,
        defaultOnly: !!defaultParent
      }
    })
}

/**
 * @name unFlatUserMenus
 * @description Converts and mutates the flat user menu into its correct nested structure
 * @param userMenus MenuEntry Array
 * @returns MenuList Array
 */

const unFlatUserMenus = (
  userMenus: MenuEntry[],
  defaultMenus: MenuEntry[],
  isManager?: boolean
) => {
  let count = 0

  const flatMenu = isManager
    ? flattenMenus(userMenus)
    : flattenMenus(userMenus).filter((menu) => menu.enabled)

  return arrayToTree(
    flatMenu.map((userMenu) => {
      if (userMenu.screenId) {
        const defualtScreens = flattenMenus(defaultMenus)
          .filter((menu) => menu.screenId)
          .find((menu) => menu.screenId === userMenu.screenId)

        const defaultParent = flattenMenus(userMenus).find(
          (menu) => menu.defaultGroupId === defualtScreens?.parent?.defaultGroupId
        )

        return {
          ...userMenu,
          defaultOrder: defualtScreens?.order,
          defaultParentId: defaultParent?.id
        } as MenuList
      } else {
        const defaultGroupIds = flattenMenus(defaultMenus).map(
          (defaultMenu) => defaultMenu.defaultGroupId
        )
        return {
          ...userMenu,
          accordionIndex: ++count - 1,
          defaultOrder: userMenu.order,
          defaultGroupId: userMenu.defaultGroupId || `${userMenu.title} - ${userMenu.userId}`,
          removeOnReset: userMenu.defaultGroupId
            ? !defaultGroupIds.includes(userMenu.defaultGroupId)
            : true
        } as MenuList
      }
    }),
    {
      dataField: null
    }
  ) as MenuList[]
}

/**
 * @name unFlatDefaultMenus
 * @description Converts the flat defualt menu into its correct nested structure
 * @param userMenus MenuEntry Array
 * @returns MenuList Array
 */
const unFlatDefaultMenus = (defaultMenus: MenuEntry[], userMenus: MenuEntry[]) =>
  arrayToTree(defaultScreens(defaultMenus, userMenus) as MenuList[], {
    dataField: null
  }) as MenuList[]

/**
 * @name customizer
 * @description lodash mergeWith cistomizer function
 * @param objValue value returns any type from the first object
 * @param srcValue value returns any type from the second object
 * @param key the key of the value in the object
 * @returns any array or undefined
 */
const customizer = (objValue: any, srcValue: any, key: string) => {
  if (key === 'children') {
    if (_.isArray(objValue) && objValue.length > 0 && _.isArray(srcValue) && srcValue.length > 0) {
      const parentId = srcValue[0].parentId
      return objValue
        .map((value) => ({
          ...value,
          parentId,
          defaultOnly: true
        }))
        .concat(srcValue)
    }
  }
}

/**
 * @name menuMerger
 * @description Merges 2 arrays together using lodash
 * @param defaultMenus MenuEntry Array
 * @param userMenus MenuEntry Array
 * @returns MenuList Array
 */
export const menuMerger = (
  defaultMenus?: MenuEntry[],
  userMenus?: MenuEntry[],
  isManager?: boolean
) => {
  if (userMenus && defaultMenus) {
    const merged = _.mergeWith(
      _.keyBy(unFlatDefaultMenus(defaultMenus, userMenus), 'defaultGroupId'),
      _.keyBy(unFlatUserMenus(userMenus, defaultMenus, isManager), 'defaultGroupId'),
      customizer
    )

    const additionalDefaultScreens = defaultScreens(defaultMenus, userMenus).filter(
      (additionalDefaultScreen) =>
        !flattenMenus(_.values(merged)).find(
          (mergedMenu) => mergedMenu.id === additionalDefaultScreen.id
        )
    )
    const menu = [...flattenMenus(_.values(merged)), ...additionalDefaultScreens]
    const result = arrayToTree(menu as MenuList[], {
      dataField: null
    })

    return _.sortBy(result, 'order')
  }
  return []
}
