import { ButtonProps, Flex } from '@chakra-ui/react'
import React from 'react'
import { Button } from '../../components'
import { theme } from '../../theme'
import DropdownMenu, { DropdownItem, GroupedDropDownItems } from '../DropdownMenu'

type DropDownButtonProps = ButtonProps & {
  text: string
  onClick: () => void
  menuItems: DropdownItem[]
  groupedMenuItems?: GroupedDropDownItems[]
  portal?: boolean
}

const DropDownButton: React.FC<DropDownButtonProps> = ({
  onClick,
  text,
  menuItems,
  groupedMenuItems,
  bg,
  color,
  isDisabled,
  portal,
  ...rest
}) => {
  return (
    <Flex>
      <Button
        {...rest}
        isDisabled={isDisabled}
        bg={bg}
        color={color}
        variant="outline"
        text={text}
        border="1px solid"
        borderRadius="0.375rem"
        borderRight={0}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        onClick={onClick}
      />
      <DropdownMenu
        isDisabled={isDisabled}
        background={bg}
        color={`${color}`}
        border={theme.colors.border.dropDownButton}
        borderRadius="0.375rem"
        borderLeft={0}
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        menuItems={menuItems}
        groupedMenuItems={groupedMenuItems}
        paddingX={0}
        portal={portal}
      />
    </Flex>
  )
}

export default DropDownButton
