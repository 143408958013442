import { Action, createContainer, createHook, createStore } from 'react-sweet-state'
import { HeaderProps, Row, TableInstance } from 'react-table'
import { ColumnDefinition } from './hooks/orderHookServer'
import { TableHeaderProps } from './Table.Props'

type TableProps<D extends object> = TableInstance<D> & {
  toggleAllRowsSelected: (value?: boolean) => void
  totalCount?: number
  itemCount: number
  loadMoreItems: (startIndex: number, endIndex: number) => any
  isItemLoaded: (index: number) => boolean
  hasMore?: boolean
  isNextPageLoading?: boolean
  useSingleRowSelect?: boolean
  rowCount: number
  name: string
  tableHeaderProps?: TableHeaderProps<any>
  fieldsActive?: boolean
  itemSize?: number
  onRowDoubleClick?: (rows: Row<D>) => void
  isDisplayRow?: boolean
}
export enum MenuInfoType {
  None = 'None',
  Column = 'Column',
  Row = 'Row'
}

export type State = {
  tableHook: TableProps<any> | null
  showCountModal: MenuInfoType
  toggleFooter: boolean
  warning: {
    index?: number | null
    showWarning?: boolean
    disableRowSelect?: boolean
  }
  hasSideBar: boolean
  localState: ColumnDefinition<any>[]
  fetchConfig: boolean
  isOpen: boolean
  columnPresets: {
    id: number
    title: string
    onClick: () => void
  }[]
  headerProps: React.PropsWithChildren<HeaderProps<any>> | null
}
export type Actions = typeof actions

const initialState: State = {
  tableHook: null,
  showCountModal: MenuInfoType.None,
  toggleFooter: false,
  warning: { index: null, showWarning: false, disableRowSelect: false },
  hasSideBar: false,
  localState: [],
  fetchConfig: true,
  isOpen: false,
  columnPresets: [],
  headerProps: null
}

const actions = {
  setHeaderProps: (
    headerProps: React.PropsWithChildren<HeaderProps<any>> | null
  ): Action<State> => ({ setState }) => setState({ headerProps }),
  setIsOpen: (isOpen: boolean): Action<State> => ({ setState, getState }) =>
    setState({ isOpen: isOpen && getState().tableHook?.fieldsActive }),
  setFetchConfig: (fetchConfig: boolean): Action<State> => ({ setState }) =>
    setState({ fetchConfig }),
  setLocalState: (localState: ColumnDefinition<any>[]): Action<State> => ({ setState }) => {
    setState({
      localState
    })
  },
  setTableHook: (hook: TableProps<any>): Action<State> => ({ setState, getState }) => {
    setState({
      tableHook: {
        ...getState().tableHook,
        ...hook
      }
    })
  },
  setShowCount: (hook: MenuInfoType): Action<State> => ({ setState }) => {
    setState({
      showCountModal: hook
    })
  },
  setToggleFooter: (hook?: boolean): Action<State> => ({ setState, getState }) => {
    const toggle = hook !== undefined ? hook : !getState().toggleFooter
    setState({
      toggleFooter: toggle
    })
  },
  setDisableRowSelect: (disabled?: boolean): Action<State> => ({ setState, getState }) => {
    setState({
      warning: {
        ...getState().warning,
        disableRowSelect: disabled
      }
    })
  },
  setShowWarning: (warning?: boolean): Action<State> => ({ setState, getState }) => {
    setState({
      warning: {
        ...getState().warning,
        showWarning: warning
      }
    })
  },
  setIndex: (index: number): Action<State> => ({ setState, getState }) => {
    setState({
      warning: {
        ...getState().warning,
        index
      }
    })
  },
  setHasSidebar: (hasSideBar: boolean): Action<State> => ({ setState }) => {
    setState({
      hasSideBar
    })
  },
  setColumnPresets: (
    presets: { id: number; title: string; onClick: () => void }[]
  ): Action<State> => ({ setState }) => {
    setState({
      columnPresets: presets
    })
  }
}

const Store = createStore<State, Actions>({
  initialState,
  actions
})
const TableContainer = createContainer(Store)

export const useTableHook = createHook(Store)
export default TableContainer
