import { MenuList } from '../index'
import { flattenMenus } from './flattenMenus'

/**
 * @name copyMenu
 * @description creates a copy of the menu so you dont mutate the original
 * @param menu Menu List
 */
export const copyMenu = (menu: MenuList) => {
  return Object.assign({}, menu)
}

/**
 * @name onMenusSearch
 * @description
 * @param menu Menu List
 */
export const onMenusSearch = (menus: MenuList[], searchValue: string) => {
  const filterMenu = (menu: MenuList): boolean | number | undefined => {
    if (menu.screen?.defaultTitle?.includes(searchValue)) return true
    if (menu.children) {
      return (menu.children = menu.children.map(copyMenu).filter(filterMenu)).length
    }
  }
  const findMenus = menus.map(copyMenu).filter(filterMenu)

  const menuIds = flattenMenus(findMenus)
    .filter((menu) => !menu.screenId)
    .map((menu) => menu.id || 0)
  //Loop though each Id and if it is not open click it by finding the button inside and clicking that button
  menuIds.forEach((menuId) => {
    //timout is needed as each accordion needs to be open before the nest one can be clicked.
    setTimeout(() => {
      document.querySelectorAll<HTMLElement>('.my-accordion').forEach((item) => {
        if (item.getAttribute('aria-expanded') === 'true') {
          item.click()
        }
      })
      if (
        document.getElementById(`groupd-${menuId}`)!.children[0].getAttribute('aria-expanded') ===
        'false'
      ) {
        const menuElement = document.getElementById(`groupd-${menuId}`)!.children[0].id
        document.getElementById(menuElement)?.click()
      }
    }, 500)
  })

  //Find the searched screem
  const screen = flattenMenus(findMenus).find((foundScreen) => foundScreen.screenId)
  //Remove the class from all screens that may have it and the add it to the searched screen
  if (screen) {
    const elements = document.getElementsByClassName('search-result')
    while (elements.length > 0) {
      elements[0].classList.remove('search-result')
    }
    document.getElementById(`screen-${screen.screenId}`)!.className += ' search-result'
    //Wiat for all expands to occure before scrolling the screen into view
    setTimeout(() => document.getElementById(`screen-${screen.screenId}`)!.scrollIntoView(), 2000)
  }
}
