import { Flex, Heading, Image } from '@chakra-ui/react'
import React from 'react'
import images from '../../theme/images'

type FallbackErrorMessageProps = {
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}
const FallbackErrorMessage: React.FC<FallbackErrorMessageProps> = () => (
  <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center">
    <Image src={images.warning} width={350} height={350} />
    <Heading textAlign="center" size="md" mt={4} fontWeight="lighter">
      An error has occurred.
    </Heading>
  </Flex>
)

export default FallbackErrorMessage
