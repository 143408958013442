import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { IDENTITY_SERVER } from './constants'

//@ts-expect-error - overrideMimeType & load seem to be missing in the typing
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    saveMissing: true,
    keySeparator: false,
    backend: {
      loadPath: IDENTITY_SERVER.graphQLEndpoint + '/../locales/{{lng}}/{{ns}}.json',
      addPath: IDENTITY_SERVER.graphQLEndpoint + '/../locales/add/{{lng}}/{{ns}}',
      allowMultiLoading: false,
      crossDomain: true,
      overrideMimeType: true,
      load: 'all'
    },
    fallbackNS: 'translation',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  })

export default i18n
