import {
  Box,
  Button as ChakraButton,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Switch,
  Tooltip
} from '@chakra-ui/react'
import { isArray } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { MoreVertical, Plus, Square } from 'react-feather'
import { ReactSortable } from 'react-sortablejs'
import { HeaderProps, Hooks, IdType, Renderer } from 'react-table'
import { Button, Col, Container, DropdownMenu, Row, StyledInput } from '../..'
import { TableNames, useGlobalTableHook } from '../../../contexts/Stores/Global.Table.Store'
import {
  PresetColumnType,
  useCreatePresetMutation,
  useDeletePresetMutation,
  useUpdatePresetMutation
} from '../../../generated/graphql'
import { useClickedOutside } from '../../../hooks'
import usePersistState from '../../../hooks/persistState'
import DropDownButton from '../../DropDownButton'
import { useTableHook } from '../Table.Store'

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.2,
  ghostClass: 'ghost',
  handle: '.handle'
}

export type ColumnDefinition<D extends {}> = {
  id: IdType<D>
  title: Renderer<HeaderProps<D>> | undefined
  isVisible?: boolean
  order: number
}

export type ColumnManager<D extends {}> = {
  [key: string]: ColumnDefinition<D>[]
}

export type ColumnManagerWithPreset<D extends {}> = {
  [key: string]: {
    [key: string]: ColumnDefinition<D>[]
  }
}

/**
 * @name orderHook container
 * @description renders context menu on header and rows
 * @param hooks react table hooks
 */
export const orderHookServer = <D extends {}>(hooks: Hooks<D>, name: string) => {
  const tableName = name as TableNames
  const [state, { setTableCurrentPreset }] = useGlobalTableHook()
  const [updated, setUpdated] = useState(false)
  const [
    { isOpen, headerProps, fetchConfig },
    { setLocalState, setIsOpen, setColumnPresets, setFetchConfig }
  ] = useTableHook()
  const [currentColumnPreset, setCurrentColumnPreset] = usePersistState<{
    [key: string]: string
  }>('columnPreset', 'localStorage')
  const preset = currentColumnPreset[name] || 'default'
  const [createPreset] = useCreatePresetMutation({
    onQueryUpdated: (observableQuery, diff) => {
      if (diff.result.presetsForTable.nodes[0].table === tableName) {
        observableQuery.refetch()
        setUpdated(!updated)
        return true
      }
    },
    refetchQueries: ['presetsForTable']
  })
  const [updatePreset] = useUpdatePresetMutation({
    onQueryUpdated: (observableQuery, diff) => {
      if (diff.result.presetsForTable.nodes[0].table === tableName) {
        observableQuery.refetch()
        setUpdated(!updated)
        return true
      }
    },
    refetchQueries: ['presetsForTable']
  })
  const [deletePreset] = useDeletePresetMutation({
    onQueryUpdated: (observableQuery, diff) => {
      if (diff.result.presetsForTable.nodes[0].table === tableName) {
        observableQuery.refetch()
        setUpdated(!updated)
        return true
      }
    },
    refetchQueries: ['presetsForTable']
  })

  useEffect(() => {
    const serverSideSavedPresets =
      state.presets[tableName]?.presetsForTable?.nodes?.map((presetsForTable) => {
        if (presetsForTable?.__typename === 'TablePreset') {
          return {
            id: presetsForTable.id || 0,
            title: presetsForTable.title,
            onClick: () => {
              const presetColumns = presetsForTable.columns
                ?.map((presetColumn) => ({
                  id: presetColumn?.fieldName || '',
                  title: presetColumn?.displayName || '',
                  isVisible: Boolean(presetColumn?.isVisible),
                  order: presetColumn?.order || 0
                }))
                .sort((columnOne, columnTwo) => {
                  if (columnOne?.order < columnTwo?.order) return -1
                  return columnOne?.order > columnTwo.order ? 1 : 0
                })
              if (!presetColumns) return
              setLocalState(presetColumns)
              setTableCurrentPreset(name, presetsForTable.title)
              setCurrentColumnPreset({ [name]: presetsForTable.title })
              setTimeout(() => {
                presetColumns.forEach((item) => {
                  headerProps?.toggleHideColumn(item.id, !item.isVisible)
                })
                headerProps?.setColumnOrder(presetColumns.map((presetColumn) => presetColumn.id))
              }, 0)
            }
          }
        }
        return {
          id: 0,
          title: 'Error',
          onClick: () => {}
        }
      }) || []
    setColumnPresets(serverSideSavedPresets)

    const foundPreset = state.presets[tableName]?.presetsForTable?.nodes?.find((presetForTable) => {
      if (presetForTable?.__typename === 'TablePreset') {
        return presetForTable?.title === preset
      }
    })

    if (foundPreset?.__typename === 'TablePreset' && fetchConfig) {
      const foundPresetColumns = foundPreset.columns?.map((foundPresetColumn) => ({
        id: foundPresetColumn?.fieldName || '',
        title: foundPresetColumn?.displayName || '',
        isVisible: Boolean(foundPresetColumn?.isVisible),
        order: foundPresetColumn?.order || 0
      }))

      if (!foundPresetColumns) return
      setTimeout(() => {
        foundPresetColumns.forEach((item) => {
          headerProps?.toggleHideColumn(item.id, !item.isVisible)
        })
        headerProps?.setColumnOrder(
          foundPresetColumns
            .sort((columnOne, columnTwo) => {
              if (columnOne?.order < columnTwo?.order) return -1
              return columnOne?.order > columnTwo.order ? 1 : 0
            })
            .map((presetColumn) => presetColumn.id)
        )
      }, 0)
      setLocalState(foundPresetColumns)
      setFetchConfig(false)
    }
  }, [state.presets, headerProps, updated])

  const wrapperRef = useRef<HTMLDivElement>(null)
  useClickedOutside(wrapperRef, { isOpen, setIsOpen })

  hooks.visibleColumns.push((columns) => [
    ...columns,
    // Let's make a column for selection
    {
      id: 'orderMenu',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: (props) => {
        const [currentColumnPreset, setCurrentColumnPreset] = usePersistState<{
          [key: string]: string
        }>('columnPreset', 'localStorage')
        const preset = currentColumnPreset[name] || 'default'
        const [showSaveAs, setShowSaveAs] = useState(false)
        const [saveAs, setSaveAs] = useState('')
        const isDefaultPreset = preset === 'default'
        const [
          { localState, isOpen, columnPresets },
          { setLocalState, setIsOpen, setHeaderProps }
        ] = useTableHook()
        useEffect(() => {
          setHeaderProps(props)
        }, [])

        return (
          <Popover isLazy isOpen={isOpen} closeOnBlur={false}>
            <PopoverTrigger>
              <ChakraButton
                minWidth={0}
                padding={0}
                background="transparent"
                onClick={() => {
                  setIsOpen(true)
                }}
              >
                <Flex alignItems="center" cursor="pointer">
                  <Flex flexDirection="column">
                    <Icon as={Square} fontSize="8px" />
                    <Icon as={Square} fontSize="8px" />
                    <Icon as={Square} fontSize="8px" />
                  </Flex>
                  <Icon as={Plus} fontSize="8px" />
                </Flex>
              </ChakraButton>
            </PopoverTrigger>
            <Portal>
              <PopoverContent ref={wrapperRef}>
                <PopoverArrow />
                <PopoverHeader>
                  <DropdownMenu
                    padding={1}
                    menuItems={columnPresets}
                    title={preset}
                    portal={false}
                  />
                </PopoverHeader>
                <PopoverCloseButton onClick={() => setIsOpen(false)} />
                <PopoverBody padding={0}>
                  {showSaveAs && (
                    <Flex padding={1}>
                      <StyledInput
                        margin={0}
                        placeholder="Save As"
                        autoFocus
                        value={saveAs}
                        onChange={(e) => setSaveAs(e.currentTarget.value)}
                      />
                    </Flex>
                  )}
                  {!showSaveAs && (
                    <ReactSortable
                      style={{ maxHeight: '250px', overflow: 'scroll' }}
                      list={localState}
                      setList={(newState) => {
                        const newLocalState = newState.map((ns, index) => ({ ...ns, order: index }))
                        setLocalState(newLocalState)
                      }}
                      {...sortableOptions}
                      onEnd={() => {
                        props.setColumnOrder(localState.map((d) => d.id))
                      }}
                    >
                      {isArray(localState) &&
                        localState.map((item, index) => (
                          <Flex
                            flex={1}
                            minHeight="35px"
                            alignItems="center"
                            key={item.id}
                            borderBottom={
                              index + 1 < localState.length ? '1px solid #E7E6E6' : 'none'
                            }
                          >
                            <Container fluid padding={1} paddingY={0}>
                              <Row middle="xs">
                                <Col xs={1}>
                                  <Flex flexWrap="nowrap">
                                    <Flex className="handle" cursor="move">
                                      <MoreVertical height="14px" />
                                      <Box marginLeft="-20px">
                                        <MoreVertical height="14px" />
                                      </Box>
                                    </Flex>
                                  </Flex>
                                </Col>
                                <Col xs>{item.title}</Col>
                                <Col xs={2}>
                                  <Tooltip
                                    hasArrow
                                    label="One header must be enabled"
                                    isDisabled={
                                      localState.filter((ls) => ls.isVisible).length > 1 ||
                                      !item.isVisible
                                    }
                                  >
                                    <Box>
                                      <Switch
                                        size="md"
                                        color="menuManager.switch"
                                        defaultChecked={item.isVisible}
                                        isChecked={item.isVisible}
                                        isDisabled={
                                          item.isVisible &&
                                          localState.filter((ls) => ls.isVisible).length === 1
                                        }
                                        onChange={() => {
                                          props.toggleHideColumn(item.id, item.isVisible)
                                          const changedState = localState.map((localState) => {
                                            if (localState.id === item.id) {
                                              return {
                                                ...localState,
                                                isVisible: !localState.isVisible
                                              }
                                            }
                                            return localState
                                          })
                                          setLocalState(changedState)
                                        }}
                                      />
                                    </Box>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Container>
                          </Flex>
                        ))}
                    </ReactSortable>
                  )}
                </PopoverBody>
                <PopoverFooter display="flex" justifyContent="center">
                  {showSaveAs && (
                    <Button
                      bg="primary.base"
                      color="text.onPrimary"
                      text="Save Preset"
                      onClick={() => {
                        setIsOpen(false)
                        setShowSaveAs(false)
                        setCurrentColumnPreset({ [name]: saveAs })
                        setTableCurrentPreset(name, saveAs)

                        createPreset({
                          variables: {
                            tableName: name,
                            preset: {
                              title: saveAs,
                              columns: localState.map((ls) => ({
                                fieldName: ls.id,
                                displayName: ls.title!.toString(),
                                order: ls.order,
                                isVisible: Boolean(ls.isVisible),
                                columnType: PresetColumnType.Normal
                              }))
                            }
                          }
                        })
                      }}
                    />
                  )}
                  {!showSaveAs && (
                    <DropDownButton
                      text={isDefaultPreset ? 'Save As' : 'Save'}
                      bg="primary.base"
                      color="text.onPrimary"
                      portal={false}
                      onClick={() => {
                        if (isDefaultPreset) {
                          setShowSaveAs(true)
                        } else {
                          setIsOpen(false)
                          const find = state.presets[tableName]?.presetsForTable?.nodes?.find(
                            (f) => {
                              if (f?.__typename === 'TablePreset') {
                                return f.title === preset
                              }
                            }
                          )
                          if (find?.__typename === 'TablePreset') {
                            updatePreset({
                              variables: {
                                preset: {
                                  id: find.id,
                                  title: find.title,
                                  table: tableName,
                                  columns: localState.map((ls) => ({
                                    id: find?.columns?.find((c) => c?.fieldName === ls.id)?.id,
                                    fieldName: ls.id,
                                    displayName: ls.title!.toString(),
                                    order: ls.order,
                                    isVisible: Boolean(ls.isVisible),
                                    columnType: PresetColumnType.Normal
                                  }))
                                }
                              }
                            })
                          }
                        }
                      }}
                      menuItems={[
                        {
                          title: 'Save As',
                          onClick: () => {
                            setShowSaveAs(true)
                          }
                        },
                        {
                          title: 'Rest',
                          onClick: () => {
                            const foundPreset = state.presets[
                              tableName
                            ]?.presetsForTable?.nodes?.find((f) => {
                              if (f?.__typename === 'TablePreset') {
                                return f.title === preset
                              }
                            })

                            if (foundPreset && foundPreset.__typename === 'TablePreset') {
                              const foundPresetColumns = foundPreset.columns?.map(
                                (foundPresetColumn) => ({
                                  id: foundPresetColumn?.fieldName || '',
                                  title: foundPresetColumn?.displayName || '',
                                  isVisible: Boolean(foundPresetColumn?.isVisible),
                                  order: foundPresetColumn?.order || 0
                                })
                              )

                              if (!foundPresetColumns) return
                              setTimeout(() => {
                                foundPresetColumns.forEach((item) => {
                                  headerProps?.toggleHideColumn(item.id, !item.isVisible)
                                })
                                headerProps?.setColumnOrder(
                                  foundPresetColumns
                                    .sort((columnOne, columnTwo) => {
                                      if (columnOne?.order < columnTwo?.order) return -1
                                      return columnOne?.order > columnTwo.order ? 1 : 0
                                    })
                                    .map((presetColumn) => presetColumn.id)
                                )
                              }, 0)
                              setLocalState(foundPresetColumns)
                            }
                          }
                        },
                        {
                          title: 'Delete',
                          onClick: () => {
                            const presetId = columnPresets.find((c) => c.title === preset)?.id
                            if (presetId) {
                              deletePreset({
                                variables: {
                                  id: presetId
                                }
                              })
                              const foundPreset = state.presets[
                                tableName
                              ]?.presetsForTable?.nodes?.find((f) => {
                                if (f?.__typename === 'TablePreset') {
                                  return f.title === 'default'
                                }
                              })

                              if (foundPreset && foundPreset.__typename === 'TablePreset') {
                                const foundPresetColumns = foundPreset.columns?.map(
                                  (foundPresetColumn) => ({
                                    id: foundPresetColumn?.fieldName || '',
                                    title: foundPresetColumn?.displayName || '',
                                    isVisible: Boolean(foundPresetColumn?.isVisible),
                                    order: foundPresetColumn?.order || 0
                                  })
                                )

                                if (!foundPresetColumns) return
                                setTimeout(() => {
                                  foundPresetColumns.forEach((item) => {
                                    headerProps?.toggleHideColumn(item.id, !item.isVisible)
                                  })
                                  headerProps?.setColumnOrder(
                                    foundPresetColumns
                                      .sort((columnOne, columnTwo) => {
                                        if (columnOne?.order < columnTwo?.order) return -1
                                        return columnOne?.order > columnTwo.order ? 1 : 0
                                      })
                                      .map((presetColumn) => presetColumn.id)
                                  )
                                }, 0)
                                setLocalState(foundPresetColumns)
                              }
                              setCurrentColumnPreset({ [name]: 'default' })
                              setTableCurrentPreset(name, 'default')
                            }
                          }
                        }
                      ]}
                    />
                  )}
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
        )
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to render a checkbox

      flex: 0,
      minWidth: 8,
      width: 8,
      position: 'sticky',
      right: 0
    }
  ])
}
