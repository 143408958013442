import { Flex, IconButton } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Search } from 'react-feather'
import { MenuList } from '.'
import { Col, Container, Row } from '../../../../components'
import { StyledSelect } from '../../../../components/FormElements/ConnectedSelect'
import { useAllMenusQuery } from '../../../../generated/graphql'
import { flattenMenus } from './Helpers/flattenMenus'
import { onMenusSearch } from './Helpers/searchMenus'

type SearchProps = {
  menus: MenuList[]
  isManager?: boolean
}

/**
 * @render react
 * @name SearchMenu container
 * @description Shows search button or select to filter out the menu list
 */
const SearchMenu: React.FC<SearchProps> = ({ menus, isManager }) => {
  const [search, setSearch] = useState(false)
  const { data: allMenusData } = useAllMenusQuery()
  const menuOptions = isManager
    ? allMenusData?.allMenus?.map((m) => ({
        label: `${m?.defaultTitle} | #${m?.screenCode}`,
        value: m?.defaultTitle,
        screenCode: m?.screenCode
      }))
    : flattenMenus(menus)
        .filter((menu) => menu.screenId)
        .filter((menu) => menu.enabled)
        .map((m) => ({
          label: `${m.screen?.defaultTitle} | #${m.screen?.screenCode}`,
          value: m.screen?.defaultTitle,
          screenCode: m.screen?.screenCode
        }))
  return (
    <Flex justifyContent="flex-end" mb={1} mr={1}>
      {!search && (
        <IconButton
          marginBottom={1}
          marginLeft="auto"
          height="auto"
          variant="unstyled"
          aria-label="search"
          icon={<Search />}
          onClick={() => {
            setSearch(true)
          }}
        />
      )}
      {search && (
        <Container fluid>
          <Row end="xs">
            <Col xs={3}>
              <StyledSelect
                filterOption={(option, input) => {
                  return input
                    .split(' ')
                    .reduce(
                      (acc: boolean, cur) =>
                        acc && option.label.toLowerCase().includes(cur.toLowerCase()),
                      true
                    )
                }}
                //@ts-ignore - SelectObject is not the expected type
                onChange={(option: SelectObject) => {
                  if (option && option.value) {
                    onMenusSearch(menus, option.value)
                  } else {
                    setSearch(false)
                  }
                }}
                styles={{
                  menu: (styles: any) => ({
                    ...styles,
                    width: 'auto',
                    minWidth: '100%'
                  }),
                  option: (provided: object) => ({
                    ...provided,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'left',
                    textOverflow: 'ellipsis'
                  })
                }}
                isClearable={true}
                options={menuOptions}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Flex>
  )
}

export default SearchMenu
