import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import usePersistState from '../hooks/persistState'
import { logger } from '../utils'

type LockType = Partial<{
  warehouse: boolean
  stockOwner: boolean
}>

type AppContextType = {
  collapseOpen: boolean
  toggleCollaps: (open: boolean) => void
  drawerOpen: boolean
  toggleDrawer: (open?: boolean) => void
  displayPageHeader: boolean
  showPageHeader: (show: boolean) => void
  displayPageButton: {
    show: boolean
    text: string | null
    onClick: () => void
  }
  createPageButton: (show: boolean, text: string, onClick: () => void) => void
  mutationResponse: object
  createMutationResponse: (response: object) => void
  resetTableData: boolean
  createResetTableData: (reset: boolean) => void
  pageName: { translation: string }
  createPageName: (name?: string) => Promise<string | null>
  lock: LockType
  createLocked: (options: LockType) => void
}

export const AppContext = React.createContext<AppContextType>({
  collapseOpen: false,
  toggleCollaps: () => null,
  drawerOpen: false,
  toggleDrawer: () => null,
  displayPageHeader: true,
  showPageHeader: () => null,
  displayPageButton: {
    show: false,
    text: null,
    onClick: () => null
  },
  createPageButton: () => null,
  mutationResponse: {},
  createMutationResponse: () => null,
  resetTableData: false,
  createResetTableData: () => null,
  pageName: {
    translation: 'home'
  },
  createPageName: async () => null,
  lock: {
    stockOwner: false,
    warehouse: false
  },
  createLocked: () => null
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 50em)' })

  const [lock, setLock] = React.useState<LockType>({ warehouse: false, stockOwner: false })
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [drawerOpen, setDrawerOpen] = React.useState(!isTabletOrMobile)
  const [displayPageHeader, setDisplayPageHeader] = React.useState(false)
  const [displayPageButton, setDisplayPageButton] = React.useState({
    show: false,
    text: 'Button',
    onClick: () => {
      logger('Defualt Click')
    }
  })
  const [mutationResponse, setMutationResponse] = React.useState({})
  const [resetTableData, setResetTableData] = React.useState(false)
  const [pageName, setPageName] = usePersistState<{ translation: string }>('translation')

  function createLocked(option: LockType) {
    setLock(option)
  }

  function toggleCollaps(open: boolean) {
    setCollapseOpen(!open)
  }

  function toggleDrawer(open?: boolean) {
    setDrawerOpen(open ? open : !drawerOpen)
  }

  function showPageHeader(show: boolean) {
    setDisplayPageHeader(show)
  }
  function createPageButton(show: boolean, text: string, onClick: () => void) {
    setDisplayPageButton({ show, text, onClick })
  }

  function createMutationResponse(response: object) {
    setMutationResponse(response)
  }

  function createResetTableData(reset: boolean) {
    setResetTableData(reset)
  }

  function createPageName(name?: string) {
    const n = name || 'translation'
    return new Promise<string>((resolve) => {
      setPageName({ translation: n })
      resolve(n)
    })
  }

  return (
    <AppContext.Provider
      value={{
        collapseOpen,
        toggleCollaps,
        drawerOpen,
        toggleDrawer,
        displayPageHeader,
        showPageHeader,
        displayPageButton,
        createPageButton,
        mutationResponse,
        createMutationResponse,
        resetTableData,
        createResetTableData,
        pageName,
        createPageName,
        lock,
        createLocked
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
