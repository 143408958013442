import {
  Box,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { arrayToTree } from 'performant-array-to-tree'
import React from 'react'
import { MenuList, TRemoveMenu } from '.'
import { UserSetupFormValues } from '../..'
import { Button } from '../../../../components'
import DropDownButton from '../../../../components/DropDownButton'
import { CurrentUserQuery, MenuEntry } from '../../../../generated/graphql'
import { useMacsTranslationFunction } from '../../../../hooks/translation'
import useAuthentication from '../../../../hooks/useAuthentication'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'
import { flattenMenus } from './Helpers/flattenMenus'
import { useSaveButton } from './Helpers/general'
import { menuMerger } from './Helpers/menuMerger'
import { resetMenu } from './Helpers/reset'
import { mutations } from './Helpers/save'

type CancelButtonProps = {
  menus: MenuList[]
  removeMenus: TRemoveMenu
  onModalClose?: () => void
  parentIds: number[]
}

type ResetButtonProps = {
  menus: MenuList[]
  setMenus: React.Dispatch<React.SetStateAction<MenuList[]>>
  removeMenus: TRemoveMenu
  setRemoveMenus: React.Dispatch<React.SetStateAction<TRemoveMenu>>
  parentIds: number[]
}

type SaveButtonProps = {
  defaultMenus: MenuEntry[]
  menus: MenuList[]
  setMenus: React.Dispatch<React.SetStateAction<MenuList[]>>
  removeMenus: TRemoveMenu
  setRemoveMenus: React.Dispatch<React.SetStateAction<TRemoveMenu>>
  onModalClose?: () => void
  parentIds: number[]
  setDefaultUpdated: React.Dispatch<
    React.SetStateAction<{
      screen: MenuList[]
      group: MenuList[]
    }>
  >
  isManager?: boolean
  currentUser?: CurrentUserQuery
}

export const CancelButton: React.FC<CancelButtonProps> = ({
  menus,
  removeMenus,
  parentIds,
  onModalClose
}) => {
  const { values } = useFormikContext<UserSetupFormValues>()
  const { updateMenuLoading, updateUserMutation } = useSaveButton()

  const translate = useMacsTranslationFunction()
  const {
    onOpen: cancelPopOverOnOpen,
    onClose: cancelPopOverOnClose,
    isOpen: cancelPopOverIsOpen
  } = useDisclosure()

  const update = mutations(menus, parentIds).update
  const create = mutations(menus, parentIds).create
  const removeScreen = mutations(menus, parentIds).removeScreen
  const createDefaultScreen = mutations(menus, parentIds).createDefaultScreen
  const creatDefaultGroup = mutations(menus, parentIds).creatDefaultGroup

  return (
    <Popover
      isOpen={cancelPopOverIsOpen}
      onOpen={cancelPopOverOnOpen}
      onClose={cancelPopOverOnClose}
    >
      <PopoverTrigger>
        <Box>
          <Button
            variant="outline"
            isDisabled={
              flattenMenus(menus).filter((menu) => menu.isNew || menu.isUpdated).length === 0 &&
              removeMenus.length === 0
            }
            text="Cancel"
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader display="flex" justifyContent="center">
          {translate('Are you sure')}
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody display="flex" paddingX={1} justifyContent="center">
          <ButtonGroup spacing={1} justifyContent="center">
            <Button
              bg="intent.error"
              color="text.onPrimary"
              text="Close"
              onClick={() => onModalClose && onModalClose()}
            />
            <Button
              bg="primary.base"
              color="text.onPrimary"
              text="Save and Close"
              isLoading={updateMenuLoading}
              onClick={() => {
                updateUserMutation({
                  variables: {
                    id: values.id,
                    input: {
                      userMenus: {
                        ...values.userMenus,
                        update: update,
                        create: [...create, ...createDefaultScreen, ...creatDefaultGroup],
                        delete: [...removeScreen, ...removeMenus]
                      }
                    }
                  }
                }).then((data) => {
                  if (data.data?.updateUser?.__typename === 'WMSUser') {
                    onModalClose && onModalClose()
                    cancelPopOverOnClose()
                  }
                })
              }}
            />
          </ButtonGroup>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export const ResetButton: React.FC<ResetButtonProps> = ({ menus, setMenus, setRemoveMenus }) => {
  return (
    <DropDownButton
      text="Reset"
      onClick={() => resetMenu(menus, setMenus, setRemoveMenus, 'All')}
      menuItems={[
        {
          title: 'Reset All',
          onClick: () => resetMenu(menus, setMenus, setRemoveMenus, 'All')
        },
        {
          title: 'Reset Title',
          onClick: () => resetMenu(menus, setMenus, setRemoveMenus, 'Title')
        },
        {
          title: 'Reset Order',
          onClick: () => resetMenu(menus, setMenus, setRemoveMenus, 'Order')
        }
      ]}
    />
  )
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  defaultMenus,
  menus,
  setMenus,
  removeMenus,
  setRemoveMenus,
  onModalClose,
  parentIds,
  setDefaultUpdated,
  isManager
}) => {
  const { updateCognitoUserMenus } = useAuthentication()
  const { values } = useFormikContext<UserSetupFormValues>()
  const { updateMenuLoading, updateUserMutation } = useSaveButton()
  const getCurrentUser = useCurrentUser()

  const update = mutations(menus, parentIds).update
  const create = mutations(menus, parentIds).create
  const removeScreen = mutations(menus, parentIds).removeScreen
  const createDefaultScreen = mutations(menus, parentIds).createDefaultScreen
  const creatDefaultGroup = mutations(menus, parentIds).creatDefaultGroup
  const removeCreate = mutations(menus, parentIds).removeCreate

  const save = () => {
    updateUserMutation({
      variables: {
        id: values.id,
        input: {
          userMenus: {
            ...values.userMenus,
            update: update,
            create: [...create, ...createDefaultScreen, ...creatDefaultGroup],
            delete: [...removeScreen, ...removeMenus, ...removeCreate]
          }
        }
      }
    }).then((userMenu) => {
      if (
        userMenu &&
        userMenu.data?.updateUser?.__typename === 'WMSUser' &&
        userMenu.data.updateUser.userMenus
      ) {
        const mergedMenus = menuMerger(
          defaultMenus,
          userMenu.data.updateUser.userMenus as MenuEntry[],
          isManager
        )
        const data = JSON.parse(JSON.stringify(mergedMenus)) as MenuList[]
        setRemoveMenus([])
        setDefaultUpdated({ screen: [], group: [] })
        setMenus(data)
        getCurrentUser((currentUserData) => {
          if (values.id === currentUserData?.id) {
            const enabledMenus = arrayToTree(
              flattenMenus(data).filter((enabledMenu) => enabledMenu.enabled),
              { dataField: null }
            ) as MenuList[]
            updateCognitoUserMenus(enabledMenus)
          }
        })
      }
    })
  }
  return (
    <Box>
      <DropDownButton
        isDisabled={
          flattenMenus(menus).filter((menu) => menu.isNew || menu.isUpdated).length === 0 &&
          removeMenus.length === 0 &&
          createDefaultScreen.length === 0 &&
          creatDefaultGroup.length === 0
        }
        onClick={() => save()}
        menuItems={[
          {
            title: 'Save',
            onClick: () => save()
          },
          {
            title: 'Save & Close',
            onClick: () => {
              onModalClose && onModalClose()
              save()
            }
          }
        ]}
        bg="primary.base"
        color="text.onPrimary"
        isLoading={updateMenuLoading}
        text="Save"
      />
    </Box>
  )
}
