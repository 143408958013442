import { FormikHelpers } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { Column } from 'react-table'
import { SelectObject } from '../components/FormElements/ConnectedSelect'
import { AuditEntryFilterInput, Maybe } from '../generated/graphql'
import { TableContextType } from './TableProvider.context'

type ISidePanel = {
  key: string | { parent: string; child: string }
  header: string
  value: Maybe<string>
  isHeader?: boolean
}[]

type IDataExport = {
  exportData: any
  exportMaxSize: number
  exportCurrentSize: number
  exportError: null
}

type IPage = Maybe<'stock' | 'audit'>

export type ExportForm = {
  destination: SelectObject
  saveAsName: string
  saveTo: string
  exportFormat: SelectObject
  exportFields: SelectObject[]
} & FormikHelpers<any>

type AdvancedDataContextType = {
  showQueryBuilder: boolean
  createShowQueryBuilder: (show: boolean) => void
  page: IPage
  createPage: (page: IPage) => void
  data: any[]
  columns: Column<{
    id: string | number
    code: string
  }>[]
  createColumns: (
    columns: Column<{
      id: string | number
      code: string
    }>[]
  ) => void
  sidePanel: ISidePanel
  createSidePanel: (sidePanel: ISidePanel) => void
  tablePanelWidth: string
  createTablePanelWidth: (width: string) => void
  selectedRow: any
  createSelectedRow: (row: any) => void
  exportForm: ExportForm
  createExportForm: (form: ExportForm) => void
  exportObject: IDataExport
  createExportObject: (exportObject: IDataExport) => void
  filter: Maybe<{ [key: string]: any }> | undefined
  advancedFilterInitial: any
  createAdvancedFilterInitial: (initial: any) => void
  createFilter: (filter: Maybe<{ [key: string]: any }>) => Promise<Maybe<{ [key: string]: any }>>
  totalCount: number
  createTotalCount: (total: number) => void
} & TableContextType

export const AdvancedDataContext = React.createContext<AdvancedDataContextType>({
  showQueryBuilder: false,
  createShowQueryBuilder: () => null,
  page: null,
  createPage: () => null,
  columns: [{ Header: 'PlaceHolder', accessor: 'placeHolder' }],
  createColumns: () => null,
  sidePanel: [],
  createSidePanel: () => null,
  tablePanelWidth: '100%',
  createTablePanelWidth: () => null,
  selectedRow: {},
  createSelectedRow: () => null,
  exportForm: {} as ExportForm,
  createExportForm: () => null,
  exportObject: {
    exportData: [] as any,
    exportMaxSize: 0,
    exportCurrentSize: 0,
    exportError: null
  },
  createExportObject: () => null,
  filter: {},
  createFilter: async () => null,
  endCursor: null,
  createEndCursor: () => null,
  hasMore: false,
  createHasMore: () => null,
  isNextPageLoading: false,
  createIsNextPageLoaded: () => null,
  toggleSelection: false,
  createToggleSelection: () => null,
  data: [],
  items: [],
  createItems: () => null,
  clearAll: () => null,
  totalCount: 0,
  createTotalCount: () => null,
  advancedFilterInitial: [],
  createAdvancedFilterInitial: () => null,
  totalItemsCount: 0,
  createTotalItemsCount: () => null
})

export const useAdvancedDataContext = () => React.useContext(AdvancedDataContext)

const AdvancedDataProvider: React.FC<{}> = ({ children }) => {
  const [advancedFilterInitial, setAdvancedFilterInitial] = useState([])
  const [showQueryBuilder, setShowQueryBuilder] = useState(false)
  const [page, setPage] = useState<IPage>(null)
  const [columns, setColumns] = useState<
    Column<{
      id: string | number
      code: string
    }>[]
  >([{ Header: 'PlaceHolder', accessor: 'placeHolder' }])
  const [sidePanel, setSidePanel] = useState<ISidePanel>([])
  const [tablePanelWidth, setTablePanelWidth] = useState<string>('100%')
  const [selectedRow, setSelectedRow] = useState<any>([])
  const [exportForm, setExportForm] = useState<ExportForm>({} as ExportForm)
  const [exportObject, setExportObject] = useState({
    exportData: [] as any,
    exportMaxSize: 0,
    exportCurrentSize: 0,
    exportError: null
  })
  const [filter, setFilter] = useState<Maybe<{ [key: string]: any }>>()
  const [endCursor, setEndCursor] = React.useState<string | null>(null)
  const [items, setItems] = React.useState<any>([])
  const [hasMore, setHasMore] = React.useState<boolean>(true)
  const [isNextPageLoading, setIsNextPageLoading] = React.useState<boolean>(false)
  const [toggleSelection, setToggleSelection] = React.useState<any>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [totalItemsCount, setTotalItemsCount] = React.useState<any>(false)

  const data = React.useMemo(() => items, [items])

  function createTotalCount(total: number) {
    setTotalCount(total)
  }
  function createAdvancedFilterInitial(initial: any) {
    setAdvancedFilterInitial(initial)
  }

  function createShowQueryBuilder(show: boolean) {
    setShowQueryBuilder(show)
  }

  function createEndCursor(cursor: string | null) {
    setEndCursor(cursor)
  }
  function createHasMore(more: boolean) {
    setHasMore(more)
  }
  function createIsNextPageLoaded(next: boolean) {
    setIsNextPageLoading(next)
  }
  function createToggleSelection(toggle: boolean) {
    setToggleSelection(toggle)
  }
  function createItems(items: any) {
    setItems(items)
  }

  function clearAll() {
    setEndCursor(null)
    setItems([])
    setHasMore(true)
  }

  function createColumns(
    columns: Column<{
      id: string | number
      code: string
    }>[]
  ) {
    setColumns(columns)
  }

  function createSidePanel(sidePanel: ISidePanel) {
    setSidePanel(sidePanel)
  }

  function createTablePanelWidth(width: string) {
    setTablePanelWidth(width)
  }

  function createSelectedRow(row: string) {
    setSelectedRow(row)
  }

  function createExportForm(form: ExportForm) {
    setExportForm(form)
  }

  function createExportObject(exportObject: IDataExport) {
    setExportObject(exportObject)
  }

  function createTotalItemsCount(totalCount: number) {
    setTotalItemsCount(totalCount)
  }

  const createFilter = (filter: Maybe<AuditEntryFilterInput>) => {
    return new Promise<Maybe<AuditEntryFilterInput>>((resolve) => {
      setFilter(filter)
      resolve(filter)
    })
  }

  const createPage = (page: IPage) => {
    setPage(page)
  }

  return (
    <AdvancedDataContext.Provider
      value={{
        showQueryBuilder,
        createShowQueryBuilder,
        page,
        createPage,
        data,
        columns,
        createColumns,
        sidePanel,
        createSidePanel,
        tablePanelWidth,
        createTablePanelWidth,
        selectedRow,
        createSelectedRow,
        exportForm,
        createExportForm,
        exportObject,
        createExportObject,
        filter,
        createFilter,
        endCursor,
        hasMore,
        isNextPageLoading,
        toggleSelection,
        items,
        createEndCursor,
        createHasMore,
        createIsNextPageLoaded,
        createToggleSelection,
        createItems,
        clearAll,
        totalCount,
        createTotalCount,
        advancedFilterInitial,
        createAdvancedFilterInitial,
        totalItemsCount,
        createTotalItemsCount
      }}
    >
      {children}
    </AdvancedDataContext.Provider>
  )
}

export default AdvancedDataProvider
