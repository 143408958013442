import {
  Button,
  ButtonProps,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Portal,
  Spinner
} from '@chakra-ui/react'
import * as React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

export type DropdownItem = { title: string; onClick?: () => void }
export type GroupedDropDownItems = { group: string; items: DropdownItem[] }

type DropdownMenuProps = {
  menuItems: DropdownItem[]
  groupedMenuItems?: GroupedDropDownItems[]
  title?: string
  bg?: string
  color?: string
  icon?: React.ReactElement
  portal?: boolean
  isLoading?: boolean
} & ButtonProps

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  menuItems,
  groupedMenuItems,
  title,
  bg,
  color,
  icon,
  portal = true,
  isLoading,
  ...rest
}) => {
  const showIcon = (isOpen: boolean) => {
    if (title) {
      return isOpen ? <ChevronUp size="1rem" /> : <ChevronDown size="1rem" />
    }
    return null
  }

  const showIconInTitle = (isOpen: boolean) => {
    if (!title) {
      return (
        <Flex justifyContent="center">
          {isOpen ? <ChevronUp size="1rem" /> : <ChevronDown size="1rem" />}
        </Flex>
      )
    }
    return null
  }

  const menuItemsRender = () => (
    <MenuList paddingY={1}>
      {menuItems.map(({ title, onClick }) => (
        <MenuItem key={title} onClick={onClick} minHeight="32px" paddingX={4}>
          {title}
        </MenuItem>
      ))}
      {groupedMenuItems?.map(({ group, items }, index) => (
        <MenuOptionGroup title={group} key={index}>
          {items.map(({ title, onClick }) => (
            <MenuItem key={title} onClick={onClick} minHeight="32px" paddingX={4}>
              {title}
            </MenuItem>
          ))}
        </MenuOptionGroup>
      ))}
      {isLoading && <Spinner size="sm" display="flex" margin="auto" />}
    </MenuList>
  )

  return (
    <Menu>
      {({ isOpen }) => (
        <React.Fragment>
          <MenuButton as={Button} rightIcon={showIcon(isOpen)} bg={bg} color={color} {...rest}>
            {title && <Flex>{icon ? icon : title}</Flex>}
            {showIconInTitle(isOpen)}
          </MenuButton>
          {portal && <Portal>{menuItemsRender()}</Portal>}
          {!portal && menuItemsRender()}
        </React.Fragment>
      )}
    </Menu>
  )
}

export default DropdownMenu
