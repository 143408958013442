import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Switch,
  Text,
  useClipboard
} from '@chakra-ui/react'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Edit2, Info, Monitor, MoreVertical } from 'react-feather'
import { TMenu } from '.'
import { Col, Container, Row } from '../../../../components'
import { P } from '../../../../components/Typography'
import { theme } from '../../../../theme'
import { editTitle } from './Helpers/general'
import { toggleItem } from './Helpers/menuItems'

const StyledItemContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop.toString())
})<BoxProps>`
  width: 100%;
  position: relative;
  background: white;

  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  min-height: 30px;
`
/**
 * @render react
 * @name MenuItem container
 * @description Menu Item Component
 * @param menu Screen definition
 * @param menuIndex Screen position in its parent
 * @param setMenus React State setter for the menus
 * @param accordionRef Main container ref
 */
const MenuItem: React.FC<TMenu> = ({ menu, menuIndex, setMenus, accordionRef, isManager }) => {
  const [edit, setEdit] = useState(false)
  const [root, setRoot] = useState('')
  const { onCopy } = useClipboard(root)
  if (!isManager && !menu.enabled) return null
  return (
    <StyledItemContainer id={`screen-${menu.screenId}`}>
      <Container fluid padding={1} paddingY={0}>
        <Row middle="xs">
          <Col xs={1}>
            <Flex flexWrap="nowrap">
              <Flex>
                <Monitor height="14px" fontSize="14px" />
              </Flex>
              <Flex className="handle" cursor="move">
                <MoreVertical height="14px" />
                <Box marginLeft="-20px">
                  <MoreVertical height="14px" />
                </Box>
              </Flex>
            </Flex>
          </Col>
          <Col xs>
            {!edit && menu.title}
            {edit && (
              <input
                id={`${menu.title}-${menu.id}`}
                type="text"
                value={menu.title || ''}
                onBlur={() => setEdit(false)}
                onClick={(e) => {
                  e.preventDefault()
                }}
                onChange={(e) => {
                  setMenus((sourceList) => editTitle(sourceList, menuIndex, e))
                }}
              />
            )}
          </Col>
          <Flex alignItems="center" textAlign="center">
            <Text>#{menu.screen?.screenCode}</Text>
            <IconButton
              aria-label="Edit"
              variant="unstyled"
              icon={<Edit2 color={theme.colors.menuManager.edit} height="14px" />}
              onClick={() => {
                setEdit(!edit)
                //setTimeout is used to delay the focus event so that the input has time to render before focusing on it
                setTimeout(() => document.getElementById(`${menu.title}-${menu.id}`)?.focus())
              }}
            />
            {isManager && (
              <Switch
                size="md"
                color="menuManager.switch"
                defaultChecked={menu.enabled}
                isChecked={menu.enabled}
                onChange={() => {
                  setMenus((sourceList) => toggleItem(sourceList, menuIndex))
                }}
              />
            )}
            <Popover>
              <PopoverTrigger>
                <Box onClick={() => setRoot(menu.screen?.action || '')}>
                  <Info height="14px" />
                </Box>
              </PopoverTrigger>
              <Portal containerRef={accordionRef}>
                <PopoverContent background={theme.colors.menuManager.popover}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader display="flex">
                    <Text>{menu.screen?.defaultTitle}&nbsp;</Text>
                    <P>(Default Name)</P>
                  </PopoverHeader>
                  <PopoverBody>
                    <Container fluid>
                      <Row>
                        <Col xs>
                          <Flex justifyContent="space-between">
                            <P minWidth="80px">Custom Title</P>
                            <Text>:&nbsp;</Text>
                            <Text minWidth="100px">{menu.title}</Text>
                          </Flex>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <Flex justifyContent="space-between">
                            <P minWidth="80px">Screen No</P>
                            <Text>:&nbsp;</Text>
                            <Text minWidth="100px">{menu.screen?.screenCode}</Text>
                          </Flex>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>{menu.screen?.description}</Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <Flex justifyContent="space-between">
                            <P minWidth="80px">Root</P>
                            <Text>:&nbsp;</Text>
                            <Text
                              minWidth="100px"
                              cursor="pointer"
                              onClick={() => {
                                onCopy()
                              }}
                            >
                              {menu.screen?.action}
                            </Text>
                          </Flex>
                        </Col>
                      </Row>
                    </Container>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        </Row>
      </Container>
    </StyledItemContainer>
  )
}

export default MenuItem
