/* eslint @typescript-eslint/no-var-requires: "off" */
const images = {
  underConstruction: require('../assets/images/under-construction.svg').default,
  warning: require('../assets/images/warning.svg').default,
  empty: require('../assets/images/empty.svg').default,
  facebook: require('../assets/images/facebook.svg').default,
  appLogo: require('../assets/images/logo.png').default,
  macsLogo: require('../assets/images/MACS_logo.svg').default,
  macsLogoSmall: require('../assets/images/MACS_logo_small.svg').default,
  csv: require('../assets/images/csv.svg').default,
  pdf: require('../assets/images/pdf.svg').default,
  doc: require('../assets/images/doc.svg').default,
  xls: require('../assets/images/xls.svg').default,
  general: require('../assets/images/documents.svg').default
}

export default images
