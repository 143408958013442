import React from 'react'
import InfoPage from '..'

/**
 * @render react
 * @name ComingSoon container
 * @description Coming Soon Info Page
 */
const ComingSoon: React.FC = () => (
  <InfoPage
    header="Coming Soon"
    description="This page will be available soon, please understand that quality takes time"
  />
)

export default ComingSoon
