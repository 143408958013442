import styled from '@emotion/styled'
import * as React from 'react'
import { Grid, GridProps } from 'react-flexbox-grid'
import { color, ColorProps, space, SpaceProps } from 'styled-system'

export type StyledContainerProps = GridProps &
  SpaceProps &
  ColorProps & {
    color?: string
    height?: string
  }

const StyledContainer = styled(Grid, {
  shouldForwardProp: (prop) => {
    return prop === 'paddingY' ? false : true
  }
})`
  ${space};
  ${color};
`

const CustomContainer: React.FC<StyledContainerProps> = ({ children, height, ...rest }) => {
  return (
    <StyledContainer style={{ width: '100%', height }} {...rest}>
      {children}
    </StyledContainer>
  )
}

export default CustomContainer

CustomContainer.defaultProps = {
  padding: 0
}
