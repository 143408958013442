import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  IconButton,
  Switch
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Edit2, MoreVertical, Plus, Trash2 } from 'react-feather'
import { Col, Row } from 'react-flexbox-grid'
import { ReactSortable } from 'react-sortablejs'
import { MenuRender, sortableOptions, TMenu } from '.'
import { Container } from '../../../../components'
import { theme } from '../../../../theme'
import { editTitle } from './Helpers/general'
import {
  addNewGroup,
  deleteGroup,
  setGroupMenus,
  showAdd,
  showDelete,
  toggleGroup
} from './Helpers/menuGroups'

const StyledDropContainer = styled.div`
  width: 100%;
  position: relative;
`

/**
 * @render react
 * @name MenuGroup container
 * @description Menu Group Component
 * @param menu Screen definition
 * @param menuIndex Screen position in its parent
 * @param setMenus React State setter for the menus
 * @param accordionRef Main container ref
 */
const MenuGroup: React.FC<TMenu> = React.memo(
  ({
    menu,
    menuIndex,
    setMenus,
    removeMenus,
    setRemoveMenus,
    accordionRef,
    defaultUpdated,
    isManager
  }) => {
    const [edit, setEdit] = useState(false)

    if (!isManager && !menu.enabled) return null
    return (
      <AccordionItem
        background="background.base"
        mb={1}
        minHeight="30px"
        isDisabled={
          defaultUpdated
            ? defaultUpdated.screen.length > 0 || defaultUpdated.group.length > 0
            : false
        }
      >
        <h2>
          <Flex paddingLeft={1} id={`groupd-${menu.id}`}>
            <AccordionButton paddingY={0} paddingX={0} minHeight="30px" className="my-accordion">
              <AccordionIcon marginRight={1} fontSize="14px" />
              <Flex mr={1} className="handle" cursor="move">
                <MoreVertical height="14px" />
                <Box marginLeft="-20px">
                  <MoreVertical height="14px" />
                </Box>
              </Flex>
              <Flex textAlign="left">
                {!edit && menu.title}
                {edit && (
                  <input
                    id={`${menu.title}-${menu.id}`}
                    type="text"
                    value={menu.title || ''}
                    onBlur={() => setEdit(false)}
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                    onChange={(e) => {
                      setMenus((sourceList) => editTitle(sourceList, menuIndex, e))
                    }}
                    onKeyUp={(e) => {
                      if (e.code === 'Space') {
                        e.preventDefault()
                        e.stopPropagation()
                      }
                    }}
                  />
                )}
              </Flex>
            </AccordionButton>
            <Flex marginLeft="auto" alignItems="center" mr="-10px">
              <IconButton
                isDisabled={
                  defaultUpdated
                    ? defaultUpdated.screen.length > 0 || defaultUpdated.group.length > 0
                    : false
                }
                aria-label="Edit"
                variant="unstyled"
                icon={<Edit2 color={theme.colors.menuManager.edit} height="14px" />}
                onClick={() => {
                  setEdit(!edit)
                  //setTimeout is used to delay the focus event so that the input has time to render before focusing on it
                  setTimeout(() => document.getElementById(`${menu.title}-${menu.id}`)?.focus())
                }}
              />
              {isManager && (
                <Switch
                  isDisabled={
                    defaultUpdated
                      ? defaultUpdated.screen.length > 0 || defaultUpdated.group.length > 0
                      : false
                  }
                  size="md"
                  color="menuManager.switch"
                  defaultChecked={menu.enabled}
                  isChecked={menu.enabled}
                  onChange={() => {
                    setMenus((sourceList) => toggleGroup(sourceList, menuIndex))
                  }}
                />
              )}
              {showDelete(menu.children, menu.removeOnReset) && (
                <IconButton
                  isDisabled={
                    defaultUpdated
                      ? defaultUpdated.screen.length > 0 || defaultUpdated.group.length > 0
                      : false
                  }
                  aria-label="Edit"
                  variant="unstyled"
                  icon={<Trash2 color={theme.colors.menuManager.remove} height="14px" />}
                  onClick={() => {
                    setMenus((sourceList) =>
                      deleteGroup(sourceList, menuIndex, setRemoveMenus, removeMenus)
                    )
                  }}
                />
              )}
              {!showDelete(menu.children, menu.removeOnReset) && <Box boxSize="35px" />}
            </Flex>
          </Flex>
        </h2>
        <AccordionPanel pb={4} paddingRight={0} background="solid.muted">
          <StyledDropContainer className="block">
            <Container fluid>
              <Row>
                <Col>
                  {showAdd(menu.depth) && (
                    <IconButton
                      aria-label="Add"
                      bg="primary.base"
                      color="white"
                      icon={<Plus />}
                      height="30px"
                      onClick={() => {
                        setMenus((sourceList) => addNewGroup(sourceList, menuIndex))
                      }}
                    />
                  )}
                </Col>
                <Col xs>
                  <ReactSortable
                    group="shared"
                    key={`${menu.id}-${menu.depth}`}
                    list={menu.children ? menu.children : []}
                    setList={(currentList, _, store) => {
                      if (store.dragging) {
                        setMenus((sourceList) => setGroupMenus(sourceList, currentList, menuIndex))
                      }
                    }}
                    {...sortableOptions}
                  >
                    {menu.children &&
                      menu.children.map((child, childIndex: number) => {
                        return (
                          <MenuRender
                            key={child.id}
                            accordionRef={accordionRef}
                            menu={child}
                            menuIndex={[...menuIndex, childIndex]}
                            removeMenus={removeMenus}
                            setRemoveMenus={setRemoveMenus}
                            setMenus={setMenus}
                            isManager={isManager}
                          />
                        )
                      })}
                  </ReactSortable>
                </Col>
              </Row>
            </Container>
          </StyledDropContainer>
        </AccordionPanel>
      </AccordionItem>
    )
  }
)
export default MenuGroup
